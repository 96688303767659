import { Tooltip } from 'antd';
import styles from './index.module.scss';
import { EyeIcon } from 'utils/svgs';
type Props = {
	employeeShare: any;
	placement?: any;
};
const TooltipContent = ({ employeeShare }: Props) => {
	return (
		<div className={styles['tooltip-content']}>
			<div className={styles['tooltip-header']}>
				<span>Salary amount</span>
				<span>Percentage</span>
			</div>
			{employeeShare.map((share: any, index: number) => (
				<div key={share.id} className={styles['tooltip-row']}>
					<span className={styles['tooltip-cell']}>
						{index === 0
							? `First ${share.maxAmount.toFixed(2)} ZMW`
							: index === employeeShare.length - 1
							? `${share.minAmount.toFixed(2)} ZMW - Onwards`
							: `Next ${share.minAmount.toFixed(
									2
							  )} ZMW - ${share.maxAmount.toFixed(2)} ZMW`}
					</span>
					<span className={styles['tooltip-cell']}>
						{share.percentage.toFixed(2)}%
					</span>
				</div>
			))}
		</div>
	);
};

const EmployeeShareComponent = ({ employeeShare, placement }: Props) => {
	return (
		<div className="ant-tooltip-container">
			<Tooltip
				title={<TooltipContent employeeShare={employeeShare} />}
				color="#fff"
				placement={placement ? placement : 'left'}
				overlayInnerStyle={{ width: 'max-content' }}
				className="ant-tooltip-paye"
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<EyeIcon />
					<span>View Details</span>
				</div>
			</Tooltip>
		</div>
	);
};

export default EmployeeShareComponent;
