import Buttons from "components/Global/Buttons";
import React from "react";
import styles from "./index.module.scss";
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type headerProps = {
  sidebarDrawerOpen: () => void
  PayeExist: boolean
  isLoading: boolean
}

const PayeTabHeader = ({ sidebarDrawerOpen, PayeExist, isLoading }: headerProps) => {
 	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
  const buttonTitle = PayeExist ? 'Edit PAYE' : 'Add PAYE';
  const buttons = [
    {
      text: buttonTitle,
      isLoading: false,
      className: 'btn-blue',
      isSubmit: true,
      onclick: () => {
        sidebarDrawerOpen()
      },
      disabled: isLoading,
    },
  ];
  return <div className={styles['paye-header']}>
    <p>PAYE</p>
    {isAdd && (
    <div>
      <Buttons buttons={buttons} />
    </div>
    )}
  </div>;
};

export default PayeTabHeader;
