import { postApi, getApi, getApiPDF } from 'apis';
const createSkillLevy = async (data: any) => {
	return await postApi('/skillLevy/create', data, true);
};

const getLatestSkillLevy = async () => {
	return await getApi('/skillLevy/latest');
};

const getSkillLevyHistory = async (params: any) => {
	return await getApi(`/skillLevy/history`, params);
};
const downloadSkillLevyAttachment = async (skillLevyId: string) => {
	return await getApiPDF('/skillLevy/attachment/download', {
		skillLevyId: skillLevyId,
	});
};


export const skillLevyApi = {
	createSkillLevy,
	getLatestSkillLevy,
	getSkillLevyHistory,
	downloadSkillLevyAttachment,
};
