import { Modal, Table } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import React from 'react';
import { invalidText } from 'utils/utils';
import './index.scss';

interface HistoryModalProps {
	isVisible: boolean;
	onClose: () => void;
	record: any;
	historyData: any[];
	page: number;
	totalRecords: number;
	loading: boolean;
	pageSize: number;
	fetchRecords: any;

	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
}

const getDayOrdinal = (day: number) => {
	if (day === 1 || day === 21 || day === 31) {
		return 'st of Month';
	} else if (day === 2 || day === 22) {
		return 'nd of Month';
	} else if (day === 3 || day === 23) {
		return 'rd of Month';
	} else {
		return 'th of Month';
	}
};

const HistoryModal: React.FC<HistoryModalProps> = ({
	isVisible,
	onClose,
	record,
	historyData,
	page,
	totalRecords,
	loading,
	pageSize,

	tableChangeHandler,
}) => {
	const columns = [
		{
			title: 'Day',
			dataIndex: 'day',
			sorter: true,
			key: 'day',
			className: 'bg-white',
			render: (day: number) => (
				<>
					{day}
					{getDayOrdinal(day)}
				</>
			),
		},
		{
			title: 'Updated By',
			dataIndex: 'updatedBy',
			key: 'updatedBy',
			className: 'bg-white',
			render: (updatedBy: any) => updatedBy?.fullName,
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			sorter: true,
			key: 'createdAt',
			className: 'bg-white',
			render: (createdAt: string) =>
				dayjs(createdAt).format('DD/MM/YYYY'),
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			className: 'bg-white',
			render: (text: string) =>
				invalidText(text) ? (
					'-'
				) : (
					<Ellipse
						message={text}
						maxLength={20}
						key={text}
						tooltipMessage={text}
						isTooltip={true}
					/>
				),
		},
	];

	return (
		<Modal
			title="History"
			open={isVisible}
			onCancel={onClose}
			footer={null}
			width={800}
			className="hstoryModal"
		>
			<Table
				loading={loading}
				columns={columns}
				dataSource={historyData}
				rowKey="id"
				onChange={tableChangeHandler}
				pagination={{
					total: totalRecords,
					current: page,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
			/>
		</Modal>
	);
};

export default HistoryModal;
