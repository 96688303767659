import { SearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	button: any;
};

const BranchCodeHeader = (props: Props) => {
	const { searchValue, handleSearch, handleSearchApi, button } = props;
	const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search code..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
			</Space>
			{permissions.allowedPermissions.includes('Add_Branch_Code') && (
				<Space>
					<Buttons buttons={button} />
				</Space>
			)}
		</div>
	);
};

export default BranchCodeHeader;
