import { SearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
};

const ShiftHeader = (props: Props) => {
	const { searchValue, handleSearch, handleSearchApi } = props;

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search shift..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
			</Space>
			{/* <Space>
				<DatePicker
					picker="month"
					value={selectedMonthYear}
					onChange={handleMonthYearChange}
					placeholder="Select Month"
					format="MMM, YYYY"
					size="large"
				/>
			</Space> */}
		</div>
	);
};

export default ShiftHeader;
