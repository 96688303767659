import { AuthLayout } from 'components/Global/AuthLayout';
import GlobalLayout from 'layouts/Global';
import { ForgotPassword, Login, ResetPassword } from 'pages';
import AccessDeniedPage from 'pages/AccessDeniedPage';
import ApproveRejectPage from 'pages/payrollApprovalRejection';
// import AddEmployeePage from 'pages/AddEmployee';
import CalculateStatutory from 'components/Payroll/CalculateStatutory';
import { Dashboard } from 'pages/Dashboard';
import { EmployeesPage } from 'pages/Employees';
import PageNotFoundPage from 'pages/PageNotFound';
import { Payroll } from 'pages/Payroll';
import Report from 'pages/Report';
import Approval from 'pages/Settings/Approval';
import { Employee } from 'pages/Settings/Approval/Employee';
import Configuration from 'pages/Settings/Configuration';
import { BranchCode } from 'pages/Settings/Masters/BranchCode';
import { CategoryCode } from 'pages/Settings/Masters/CategoryCode';
import { Company } from 'pages/Settings/Masters/Company';
import { CostCenter } from 'pages/Settings/Masters/CostCenter';
import { Department } from 'pages/Settings/Masters/Department';
import { JobGrade } from 'pages/Settings/Masters/JobGrade';
import { JobTitle } from 'pages/Settings/Masters/JobTitle';
import { PayPoint } from 'pages/Settings/Masters/PayPoint';
import Notification from 'pages/Settings/Notification';
import PayrollSettings from 'pages/Settings/PayrollSettings';
import { Deduction } from 'pages/Settings/PayrollSettings/PayrollPages/Deduction';
import { Earning } from 'pages/Settings/PayrollSettings/PayrollPages/Earning';
import { Roles } from 'pages/Settings/Roles';
import Settings from 'pages/Settings/Settings';
import { Users } from 'pages/Settings/Users';
import { Coa } from 'pages/Settings/Coa';
import { Shifts } from 'pages/Shifts';
import { TimeActivities } from 'pages/TimeActivities';
import { TimeLogs } from 'pages/TimeLogs';
import { TimeSheets } from 'pages/TimeSheets';
import { createBrowserRouter } from 'react-router-dom';

import PayrollSetupUI from 'components/Settings/PayrollSettings/payrollSettingWizard';
import { LeaveRequest } from 'pages/LeaveRequest';
import { LoanRequest } from 'pages/LoanRequest';
import { MidMonthPay } from 'pages/MidMonthPay';
import NapsaReport from 'pages/Report/NapsaReport';
import NhimaReport from 'pages/Report/NhimaReport';
import P18Report from 'pages/Report/P18Report';
import PayeReport from 'pages/Report/PayeReport';
import PayrollSummaryReport from 'pages/Report/PayrollSummaryReport';
import { Holiday } from 'pages/Settings/Masters/Holiday';
import { LeaveManagement } from 'pages/Settings/Masters/LeaveManagement';
import { CompanySetup } from 'pages/Settings/PayrollSettings/PayrollPages/Company';
import { MidMonthPaySetup } from 'pages/Settings/PayrollSettings/PayrollPages/MidMonthPay';
import { PayGroupPage } from 'pages/Settings/PayrollSettings/PayrollPages/PayGroup';
import { PayPeriod } from 'pages/Settings/PayrollSettings/PayrollPages/PayPeriod';
import { StatutoryComponentPage } from 'pages/Settings/PayrollSettings/PayrollPages/StatutoryComponents';
import { RunPayroll } from 'pages/Payroll/RunPayrollPage';
import Unauthorized from 'pages/Unauthorized';
import AttendanceReportPage from 'pages/Report/attendanceReport';
import EarningReport from 'pages/Report/EarningReport';
import JournalEntryReport from 'pages/Report/JournalEntry';
import PayrollSummaryEmployeeReport from 'pages/Report/PayrollSummaryEmployeeReport';
import DeductionReport from 'pages/Report/DeductionReport';
import NetPayReport from 'pages/Report/NetPayReport';

const router = createBrowserRouter([
	{
		element: <AuthLayout />,
		children: [
			{
				path: '/',
				element: <GlobalLayout />,
				children: [
					{
						index: true,
						element: <Dashboard />,
					},
					{
						path: '/employees',
						element: <EmployeesPage />,
					},
					{
						path: '/time-activities',
						element: <TimeActivities />,
					},
					{
						path: '/shifts',
						element: <Shifts />,
					},
					{
						path: '/calculate-statutory',
						element: <CalculateStatutory />,
					},
					{
						path: '/payroll-overview',
						element: <Payroll />,
					},
					{
						path: '/reports',
						element: <Report />,
						children: [
							{
								index: true,
								path: 'napsa',
								element: <NapsaReport />,
							},
							{
								path: 'nhima',
								element: <NhimaReport />,
							},
							{
								path: 'paye',
								element: <PayeReport />,
							},
							{
								path: 'payroll-summary',
								element: <PayrollSummaryReport />,
							},
							{
								path: 'p-18',
								element: <P18Report />,
							},
							{
								path: 'earnings',
								element: <EarningReport />,
							},
							{
								path: 'deductions',
								element: <DeductionReport />,
							},
							{
								path: 'payroll-summary-employee',
								element: <PayrollSummaryEmployeeReport />,
							},
							{
								path: 'journal-entry',
								element: <JournalEntryReport />,
							},
							{
								path: 'net-pay',
								element: <NetPayReport />,
							},
						],
					},
				],
			},
			{
				path: '/settings',
				element: <Settings />,
				children: [
					{
						index: true,
						path: 'users',
						element: <Users />,
					},
					{
						path: 'roles',
						element: <Roles />,
					},

					{
						path: 'notification',
						element: <Notification />,
					},
					{
						path: 'payroll-wizard',
						element: <PayrollSetupUI />,
					},
					{
						path: 'payroll-settings',
						element: <PayrollSettings />,
						children: [
							{
								path: 'company-setup',
								element: <CompanySetup />,
							},
							{
								path: 'statutory-components',
								element: <StatutoryComponentPage />,
							},
							{
								path: 'pay-period',
								element: <PayPeriod />,
							},
							{
								path: 'pay-group',
								element: <PayGroupPage />,
							},
							{
								path: 'pay-schedule',
								element: <h1>Pay Schedule</h1>,
							},
							{
								path: 'earning-code',
								element: <Earning />,
							},
							{
								path: 'deduction-code',
								element: <Deduction />,
							},
							{
								path: 'mid-month-pay',
								element: <MidMonthPaySetup />,
							},
							{
								path: 'payroll-history',
								element: <h1>Payroll History</h1>,
							},
						],
					},
					{
						path: 'configuration',
						element: <Configuration />,
						children: [
							{
								index: true,
								path: 'company',
								element: <Company />,
							},
							{
								path: 'branch-code',
								element: <BranchCode />,
							},
							{
								path: 'category-code',
								element: <CategoryCode />,
							},
							{
								path: 'job-title',
								element: <JobTitle />,
							},
							{
								path: 'pay-point',
								element: <PayPoint />,
							},
							{
								path: 'department',
								element: <Department />,
							},
							{
								path: 'cost-center',
								element: <CostCenter />,
							},
							{
								path: 'job-grade',
								element: <JobGrade />,
							},
							{
								path: 'leave-management',
								element: <LeaveManagement />,
							},
							{
								path: 'holidays',
								element: <Holiday />,
							},
						],
					},
					{
						path: 'approval',
						element: <Approval />,
						children: [
							{
								index: true,
								path: 'employee',
								element: <Employee />,
							},
						],
					},
					{
						path: 'coa',
						element: <Coa />,
					},
				],
			},

			{
				path: '/run-payroll/:id?',
				element: <RunPayroll />,
			},
			{
				path: '/time-logs',
				element: <TimeLogs />,
			},
			{
				path: '/time-sheets',
				element: <TimeSheets />,
			},
			{
				path: '/reports/attendance-report',
				element: <AttendanceReportPage />,
			},
			{
				path: '/leave-request',
				element: <LeaveRequest />,
			},
			{
				path: '/loan-request',
				element: <LoanRequest />,
			},
			{
				path: '/mid-month',
				element: <MidMonthPay />,
			},
			{
				element: <Login />,
				path: '/login',
			},
			{
				path: '/reset-password',
				element: <ResetPassword />,
			},
			{
				path: '/set-password',
				element: <ResetPassword />,
			},
			{
				path: '/forgot-password',
				element: <ForgotPassword />,
			},
			{
				path: '/access-denied',
				element: <AccessDeniedPage />,
			},
			{
				path: '/payroll-approve-reject',
				element: <ApproveRejectPage />,
			},
		],
	},
	{
		path: '/session-timeout',
		element: <Unauthorized message="Your session has expired." />,
	},
	{
		path: '/unauthorized',
		element: <Unauthorized message="" />,
	},
	{
		path: '*',
		element: <PageNotFoundPage />,
	},
]);

export default router;
