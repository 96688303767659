import { Button, Table } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import { handleDownload, invalidText } from 'utils/utils';
import EmployeeShareComponent from '../ToolTip';
import { HistoryOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './index.scss'
import { useState } from 'react';
import { payeApi } from 'Api/payeApi';
const { Column } = Table;

type Props = {
	latestPayeDetails: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};

const PayeLatestTable = (props: Props) => {
	const { latestPayeDetails, isLoading, showModal, totalRecords } = props;
	const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
		null
	);
		const handleRecordDownload = async (
			recordId: string,
			recordName: string
		) => {
			return await handleDownload(
				recordId,
				payeApi.downloadPayeAttachment,
				setDownloadingRowId,
				recordName
			);
		};
	return (
		<div className="paye-table">
			<Table
				dataSource={latestPayeDetails}
				rowKey={(record) => record.id}
				loading={isLoading}
				pagination={false}
			>
				<Column
					title="Effective Date"
					dataIndex="effectiveDate"
					key="effectiveDate"
					className="bg-white"
					width={150}
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Updated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					width={150}
					render={(text) => text.fullName}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					key="notes"
					className="bg-white"
					width={150}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								isTooltip={true}
								tooltipMessage={text}
							/>
						)
					}
				/>
				<Column
					title="Attachment"
					dataIndex="attachment"
					key="attachment"
					className="bg-white"
					width={'18%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<div>
									<div
										onClick={() =>
											downloadingRowId === record.id
												? () => {}
												: handleRecordDownload(
														record.id,
														record.documentName
												  )
										}
										style={{
											cursor:
												downloadingRowId === record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingRowId === record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.documentName}
											maxLength={20}
											key={record.documentName}
											tooltipMessage={record.documentName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Employee Share"
					dataIndex="employeeShare"
					key="employeeShare"
					className="bg-white"
					width={150}
					render={(employeeShare) =>
						EmployeeShareComponent({ employeeShare })
					}
				/>
				{totalRecords >= 1 && (
					<Column
						title="History"
						dataIndex="history"
						key="history"
						className="bg-white"
						width={100}
						render={() => (
							<Button
								type="link"
								icon={<HistoryOutlined />}
								onClick={showModal}
							/>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default PayeLatestTable;
