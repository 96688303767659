import { Modal, Table, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { napsaApi } from 'Api/napsa';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, handleDownload, invalidText } from 'utils/utils';

type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allNapsaData: any;
	isLoading: boolean;
	fetchAllNapsaDetails: any;
};

const ShareComponent = (data: any) => {
	const { record } = data;

	return (
		<Tooltip
			title={
				<>
					<p>EmployeeShare : {record.employeeShare} %</p>
					<p>EmployerShare : {record.employerShare} %</p>{' '}
				</>
			}
			placement="right"
		>
			{record.employerShare + record.employeeShare} %
		</Tooltip>
	);
};
const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allNapsaData,
		isLoading,
	} = props;

	const { data } = useSelector((state: any) => state?.userProfile);
	const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
		null
	);

	const handleRecordDownload = async (
		recordId: string,
		recordName: string
	) => {
		return await handleDownload(
			recordId,
			napsaApi.downloadNapsaAttachment,
			setDownloadingRowId,
			recordName
		);
	};
	return (
		<>
			<Modal
				title="History"
				open={open}
				onCancel={closeModal}
				maskClosable={true}
				width={1000}
				closable={true}
				footer={null}
			>
				<Table
					dataSource={allNapsaData}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Effective Date"
						dataIndex="effectiveDate"
						key="effectiveDate"
						sorter={true}
						className="bg-white"
						render={(text) => dayjs(text).format('DD/MM/YYYY')}
					/>
					<Column
						title="Percentage Share"
						key="employeeShare"
						dataIndex="employeeShare"
						className="bg-white"
						render={(_, record: any) => (
							<ShareComponent record={record} />
						)}
					/>
					<Column
						title="Updated By"
						dataIndex="createdBy"
						key="createdBy"
						className="bg-white"
						render={(text) => text?.fullName}
					/>
					<Column
						title="Deduction Limit"
						dataIndex="napsaDeductionLimit"
						key="napsaDeductionLimit"
						className="bg-white"
						width={'18%'}
						render={(value: string) => {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}}
					/>
					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						sorter={true}
						className="bg-white"
						render={(text) =>
							invalidText(text) ? (
								'-'
							) : (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)
						}
					/>
					<Column
						title="Attachment"
						dataIndex="attachment"
						key="attachment"
						className="bg-white"
						render={(text, record: any) => (
							<div>
								{record?.documentName ? (
									<div>
										<div
											onClick={() =>
												downloadingRowId === record.id
													? () => {}
													: handleRecordDownload(
															record.id,
															record.documentName
													  )
											}
											style={{
												cursor:
													downloadingRowId ===
													record.id
														? 'not-allowed'
														: 'pointer',
												opacity:
													downloadingRowId ===
													record.id
														? 0.5
														: 1,
											}}
										>
											<Ellipse
												message={record.documentName}
												maxLength={50}
												key={record.documentName}
												tooltipMessage={
													record.documentName
												}
												isTooltip={true}
												isLink={true}
											/>
										</div>
									</div>
								) : (
									'-'
								)}
							</div>
						)}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
