import { SearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import RefreshComponent from 'components/Global/RefreshComponent';

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	button: any;
	isLoading: boolean;
	fetchAllDeductions: () => void;
};

const DeductionHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		handleSearchApi,
		button,
		isLoading,
		fetchAllDeductions,
	} = props;
	const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search code..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
			</Space>
			<Space>
				<RefreshComponent
					isLoading={isLoading}
					fetchData={fetchAllDeductions}
					title="Refresh all the Deduction Codes"
				/>
				{permissions.allowedPermissions.includes(
					'Add_Deduction_Code'
				) && <Buttons buttons={button} />}
			</Space>
		</div>
	);
};

export default DeductionHeader;
