import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import RefreshComponent from 'components/Global/RefreshComponent';
import SearchComponent from 'components/Global/SearchComponent';
import { currencyData } from 'constants/CurrencyData';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { payrollStatusOptions } from '../../../../../constants/payrollStatus';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => Promise<void>;
	StatusFilter: string;
	handleStatusFilter: (value: string) => void;
	YearFilterValue: any;
	handleYear: (value: any) => void;
	PayPeriodFilterValue: string;
	handlePayPeriod: (value: string) => void;
	currencyValue: string;
	handleCurrency: (value: string) => void;
	button: any;
	fetchAllPayrolls: () => void;
	isLoading: boolean;
};

const PayrollHeader = (props: Props) => {
	const context = useContext(PermissionContext);

	const check = context.allowedPermissions.includes('Add_Run_Payroll');
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		YearFilterValue,
		handleYear,
		PayPeriodFilterValue,
		handlePayPeriod,
		StatusFilter,
		handleStatusFilter,
		currencyValue,
		handleCurrency,
		button,
		fetchAllPayrolls,
		isLoading,
	} = props;
	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const mappedCurrencyOptions = constantDropdownOptions?.currencyData?.map(
		(currency: any) => ({
			value: currency.value,
			label: currency.value
				? `${currency.label} - ${
						currencyData[currency.label] || currency.label
				  }`
				: 'Select Currency',
		})
	);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	return (
		<div className={styles['payrollOverview-header']}>
			<Space>
				<SearchComponent
					className={styles['payrollOverview-header-item']}
					placeHolder="Search here..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
					minHeight="43px"
				/>
				<Select
					style={{ minHeight: '43px' }}
					className={styles['payrollOverview-header-item']}
					value={StatusFilter}
					options={payrollStatusOptions}
					onChange={(value) => handleStatusFilter(value)}
					size="large"
					placeholder="Select Status"
				/>
				<Select
					style={{ minHeight: '43px', width: '180px' }}
					placeholder="Select Currency"
					size="large"
					value={currencyValue}
					// onSelect={(currency) => setSelectedCurrency(currency)}
					onChange={(value) => handleCurrency(value)}
					options={mappedCurrencyOptions || []}
				></Select>
				<div className="year-pay-period-filter">
					<GlobalPayPeriod
						handlePayPeriod={(value) => handlePayPeriod(value)}
						selectedPayPeriod={PayPeriodFilterValue}
						isRow={false}
						handleSelectedYear={(value) => handleYear(value)}
						colSpan={24}
						colStyle={{ minHeight: '43px' }}
						payPeriodError={false}
						isYearRequired={false}
						isPayPeriodRequired={false}
						selectedYear={YearFilterValue}
						isPayrollOverview={true}
					/>
				</div>
			</Space>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					width: '100%',
					gap: '20px',
				}}
			>
				<RefreshComponent
					isLoading={isLoading}
					fetchData={fetchAllPayrolls}
					title="Refresh all the payrolls"
				/>

				{check && <Buttons buttons={button} />}
			</div>
		</div>
	);
};

export default PayrollHeader;
