import { employeeApi } from 'Api/employee';
import { Checkbox, Col, Input, Row } from 'antd';
import { Loader } from 'components/Global';
import { useEffect, useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { DeductionsModalProps } from './type';

const DeductionsModal: React.FC<DeductionsModalProps> = ({
	onDataChange,
	year,
	month,
	employeeId,
	onLoadingChange,
	activeTab,
	payPeriodId,
	isUSD,
}) => {
	const [data, setData] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const updateData = (newData: any) => {
		// setDeductionsData(
		// 	showAll ? newData?.filter((item: any) => item.isDefault) : newData
		// );
		onDataChange(newData);
	};

	const handleChange = (index: string, value: string | boolean) => {
		let newData = [...data];
		if (typeof value === 'boolean') {
			newData = data.map((item: any) => {
				if (item.id === index) {
					return { ...item, isChecked: value };
				}
				return item;
			});
		} else {
			newData = data.map((item: any) => {
				if (item.id === index) {
					return {
						...item,
						amount: isNaN(parseFloat(value))
							? parseFloat('0.00')
							: value,
					};
				}
				return item;
			});
		}
		setData(newData);
		updateData(newData);
	};

	const handleBlur = (index: number) => {
		let newData = [...data];
		newData = data.map((item: any) => {
			if (item.id === index) {
				return {
					...item,
					amount:
						isNaN(item.amount) || item.amount < 0
							? '0.00'
							: parseFloat(item.amount).toFixed(2),
				};
			}
			return item;
		});
		setData(newData);
		updateData(newData);
	};

	useEffect(() => {
		onDataChange(data);
	}, [data]);
	useEffect(() => {
		onLoadingChange(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (activeTab === 'deductions' && !invalidText(payPeriodId)) {
			fetchEmployeeDeductions();
		}
	}, [payPeriodId, activeTab]);

	const fetchEmployeeDeductions = async () => {
		setIsLoading(true);
		try {
			// const selectedYear = dayjs(year).year();
			const apiData = await employeeApi.getEmployeeDeductions({
				payPeriodId: payPeriodId,
				employeeId,
			});
			if (apiData?.data?.data?.length > 0) {
				setData(apiData?.data?.data);
			}
			// else {
			// 	await fetchEmployeeLoan();
			// 	await fetchEmployeeSalaryMonthWise();
			// }
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		}
		setIsLoading(false);
	};

	const renderItems = (items: any, startIndex: number) =>
		items?.map((item: any, idx: number) => (
			<div key={item.id} className={styles.earningsFields}>
				<div className="checkbox">
					<Checkbox
						checked={item.isChecked}
						onChange={() => handleChange(item?.id, !item.isChecked)}
					/>
				</div>
				<label className={styles.label}>{item?.description}</label>
				<Input
					value={item?.amount ?? 0}
					onChange={(e) => handleChange(item?.id, e.target.value)}
					onBlur={() => handleBlur(item?.id)}
					type="number"
					suffix={isUSD ? 'USD' : 'ZMW'}
					disabled={item.isDisabled}
					className={styles.input}
				/>
				<span className={styles.currency}>{item.currency}</span>
			</div>
		));

	// const toggleShowAll = () => {
	// 	setShowAll(!showAll);
	// };

	// useEffect(() => {
	// 	setDeductionsData(
	// 		showAll ? data?.filter((item: any) => item.isDefault) : data
	// 	);
	// }, [showAll, data]);

	const half = Math.ceil(data?.length / 2);

	return (
		<div className="deductionsFields">
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'auto',
					}}
				>
					<Loader />
				</div>
			) : (
				<>
					<Row gutter={32}>
						<Col span={12}>
							{renderItems(data?.slice(0, half), 0)}
						</Col>
						<Col span={12}>
							{renderItems(data?.slice(half), half)}
						</Col>
					</Row>
					<Row>
						<Col span={23}>
							<p style={{ float: 'right' }}>
								{/* <Button
									style={{ border: 'none' }}
									className={styles.showMoreLink}
									onClick={toggleShowAll}
								>
									{showAll ? 'Show More' : 'Show Less'}
								</Button> */}
							</p>
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};

export default DeductionsModal;
