/* eslint-disable react-hooks/exhaustive-deps */
import { payrollApi } from 'Api/payroll';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import PaymentTableHeader from './Table/Header';
import PaymentTable from './Table/PaymentTable';
import styles from './index.module.scss';

const PaymentComponent = () => {
	const [paymentData, setPaymentData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [date, setDate] = useState<any>(dayjs());
	const [filterOptions, setFilterOptions] = useState('');

	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const handleStatusFilter = (value: string) => {
		setFilterOptions(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : 'desc'
		);
		setSortField(sorter.field);
	};

	const fetchPayments = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				status: filterOptions,
			};
			setIsLoading(true);
			const loanRequest = await payrollApi.getPaymentLogs(query);

			setPaymentData(loanRequest.data.data);
			setTotalRecords(loanRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching Payments.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPayments();
	}, [currentPage, filterOptions, sortField, sortOrder, pageSize, date]);

	return (
		<>
			<PaymentTableHeader
				handleDatePicker={handleDatePicker}
				dateValue={date}
				filterOptions={filterOptions}
				handleStatusFilter={handleStatusFilter}
				// button={buttons}
			/>

			<div className={styles['overview-container']}>
				<PaymentTable
					tableData={paymentData}
					totalRecords={totalRecords}
					currentPage={currentPage}
					pageSize={pageSize}
					fetchRecords={fetchPayments}
					tableChangeHandler={tableChangeHandler}
					isLoading={isLoading}
				/>
			</div>
		</>
	);
};

export default PaymentComponent;
