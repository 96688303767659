/* eslint-disable react-hooks/rules-of-hooks */
import { MailOutlined } from '@ant-design/icons';
import { Space, Switch, Table, Tooltip } from 'antd';
import { putApi } from 'apis';
import { PermissionContext } from 'components/Global/AuthLayout';
import { FC, useContext } from 'react';
import {
	DeleteActionSvg,
	EditActionSvg,
	SortSvgBottom,
	SortSvgTop,
} from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import SearchAndFilter from '../SearchAndFilter';
import styles from './index.module.scss';
import './index.scss';
import { DynamicTableProps } from './types';

const SortingIcon = (data: any) => {
	return data.data.sortOrder === 'ascend' ? (
		<SortSvgTop />
	) : (
		<SortSvgBottom />
	);
};

const UserTable: FC<DynamicTableProps> = (props) => {
	const { Column } = Table;

	const {
		userDataSource,
		performSearchHandler,
		performFilterHandler,
		searchValue,
		filterValue,
		showModal,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditSelectedUser,
		performSortHandler,
		totalRecords,
		currentPage,
		PageSize,
		modifyPageSize,
		paginationChangeHandler,
		permissions,
		sortDirection,
		isLoading,
		reinviteUserAction,
	} = props;

	const context = useContext(PermissionContext);

	const isAddPermission = checkPermission(context.permissions, {
		permissionName: 'Users',
		permission: ['add'],
	});

	const isViewPermission = checkPermission(context.permissions, {
		permissionName: 'Users',
		permission: ['view'],
	});

	const isEditPermission = checkPermission(context.permissions, {
		permissionName: 'Users',
		permission: ['edit'],
	});

	const isDeletePermission = checkPermission(context.permissions, {
		permissionName: 'Users',
		permission: ['delete'],
	});

	// const selectedCompanyPermission = useSelector(
	// 	(state: any) =>
	// 		state?.userProfile?.data?.companies[0]?.role?.permissions
	// );

	// // Check edit user permissions
	// const isEditUserPermission = checkPermission(selectedCompanyPermission, {
	// 	permissionName: 'Users',
	// 	permission: ['edit'],
	// });

	// Edit user data handler
	const editDataHandler = (userObject: any) => {
		openDrawerHandler();
		setDrawerInfoHandler('Edit User');
		setEditSelectedUser(userObject);
	};

	// Delete user data handler
	const deleteDataHandler = (userObject: any) => {
		setEditSelectedUser(userObject);
		showModal();
	};

	// Handle user status change
	const statusHandler = async (value: any, data: any) => {
		const finalData: any = {
			updateUserCompanyRoleId: data?.userCompanyRoleId,
			status: value,
		};

		try {
			await putApi('/user/status-update', finalData);
			toastText('Status updated successfully', 'success');
		} catch (error) {
			toastText('Something went wrong in status update', 'error');
		}
		// try {
		// 	await dispatch(editUserAction(finalData));

		// 	const query: any = {
		// 		page: currentPage,
		// 		limit: PageSize,
		// 		search: searchValue,
		// 		filter: filterValue === 'active',
		// 		sort: 'firstName',
		// 		type: sortDirection === 'ascend' ? 'asc' : 'desc',
		// 	};

		// 	if (filterValue === 'all') {
		// 		delete query?.filter;
		// 	}

		// 	const res: any = await dispatch(getUsersAction(query));

		// 	if (res?.payload?.error?.status === 401) {
		// 		const response = {
		// 			isLoggedIn: true,
		// 			isErrorMessage: res?.payload?.error?.message,
		// 		};
		// 		dispatch(userCheck(response));
		// 	}
		// } catch (error) {
		// 	// Handle errors here
		// 	console.error('An error occurred:', error);
		// }
	};

	// For handle the table change click
	const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
		performSortHandler &&
			performSortHandler(columnInfo.order, pageInfo.current);
	};

	return (
		<div className={styles['dynamic-table']}>
			<SearchAndFilter
				performSearchHandler={performSearchHandler}
				searchValue={searchValue}
				performFilterHandler={performFilterHandler}
				filterValue={filterValue}
				PageSize={PageSize}
			/>
			<Table
				dataSource={userDataSource}
				pagination={{
					total: totalRecords,
					current: currentPage,
					onChange: paginationChangeHandler,
					pageSize: PageSize,
					showSizeChanger: true,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				scroll={{ y: 'calc(100vh - 400px)' }}
				loading={isLoading}
			>
				<Column
					title="Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					render={(value: any) => (value ? value : '-')}
				/>
				<Column
					title="Role"
					dataIndex="roleName"
					key="roleName"
					className="bg-white"
					render={(value: any) => (value ? value : '-')}
				/>
				<Column
					title="Email"
					dataIndex="email"
					key="email"
					className="bg-white"
					render={(value: any) => (value ? value : '-')}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					render={(value: any, data: any) => {
						return (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Space>
									<Switch
										key={Math.random()}
										defaultChecked={value}
										onChange={(e) => statusHandler(e, data)}
										disabled={
											!isEditPermission ||
											data.userId === context.userId ||
											!data.isVerified ||
											(data.isAdmin &&
												!context.isSuperAdmin)
										}
									/>
								</Space>
							</div>
						);
					}}
				/>
				{(isEditPermission || isDeletePermission) && (
					<Column
						title="Action"
						dataIndex="action"
						key="action"
						className="bg-white"
						width={'15%'}
						render={(value, data: any) => {
							return (
								<Space size={20}>
									{isEditPermission &&
									!(
										data.isAdmin &&
										context.isSuperAdmin === false
									) &&
									data.userId !== context.userId ? (
										<div
											className="cursor-pointer flex align-center justify-center"
											onClick={() =>
												editDataHandler(data)
											}
										>
											<EditActionSvg />
										</div>
									) : (
										<div className=" cursor-not-allowed flex align-center justify-center">
											<EditActionSvg />
										</div>
									)}

									{isDeletePermission &&
									!(
										data.isAdmin &&
										context.isSuperAdmin === false
									) &&
									data.userId !== context.userId ? (
										<div
											className="cursor-pointer flex align-center justify-center"
											onClick={() =>
												deleteDataHandler(data)
											}
										>
											<DeleteActionSvg />
										</div>
									) : (
										<div className=" cursor-not-allowed flex align-center justify-center">
											<DeleteActionSvg />
										</div>
									)}

									{data.invitationStatus === 'Pending' && (
										<Tooltip title="Reinvite">
											<div
												className="cursor-pointer flex align-center justify-center"
												onClick={() => {
													reinviteUserAction(data);
												}}
											>
												<MailOutlined />
											</div>
										</Tooltip>
									)}
								</Space>
							);
						}}
					/>
				)}
			</Table>
		</div>
	);
};

export default UserTable;
