import { SyncOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type RefreshProps = {
	isLoading: boolean;
	fetchData: () => void;
	title: string;
};

const RefreshComponent = (props: RefreshProps) => {
	const { isLoading, fetchData, title } = props;
	return (
		<>
			<Tooltip title={title}>
				<SyncOutlined
					style={{
						fontSize: '22px',
						cursor: isLoading ? 'not-allowed' : 'pointer',
						color: '#584495',
						transition: 'transform 0.3s',
					}}
					onClick={!isLoading ? fetchData : undefined}
				/>
			</Tooltip>
		</>
	);
};

export default RefreshComponent;
