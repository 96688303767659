import { Form, Input } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';

const AddBranchCodeBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const { closeDrawerByAnimation, fetchBranchCodes, editSelected } = props;

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
			font: '16px',
			minHeight: '48px',
			minWidth: '60px',
		},
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			font: '16px',
			minHeight: '48px',
			minWidth: '110px',
			onclick: () => {},
		},
	];
	const handleSubmit = async (values: any) => {
		if (editSelected) {
			let data: any = {
				branchCodeId: editSelected?.id,
				...values,
			};

			await updateBranchCode(data);
		} else {
			await addBranchCode(values);
		}
	};

	const updateBranchCode = async (categoryCodeData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/branchCode', categoryCodeData);
			let message =
				response?.data?.message || 'Branch code updated successfully.';
			toastText(message, 'success');
			fetchBranchCodes();
			closeDrawerByAnimation();
		} catch (err: any) {
			let message = 'Something went wrong in updating branch code.';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addBranchCode = async (finalData: any) => {
		try {
			setLoading(true);

			const response = await postApi('/branchCode', finalData);
			let message =
				response?.data?.message || 'Branch code created successfully.';
			fetchBranchCodes();
			closeDrawerByAnimation();
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to branch code.';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={editSelected}
					autoComplete="off"
				>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Branch Code'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'code'}
							rules={[
								{
									required: true,
									message: 'Branch code required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Branch code cannot be empty'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Branch code length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Branch Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Branch name required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Branch name length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Branch Address'}{' '}
							{/* <span className="required-color">*</span> */}
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'address'}
							rules={[
								{
									message: 'Please enter your branch address',
									validateTrigger: 'onChange',
								},
								// {
								// 	max: 50,
								// 	message:
								// 		'Branch address length must be less than 50 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<textarea
								className={styles['side-drawer-form--textarea']}
							/>
						</Form.Item>
					</div>

					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Branch City'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'city'}
							rules={[
								{
									required: true,
									message: 'Branch city required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Branch city length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddBranchCodeBody;
