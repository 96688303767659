import { Button, Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import style from './index.module.scss';
import { TableActionHeaderProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

// Creating the global action component for table
const TableActionHeader: FC<TableActionHeaderProps> = (props) => {
	// Destructure props
	const { title, children, horizontalLine, isTitle } = props;
	const location = useLocation();
	const navigate = useNavigate();

	const [paths, setPaths] = useState([]);

	useEffect(() => {
		const _paths: any = [];
		let href = '';
		location.pathname?.split('/')?.forEach((path, index) => {
			if (index > 0) {
				if (path.length > 0) {
					let _path = path
						.split('-')
						.map(
							(word) =>
								word.charAt(0).toUpperCase() + word.slice(1)
						)
						.join(' ');

					if (_path === 'Coa') {
						_path = 'Sage 300 Chart of Accounts';
					}

					href = `${href}/${path}`;
					_paths.push({
						title: _path,
						href: href,
						onClick: () => navigate(href),
					});
				} else {
					_paths.push({
						title: 'Dashboard',
						href: '/',
						onClick: () => navigate('/'),
					});
				}
			}
		});

		setPaths(_paths);
	}, [location, navigate]);

	const handleClick = (href: string) => {
		navigate(href);
	};

	// JSX
	return (
		<div className={style['table-action-header']}>
			<Row
				className={style['table-action-header__wrapper']}
				align={'middle'}
				justify={'space-between'}
			>
				<Col className={style['table-action-header__title']}>
					<h3>
						{isTitle ? (
							title
						) : (
							<Breadcrumb separator=">">
								{paths.map(({ title, href }, index) => (
									<Breadcrumb.Item key={index}>
										<span
											style={{ cursor: 'pointer' }}
											onClick={() => handleClick(href)}
										>
											{title}
										</span>
									</Breadcrumb.Item>
								))}
							</Breadcrumb>
						)}
					</h3>
				</Col>
				<Col className={style['table-action-header__actions']}>
					{children}
					{location.pathname?.split('/').includes('settings') && (
						<Button
							style={{ background: 'none', border: 'none' }}
							onClick={() => {
								navigate('/');
							}}
						>
							<CloseOutlined style={{ fontSize: '20px' }} />
						</Button>
					)}
				</Col>
			</Row>
			{horizontalLine && (
				<hr className={style['table-action-header-line']}></hr>
			)}
		</div>
	);
};

export default TableActionHeader;
