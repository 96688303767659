import { Space, Switch, Table } from 'antd';
import { leaveManagementApi } from 'Api/masters/leave-management';
import { PermissionContext } from 'components/Global/AuthLayout';
import Ellipse from 'components/Global/Ellipse';
import { useContext } from 'react';
import { DeleteActionSvg, EditActionSvg, HistoryIcon } from 'utils/svgs';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
const { Column } = Table;

type Props = {
	leaveManagementData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setDeleteLeaveManagementId: any;
	handleEditData: any;
	isLoading: boolean;
	showDeleteModal: () => void;
	openHistoryModal: (data: any) => void;
};

const LeaveManagementTable = (props: Props) => {
	const {
		leaveManagementData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		handleEditData,
		setDeleteLeaveManagementId,
		isLoading,
		showDeleteModal,
		openHistoryModal,
	} = props;

	const permissionContext = useContext(PermissionContext);
	const isView = permissionContext.allowedPermissions.includes(
		'View_Leave_Management'
	);

	// Edit user data handler
	const editDataHandler = (selectedObj: any) => {
		handleEditData(selectedObj);
	};

	// Delete user data handler
	const deleteDataHandler = (id: string) => {
		setDeleteLeaveManagementId(id);
		showDeleteModal();
	};

	const statusHandler = async (value: any, data: any) => {
		const finalData: any = {
			leaveManagementId: data?.id,
			status: value,
		};
		try {
			await leaveManagementApi.updateLeaveManagementStatus(finalData);
			toastText('Status updated successfully', 'success');
		} catch (error) {
			toastText('Something went wrong in status update', 'error');
		}
	};
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={leaveManagementData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				className="leaveManagementModal"
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Updated By"
					dataIndex="fullName"
					key="fullName"
					className="bg-white"
					width={'20%'}
				/>
				<Column
					title="Name"
					dataIndex="name"
					key="name"
					sorter={true}
					className="bg-white"
					width={'20%'}
				/>

				<Column
					title="Description"
					dataIndex="description"
					key="description"
					className="bg-white"
					width={'20%'}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					width={'10%'}
					render={(value: any, data: any) => {
						return (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Space>
									<Switch
										key={Math.random()}
										defaultChecked={value}
										onChange={(e) => statusHandler(e, data)}
									/>
								</Space>
							</div>
						);
					}}
				/>
				<Column
					title="Action"
					key="action"
					width={'10%'}
					className="bg-white"
					render={(_: any, data: any) => (
						<Space size={20}>
							{permissionContext.allowedPermissions.includes(
								'Edit_Leave_Management'
							) ? (
								<div
									className="cursor-pointer  flex align-center justify-center"
									onClick={() => editDataHandler(data)}
								>
									<EditActionSvg />
								</div>
							) : (
								<div className="cursor-not-allowed  flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</div>
							)}

							{permissionContext.allowedPermissions.includes(
								'Delete_Leave_Management'
							) && !data.isDefault ? (
								<div
									className="cursor-pointer flex align-center justify-center"
									onClick={() => deleteDataHandler(data.id)}
								>
									<DeleteActionSvg />
								</div>
							) : (
								<div className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</div>
							)}

							{isView ? (
								<div
									className="cursor-pointer flex align-center justify-center"
									onClick={() => openHistoryModal(data)}
								>
									<HistoryIcon />
								</div>
							) : (
								<div className="cursor-not-allowed flex align-center justify-center">
									<HistoryIcon color="#928F8F" />
								</div>
							)}
						</Space>
					)}
				/>
			</Table>
		</div>
	);
};

export default LeaveManagementTable;
