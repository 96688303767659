import {
	DollarOutlined,
	ExclamationCircleOutlined,
	UploadOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import { timeLogsApi } from 'Api/timeLogs';
import { Button, Modal, Switch, Table, Tag, Tooltip, Upload } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import { getApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputHoursField from 'components/InputHoursField';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { AppDispatch } from 'redux/store';

import styles from './index.module.scss';
import './index.scss';
import { useDispatch } from 'react-redux';
import {
	checkHoliday,
	checkSunday,
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedShift: string;
	selectedShiftDetails: any;
	fetchShifts: any;
	holidays: any;
	pendingDate: any;
};

const AddPendingLogsModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		selectedShift,
		selectedShiftDetails,
		fetchShifts,
		holidays,
		pendingDate,
	} = props;
	console.log('props: ', props);
	const dispatch = useDispatch<AppDispatch>();

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(30);

	const isSunday = checkSunday(pendingDate);
	const isHoliday = checkHoliday(pendingDate, holidays);
	// const [selectedJobGrade, setSelectedJobGrade] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [employeeHours, setEmployeeHours] = useState<any>([]);
	const [uploadedFile, setUploadedFile] = useState<any>(null);
	const [isSundayRegularHours, setIsSundayRegularHours] = useState(false);
	const [defaultHours, setDefaultHours] = useState<any>({
		regularHours:
			(isSunday && !isSundayRegularHours) || isHoliday
				? 0
				: selectedShiftDetails.defaultRegularHours,
		overtimeHours:
			isSunday && isSundayRegularHours
				? 0
				: selectedShiftDetails.defaultOverTimeHours,
		timeShiftHours: timeStringToMinutes('00:00'),
		totalHours:
			selectedShiftDetails.defaultRegularHours +
			selectedShiftDetails.defaultOverTimeHours,
	});
	const [fetchedFile, setFetchedFile] = useState<any>(null);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [pendingToggleState, setPendingToggleState] = useState(false);
	//const [shouldDisableLogs, setShouldDisableLogs] = useState(false);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		let regularHours = timeStringToMinutes('00:00');
		let overtimeHours = timeStringToMinutes('00:00');
		let timeShiftHours = timeStringToMinutes('00:00');
		let totalHours = timeStringToMinutes('00:00');

		let _data = employeeHours;

		const totalRow = employeeHours.pop();
		_data = _data.map((log: any, index: number) => {
			if (
				newSelectedRowKeys.includes(log.employeeId) &&
				log.employeeId !== 'total'
			) {
				return {
					...log,
					attendanceStatus: 'PRESENT',
					regularHours:
						log.regularHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultRegularHours
							  )
							: log.regularHours,
					overtimeHours:
						log.overtimeHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultOverTimeHours
							  )
							: log.overtimeHours,
					timeShiftHours:
						log.timeShiftHours === '00:00'
							? '00:00'
							: log.timeShiftHours,
					totalHours:
						log.totalHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultRegularHours +
										selectedShiftDetails.defaultOverTimeHours
							  )
							: log.totalHours,
				};
			} else {
				if (regularHours < 0) {
					regularHours = 0;
				}

				return {
					...log,
					attendanceStatus: 'ABSENT',
					regularHours: '00:00',
					overtimeHours: '00:00',
					timeShiftHours: '00:00',
					totalHours: '00:00',
				};
			}
		});

		_data.forEach((item: any) => {
			regularHours +=
				isSunday && !item.isRegularHours
					? 0
					: timeStringToMinutes(item.regularHours);
			overtimeHours +=
				isSunday && item.isRegularHours
					? 0
					: timeStringToMinutes(item.overtimeHours);
			timeShiftHours += timeStringToMinutes(item.timeShiftHours);
			totalHours += timeStringToMinutes(item.totalHours);
		});

		totalRow.regularHours = minutesToTimeString(regularHours);
		totalRow.overtimeHours = minutesToTimeString(overtimeHours);
		totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);
		totalRow.totalHours = minutesToTimeString(totalHours);

		_data.push(totalRow);
		setDefaultHours(_data);
		setEmployeeHours(_data);

		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record: any) => ({
			disabled: record.isTotal || record.isOnLeave || record.isPayrollRun,
		}),
	};

	const handleToggleChange = (checked: any) => {
		setPendingToggleState(checked);
		setShowConfirmModal(true);
	};

	const confirmToggle = () => {
		setIsSundayRegularHours(pendingToggleState);

		if (pendingToggleState) {
			let regularHours = 0;
			let overtimeHours = 0;
			let timeShiftHours = 0;
			let totalHours = 0;

			const _data = employeeHours.map((item: any) => {
				if (item.attendanceStatus === 'PRESENT') {
					const updatedItem = {
						...item,
						regularHours: item.overtimeHours,
						overtimeHours: '00:00',
					};
					regularHours += timeStringToMinutes(
						updatedItem.regularHours
					);
					timeShiftHours += timeStringToMinutes(
						updatedItem.timeShiftHours
					);
					totalHours += timeStringToMinutes(updatedItem.totalHours);
					return updatedItem;
				} else {
					regularHours += timeStringToMinutes(item.regularHours);
					timeShiftHours += timeStringToMinutes(item.timeShiftHours);
					totalHours += timeStringToMinutes(item.totalHours);
					return item;
				}
			});

			const totalRow = _data.find(
				(item: any) => item.employeeId === 'total'
			);
			if (totalRow) {
				totalRow.regularHours = minutesToTimeString(regularHours);
				totalRow.overtimeHours = minutesToTimeString(overtimeHours);
				totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);
				totalRow.totalHours = minutesToTimeString(totalHours);
			}
			setEmployeeHours(_data);
		} else {
			let regularHours = 0;
			let overtimeHours = 0;
			let timeShiftHours = 0;
			let totalHours = 0;

			const _data = employeeHours.map((item: any) => {
				if (item.attendanceStatus === 'PRESENT') {
					const updatedItem = {
						...item,
						overtimeHours: item.regularHours,
						regularHours: '00:00',
					};

					overtimeHours += timeStringToMinutes(
						updatedItem.overtimeHours
					);
					timeShiftHours += timeStringToMinutes(
						updatedItem.timeShiftHours
					);
					totalHours += timeStringToMinutes(updatedItem.totalHours);
					return updatedItem;
				} else {
					return item;
				}
			});

			const totalRow = _data.find(
				(item: any) => item.employeeId === 'total'
			);
			if (totalRow) {
				totalRow.regularHours = minutesToTimeString(regularHours);
				totalRow.overtimeHours = minutesToTimeString(overtimeHours);
				totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);
				totalRow.totalHours = minutesToTimeString(totalHours);
			}

			setEmployeeHours(_data);
		}
		setShowConfirmModal(false);
	};

	const cancelToggle = () => {
		setShowConfirmModal(false);
	};
	const handleLogChange = (id: string, name: string, value: any) => {
		let regularHours = 0;
		let timeShiftHours = 0;
		let overtimeHours = 0;
		let totalHours = 0;

		const _data = employeeHours.map((item: any, index: number) => {
			if (item.employeeId === id) {
				regularHours += timeStringToMinutes(
					name === 'regularHours' ? value : item.regularHours
				);
				timeShiftHours += timeStringToMinutes(
					name === 'timeShiftHours' ? value : item.timeShiftHours
				);
				overtimeHours += timeStringToMinutes(
					name === 'overtimeHours' ? value : item.overtimeHours
				);
				totalHours += timeStringToMinutes(
					name === 'totalHours' ? value : item.totalHours
				);
				return {
					...item,
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					[name]: value,
				};
			} else if (index === employeeHours.length - 1) {
				return {
					...item,
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					regularHours:
						isSunday && !isSundayRegularHours
							? '00:00'
							: minutesToTimeString(regularHours),
					timeShiftHours: minutesToTimeString(timeShiftHours),
					overtimeHours:
						isSunday && isSundayRegularHours
							? '00:00'
							: minutesToTimeString(overtimeHours),
					totalHours: minutesToTimeString(totalHours),
				};
			} else {
				regularHours +=
					isSunday && !isSundayRegularHours
						? 0
						: timeStringToMinutes(item.regularHours);
				timeShiftHours += timeStringToMinutes(item.timeShiftHours);
				overtimeHours +=
					isSunday && isSundayRegularHours
						? 0
						: timeStringToMinutes(item.overtimeHours);
				totalHours += timeStringToMinutes(item.totalHours);
				return {
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					...item,
				};
			}
		});

		setEmployeeHours(_data);
	};

	const fetchTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(pendingDate).format('YYYY-MM-DD'),
				// jobGrade: selectedJobGrade,
				page: currentPage,
				pageSize: pageSize,
				shiftId: selectedShift,
			};

			const response = await timeLogsApi.getDefaultTimeLogs(query);
			const defaultSelectedKeys = response.data.data.data
				.filter(
					(item: any) =>
						item.attendanceStatus === 'PRESENT' && !item.isOnLeave
				) // Replace with your condition
				.map((item: any) => item.employeeId);

			setIsSundayRegularHours(response.data.data.isRegularHours);
			setEmployeeHours(response.data.data.data);
			setSelectedRowKeys(defaultSelectedKeys);
			setTotalRecords(response.data.total);
			//	setShouldDisableLogs(response.data.shouldDisableLogs);
		} catch (err) {
			//error
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	const validateEmployeeHours = () => {
		let invalidData = false;
		const _data = employeeHours.map((item: any) => {
			let data = { ...item };
			if (item.attendanceStatus === 'ABSENT') {
				if (item.regularHours !== '00:00') {
					data['invalidRegularHours'] = true;
				}
				if (item.overtimeHours !== '00:00') {
					data['invalidOvertimeHours'] = true;
				}
				if (item.timeShiftHours !== '00:00') {
					data['invalidTimeShiftHours'] = true;
				}
			} else if (item.attendanceStatus === 'PRESENT') {
				if (
					!isSunday &&
					!isHoliday &&
					!item.isOnLeave &&
					item.regularHours === '00:00'
				) {
					data['invalidRegularHours'] = true;
				}
			}

			// Check if any invalid field is true
			if (
				data.invalidRegularHours ||
				data.invalidOvertimeHours ||
				data.invalidTimeShiftHours
			) {
				invalidData = true;
			}
			return data;
		});
		setEmployeeHours(_data);
		return invalidData;
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		if (pendingDate) {
			fetchTimeLogs();
		}
	}, [pendingDate]);

	useEffect(() => {
		if (isOpen) {
			fetchUploadedFile();
		}
	}, [isOpen, pendingDate]);

	// useEffect(() => {
	// 	if (dayjs() > dayjs(shiftStartDate) && dayjs() > dayjs(shiftEndDate)) {
	// 		setDate(dayjs(shiftStartDate));
	// 	} else {
	// 		setDate(dayjs());
	// 	}
	// }, [shiftStartDate]);

	const handleSubmit = async () => {
		try {
			setIsUpdateLoading(true);

			const data = {
				date: dayjs(pendingDate).format('YYYY-MM-DD'),
				isRegularHours: isSundayRegularHours,
				shiftId: selectedShift,
				employeeHoursData: employeeHours
					.filter((item: any) => item.employeeId !== 'total')
					.map((log: any) => ({
						employeeId: log.employeeId,
						regularHours:
							isSunday && !isSundayRegularHours
								? 0
								: timeStringToMinutes(log.regularHours),
						timeShiftHours: timeStringToMinutes(log.timeShiftHours),
						overtimeHours:
							isSunday && isSundayRegularHours
								? 0
								: timeStringToMinutes(log.overtimeHours),
						attendanceStatus: log.attendanceStatus,
					})),
			};

			const isInvalid = validateEmployeeHours();

			if (!isInvalid) {
				await timeLogsApi.createTimeLogs(data);

				// Check if the file needs to be uploaded
				if (
					uploadedFile[0] &&
					(!fetchedFile || uploadedFile[0].name !== fetchedFile.name)
				) {
					const formData = new FormData();

					formData.append('date', data.date);
					formData.append('shiftId', data.shiftId);
					formData.append('name', uploadedFile[0].name);
					formData.append('moduleName', 'TIMELOGS');
					formData.append('file', uploadedFile[0]);
					await timeLogsApi.uploadAttachment(formData);
				}

				toastText('Daily Attendance updated successfully', 'success');
				handleCancel();
			}
		} catch (error: any) {
			let message = 'Something went wrong in submitting Daily Attendance';

			if (error.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsUpdateLoading(false);
			fetchShifts();
		}
	};

	const fetchUploadedFile = async () => {
		try {
			const query = {
				date: dayjs(pendingDate).format('YYYY-MM-DD'),
				shiftId: selectedShift,
			};
			const response = await getApi('/timeLogs/attachment', query);

			if (response?.data?.data?.name) {
				const file = {
					name: response.data.data.name,
					status: 'done',
				};

				setUploadedFile([file]);
				setFetchedFile(file); // Update fetched file details
			} else {
				setUploadedFile([]);
				setFetchedFile(null); // Clear fetched file details
			}
		} catch (err) {
			//error
		}
	};
	const uploadFile = async (options: any) => {
		const { onSuccess, onError, file, onProgress } = options;

		const formattedDate = dayjs(pendingDate).format('YYYY-MM-DD');
		const fmData = new FormData();

		fmData.append('date', formattedDate);
		fmData.append('shiftId', selectedShift);
		fmData.append('moduleName', 'TIMELOGS');
		fmData.append('file', file);

		try {
			// Simulate the file upload and update state
			setUploadedFile([file]); // Update state with the uploaded file

			// Call the API (commented out)
			// const response =
			// onSuccess(response.data);
			onSuccess(null, file);
			toastText(`${file.name} file uploaded successfully`, 'sucess');
		} catch (error) {
			onError(error);
			toastText(`${file.name} file upload failed.`, 'error');
		}
	};

	const uploadProps: any = {
		customRequest: uploadFile,
		showUploadList: {
			showRemoveIcon: false,
			showPreviewIcon: true,
			showDownloadIcon: false,
		},
		fileList: uploadedFile,
		onChange(info: any) {
			if (info.file.status !== 'uploading') {
				if (info.file.status === 'done') {
					setUploadedFile([info.file]);
				} else if (info.file.status === 'error') {
					// Handle error, if needed
				}
			}
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 3,
			format: (percent: number) =>
				percent && `${parseFloat(percent.toFixed(2))}%`,
		},
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleCancel();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
		{
			text: 'Submit',
			isLoading: isUpdateLoading,
			className: 'btn-blue',
			isSubmit: false,

			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
	];
	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				cancelToggle();
			},
		},
		{
			isLoading: false,
			text: 'Confirm',
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				confirmToggle();
			},
		},
	];
	const badgeContent =
		isSunday && isHoliday
			? {
					text: 'Sunday and Holiday',
					tooltip:
						'All hours on Sunday and Holiday are considered overtime',
			  }
			: isSunday
			? {
					text: 'Sunday',
					tooltip: 'All hours on Sunday are considered overtime',
			  }
			: isHoliday
			? {
					text: 'Holiday',
					tooltip: 'All hours on Holiday are considered overtime',
			  }
			: null;

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{
				minWidth: '1200px',
			}}
			onCancel={handleCancel}
			footer={() => {
				return (
					<div className={`${styles['modal']} modal`}>
						<div className={styles['modal-header']}>
							<Title level={4}>
								<span>Add Daily Attendance</span>
							</Title>
							{badgeContent && (
								<Tooltip title={badgeContent.tooltip}>
									<Tag
										color="red"
										style={{
											cursor: 'pointer',
											fontSize: '14px',
											padding: '10px 10px 5px 10px',
										}}
									>
										<WarningOutlined /> {''}{' '}
										{badgeContent.text}
									</Tag>
								</Tooltip>
							)}
							<div className={styles['modal-header-filter']}>
								{isSunday && (
									<div className={styles['header-toggle']}>
										<Tooltip
											title={
												'When enabled Sunday calculation will be based on regular work hours , else It will be based on overtime hours'
											}
										>
											<Switch
												checked={isSundayRegularHours}
												onChange={handleToggleChange}
												disabled={isTimeLogsLoading}
											/>{' '}
											Sunday as regular hours
										</Tooltip>
									</div>
								)}
								<DatePickerField
									name="date"
									onChange={() => {}}
									required={false}
									value={pendingDate && dayjs(pendingDate)}
									isError={false}
									disabled={isTimeLogsLoading}
									disabledBeforeDates={dayjs(pendingDate)}
									disabledAfterDates={dayjs(pendingDate)}
									placeholder="Select Date"
								/>
								{/* <SelectDropdown
									options={jobGrades}
									onChange={(value: any) => {
										
										setSelectedJobGrade(value);
									}}
									required={false}
									value={selectedJobGrade}
									isError={false}
									placeholder="Select Job Grade"
									helperText=""
									size="large"
									loading={isLoading}
									disabled={isLoading}
								/> */}
							</div>
						</div>
						<div className={styles['modal-body']}>
							<Table
								dataSource={employeeHours}
								scroll={{ y: 'calc(100vh - 360px)' }}
								pagination={{
									total: totalRecords,
									current: currentPage,
									pageSize: pageSize,
									showSizeChanger: false,
									pageSizeOptions: [10, 20, 50, 100, 200],
								}}
								onChange={tableChangeHandler}
								rowKey={(record) => record.employeeId}
								loading={isTimeLogsLoading}
								rowSelection={rowSelection}
								rowClassName={(record: any) => {
									return record.isTotal ? 'total-row' : '';
								}}
							>
								<Column
									title="Employee name & code"
									dataIndex="employeeName"
									key="employeeName"
									className="bg-white"
									width="24%"
									render={(text: string, record: any) => (
										<Tooltip
											title={
												record.isOnLeave
													? 'On leave'
													: ''
											}
										>
											<span
												style={{
													color: record.isOnLeave
														? 'red'
														: 'inherit',
													display: 'flex',
													alignItems: 'center',
													gap: '0.5rem',
												}}
											>
												{/* Conditionally show the icon based on payroll status */}
												{record.isPayrollRun &&
													record?.payrollStatus && (
														<Tooltip
															title={
																record.payrollStatus ===
																	'APPROVED' ||
																record.payrollStatus ===
																	'FINALIZED'
																	? 'Payroll is Approved/Finalized'
																	: 'Payroll is Pending'
															}
														>
															<DollarOutlined
																style={{
																	color:
																		record.payrollStatus ===
																			'APPROVED' ||
																		record.payrollStatus ===
																			'FINALIZED'
																			? 'green'
																			: 'grey',
																}}
															/>
														</Tooltip>
													)}
												{text}
											</span>
										</Tooltip>
									)}
								/>

								<Column
									title="Regular hours"
									dataIndex="regularHours"
									key="regularHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										return data.isTotal ? (
											(isSunday &&
												!isSundayRegularHours) ||
											isHoliday ||
											data.isOnLeave ? (
												'00:00'
											) : (
												record
											)
										) : (
											<InputHoursField
												value={
													(isSunday &&
														!isSundayRegularHours) ||
													isHoliday ||
													data.isOnLeave
														? '00:00'
														: record
												}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'regularHours',
														value
													);
												}}
												disabled={
													//			shouldDisableLogs ||
													data.attendanceStatus ===
														'ABSENT' ||
													data.isOnLeave ||
													data.isPayrollRun ||
													(isSunday &&
														!isSundayRegularHours) ||
													isHoliday
												}
												// disabled={
												// 	data.attendanceStatus ===
												// 		'ABSENT' ||
												// 	data.isOnLeave ||
												// 	isSunday === true ||
												// 	isHoliday
												// }
												label="Regular hours"
												required={true}
												name="regularHours"
												isError={
													data.invalidRegularHours
												}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>

								<Column
									title="Overtime hours"
									dataIndex="overtimeHours"
									key="overtimeHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										return data.isTotal ? (
											(isSunday &&
												isSundayRegularHours) ||
											data.isOnLeave ? (
												'00:00'
											) : (
												record
											)
										) : (
											<InputHoursField
												value={
													data.isOnLeave ||
													(isSunday &&
														isSundayRegularHours)
														? '00:00'
														: record
												}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'overtimeHours',
														value
													);
												}}
												disabled={
													//		shouldDisableLogs ||
													data.attendanceStatus ===
														'ABSENT' ||
													data.isOnLeave ||
													data.isPayrollRun ||
													(isSunday &&
														isSundayRegularHours)
												}
												label="Overtime hours"
												required={true}
												name="overtimeHours"
												isError={
													data.invalidOvertimeHours
												}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>
								<Column
									title="Time shift difference"
									dataIndex="timeShiftHours"
									key="timeShiftHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										return data.isTotal ? (
											data.isHoliday || data.isOnLeave ? (
												'00:00'
											) : (
												record
											)
										) : (
											<InputHoursField
												value={record}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'timeShiftHours',
														value
													);
												}}
												disabled={
													//			shouldDisableLogs ||
													data.attendanceStatus ===
														'ABSENT' ||
													isHoliday ||
													data.isOnLeave ||
													data.isPayrollRun ||
													(isSunday &&
														!data.isRegularHours)
												}
												label="Time Shift Difference"
												required={true}
												name="timeShiftHours"
												isError={
													data.invalidTimeShiftHours
												}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>
								<Column
									title="Total hours"
									dataIndex="totalHours"
									key="totalHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										const totalMinutes = data.isOnLeave
											? timeStringToMinutes('00:00')
											: timeStringToMinutes(
													(isSunday &&
														!isSundayRegularHours) ||
														isHoliday
														? '00:00'
														: data.regularHours
											  ) +
											  timeStringToMinutes(
													isSunday &&
														isSundayRegularHours
														? '00:00'
														: data.overtimeHours
											  ) +
											  timeStringToMinutes(
													data.timeShiftHours
											  );
										return (
											<span className="color-purple">
												{' '}
												{minutesToTimeString(
													totalMinutes
												)}
											</span>
										);
									}}
								/>
							</Table>
						</div>

						<div>
							{uploadedFile && uploadedFile[0] && (
								<>
									<Tooltip
										title={
											uploadedFile &&
											uploadedFile[0]?.name
										}
										placement="top"
									>
										<a
											href={uploadedFile[0].name}
											target="_blank"
											rel="noopener noreferrer"
											className="pdf-btn"
											download={
												uploadedFile &&
												uploadedFile[0].name
											}
										>
											<FaFilePdf
												color="#d32f2f"
												size={20}
											/>
										</a>
									</Tooltip>
								</>
							)}

							<Upload {...uploadProps} showUploadList={false}>
								<Tooltip
									title="Upload manual time logs in any standard format (PDF or Image)."
									placement="top"
								>
									<Button
										icon={<UploadOutlined />}
										disabled={isUpdateLoading}
									>
										Click to Upload
									</Button>
								</Tooltip>
							</Upload>
						</div>

						<div>
							<Buttons buttons={buttons} />
						</div>
					</div>
				);
			}}
			width={600}
		>
			<Modal
				title={
					<>
						<div className={styles['confirm-modal']}>
							<ExclamationCircleOutlined
								style={{
									color: 'red',
									alignItems: 'center',
								}}
							/>
						</div>
					</>
				}
				className={styles['confirm-modal-container']}
				open={showConfirmModal}
				closable={false}
				footer={[
					<div
						key={'wrapper'}
						className={styles['confirm-modal__button']}
					>
						<Buttons buttons={myButtons}></Buttons>
					</div>,
				]}
			>
				<p className={styles['confirm-modal-text']}>
					{pendingToggleState ? (
						<p>
							{' '}
							Changing Sunday hours setting will impact
							calculation of hours,<b>overtime hours</b> will be
							set to <b>regular hours</b>.
						</p>
					) : (
						<p>
							Changing Sunday hours setting will impact
							calculation of hours, <b>regular hours</b> will be
							set to <b>overtime hours</b>.
						</p>
					)}
					<p>Are you sure you want to proceed with this change?</p>
				</p>
			</Modal>
		</Modal>
	);
};

export default AddPendingLogsModal;
