import React, { useEffect, useState } from 'react';
import { getApi } from 'apis';
import { checkPermission, toastText } from 'utils/utils';
import TableActionHeader from 'components/Global/TableActionHeader';
import CoaTable from './Table';
import styles from './index.module.scss';

const CoaTables: React.FC = () => {
	const [coaData, setCoaData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		const fetchCoaData = async () => {
			setIsLoading(true);
			try {
				const query = {
					limit,
					page,
				};
				const response = await getApi('/coa/getAllCoa', query);
				setCoaData(response.data.data);
				setTotalRecords(response.data.total);
			} catch (error) {
				toastText('Failed to fetch COA data', 'error');
			} finally {
				setIsLoading(false);
			}
		};

		fetchCoaData();
	}, [page, limit]);

	const paginationChangeHandler = (pageNo: number, pageSize: number) => {
		setPage(pageNo);
		setLimit(pageSize);
	};

	return (
		<>
			<div>
				<TableActionHeader title={'Roles'}>
					<div className={styles['role-table__action']}></div>
				</TableActionHeader>
			</div>
			<div>
				<CoaTable
					data={coaData}
					loading={isLoading}
					paginationChangeHandler={paginationChangeHandler}
					totalRecords={totalRecords}
					currentPage={page}
				/>
			</div>
		</>
	);
};

export default CoaTables;
