import { Modal } from 'antd';
import React from 'react';
import './index.scss';

import Buttons from 'components/Global/Buttons';

type ConfirmModalProps = {
	visible: boolean;
	onOk: () => void;
	onCancel: () => void;
	title?: string;
	content?: string;
	okText?: string;
	cancelText?: string;
	updateEffectiveDate: any;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
	visible,
	onOk,
	onCancel,
	content = '',
	okText = 'Yes',
	cancelText = 'Cancel',
	updateEffectiveDate,
}) => {
	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			disabled: false,
			isSubmit: false,
			onclick: () => {
				onCancel();
			},
		},
		{
			text: 'Save',
			isLoading: false,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '100px',
			minHeight: '42px',
			disabled: false,
			isSubmit: true,
			onclick: () => {
				onOk();
			},
		},
	];
	return (
		<Modal
			className="confirm-modal"
			open={visible}
			onOk={onOk}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			okText={okText}
			cancelText={cancelText}
			footer={
				<div className="modal-buttons">
					<Buttons buttons={myButtons} />
				</div>
			}
		>
			<div className="modal-content">
				<p>{content}</p>
			</div>
		</Modal>
	);
};

export default ConfirmModal;
