import { timeLogsApi } from 'Api/timeLogs';
import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { minutesToTimeString, timeStringToMinutes } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedShift: string;
	selectedDate: string;
	isView: boolean;
};

const AddModal = (props: Props) => {
	const { isOpen, handleCancel, selectedShift, selectedDate, isView } = props;

	const dispatch = useDispatch<AppDispatch>();

	const isLoading = useSelector(
		(state: any) => state.constantDropdown.isLoading
	);

	const [date, setDate] = useState<any>(dayjs(selectedDate));
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);

	const [employeeHours, setEmployeeHours] = useState<any>([]);

	const handleDate = (value: string) => {
		setDate(value);
	};

	const fetchTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				shiftId: selectedShift,
			};

			const response = await timeLogsApi.getDefaultTimeLogs(query);

			setEmployeeHours(response.data.data.data);
		} catch (err) {
			// toastText('error', err.message);
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		fetchTimeLogs();
	}, [date]);

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{
				minWidth: '1200px',
				zIndex: '99999',
			}}
			onCancel={handleCancel}
			footer={() => {
				return (
					<div className={`${styles['modal']} modal`}>
						<div className={styles['modal-header']}>
							<Title level={4}>
								<span>Daily Attendance</span>
							</Title>
							<div className={styles['modal-header-filter']}>
								<DatePickerField
									name="date"
									onChange={handleDate}
									required={false}
									value={date}
									isError={false}
									disabled={isLoading || isView}
								/>
							</div>
						</div>
						<div className={styles['modal-body']}>
							<Table
								dataSource={employeeHours}
								// scroll={{ y: 'calc(100vh - 360px)' }}
								pagination={false}
								rowKey={(record) => record.employeeId}
								loading={isTimeLogsLoading}
								rowClassName={(record: any) => {
									return record.isTotal ? 'total-row' : '';
								}}
							>
								<Column
									title="Employee name & code"
									dataIndex="employeeName"
									key="employeeName"
									className="bg-white"
									width="24%"
								/>
								<Column
									title="Regular hours"
									dataIndex="regularHours"
									key="regularHours"
									className="bg-white"
									width="19%"
								/>
								<Column
									title="Overtime hours"
									dataIndex="overtimeHours"
									key="overtimeHours"
									className="bg-white"
									width="19%"
								/>
								<Column
									title="Time shift difference"
									dataIndex="timeShiftHours"
									key="timeShiftHours"
									className="bg-white"
									width="19%"
								/>
								<Column
									title="Total hours"
									dataIndex="totalHours"
									key="totalHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										const totalMinutes =
											timeStringToMinutes(
												data.regularHours
											) +
											timeStringToMinutes(
												data.overtimeHours
											) +
											timeStringToMinutes(
												data.timeShiftHours
											);
										return (
											<span className="color-purple">
												{' '}
												{minutesToTimeString(
													totalMinutes
												)}
											</span>
										);
									}}
								/>
							</Table>
						</div>
					</div>
				);
			}}
			width={600}
		></Modal>
	);
};

export default AddModal;
