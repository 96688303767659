import React, { useEffect, useState } from "react";
import PayeTabHeader from "./Header/index";
import { PayeTabProps } from "./types";
import { SideDrawerWrapper } from "components/Global";
import { TablePagination, TableSorter } from "interfaces/global.interface";
import AddPayeSideDrawer from "./AddPayeSideDrawer";
import PayeLatestTable from "./PayeTable";
import HistoryModal from "./PayeHistoryModal";
import { payeApi } from "Api/payeApi";
import { toastText } from "utils/utils";
import { Modal } from "antd";

const PayeComponent = (props: PayeTabProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [latestPayeDetails, setLatestPayeDetails] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryLoading, setHistoryLoading] = useState(false);
  const [allPayeDetails, setAllPayeDetails] = useState<any[]>([]);

  const tableChangeHandler = (
    pagination: TablePagination,
    filters: any,
    sorter: TableSorter
  ) => {
    setTotalRecords(pagination.total);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortOrder(sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : '');
    setSortField(sorter.field);
  };

  const sidebarDrawerOpen = () => {
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
  }

  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
  };

  const closeDrawerByAnimation = () => {
    setDrawerAnimation(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const fetchLatestPayeData = async () => {
    try {
      setIsLoading(true);
      const payeData = await payeApi.getLatestPaye();
      setLatestPayeDetails(payeData.data.data);
    } catch (error: any) {
      if (error.response?.data?.error?.code !== 103) {
        toastText('Something went wrong in fetching Paye Details', 'error');
      } else {
        toastText(error.response?.data?.error?.message, 'error');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const fetchAllPayeDetails = async () => {
    try {
      const query = {
        page: currentPage,
        sortBy: sortField,
        sortOrder: sortOrder,
        pageSize: pageSize,
      };

      setHistoryLoading(true);
      const allData = await payeApi.getPayeHistory(query);
      setAllPayeDetails(allData.data.data);
      setTotalRecords(allData.data.total);
    } catch (error: any) {
      if (error.response?.data?.error?.code !== 103) {
        toastText('Something went wrong in fetching history', 'error');
      } else {
        toastText(error.response?.data?.error?.message, 'error');
      }
    } finally {
      setHistoryLoading(false);
    }
  }

  useEffect(() => {
    fetchLatestPayeData();
  }, []);

  useEffect(() => {
    fetchAllPayeDetails();
  }, [pageSize, currentPage, sortOrder, sortField]);

  return (
    <>
      <div>
        <PayeTabHeader
          sidebarDrawerOpen={sidebarDrawerOpen}
          PayeExist={latestPayeDetails.length > 0}
          isLoading={isLoading}

        />
        <PayeLatestTable
          latestPayeDetails={latestPayeDetails}
          isLoading={isLoading || isHistoryLoading}
          showModal={showModal}
          totalRecords={totalRecords}
        />
      </div>


      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={latestPayeDetails.length > 0 ? 'Edit PAYE' : 'Add PAYE'}
          position="right"
          width="half"
        >
          <AddPayeSideDrawer
            closeDrawerByAnimation={closeDrawerByAnimation}
            fetchLatestPayeData={fetchLatestPayeData}
            fetchAllPayeDetails={fetchAllPayeDetails}
          />
        </SideDrawerWrapper>
      )}
      {isModalOpen && (
        <HistoryModal
          closeModal={closeModal}
          open={isModalOpen}
          totalRecords={totalRecords}
          pageSize={pageSize}
          currentPage={currentPage}
          tableChangeHandler={tableChangeHandler}
          allPayeDetails={allPayeDetails}
          isLoading={isHistoryLoading}
          fetchAllPayeDetails={fetchAllPayeDetails}
        />
      )}
    </>
  );
};

export default PayeComponent;