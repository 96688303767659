import { deleteApi, getApi, getApiCSV, postApi, putApi } from 'apis';

const createEmployee = async (data: any) => {
	return await postApi('/employee', data);
};
const getEmployeeDetailsById = async (id: string) => {
	return await getApi(`/employee/${id}`);
};

const getEmployeeActivityData = async (parmas: any) => {
	return await getApi(`/activity`, parmas);
};

const updateEmployee = async (employeeId: string, data: any) => {
	return await putApi(`/employee/${employeeId}`, data);
};

const createEmployeeEarnings = async (data: any) => {
	return await postApi('/employee/earning', data);
};

const getEmployeeEarnings = async (data: any) => {
	return await getApi('/employee/earning', data);
};
const getEmployeeDeductions = async (data: any) => {
	return await getApi('/employee/deduction', data);
};
const createEmployeeDeductions = async (data: any) => {
	return await postApi('/employee/deduction', data);
};

const uploadEmployeeDocument = async (data: any) => {
	return await postApi('/employee/upload-docs', data, true);
};

const fetchEmployeeDocuments = async (employeeId: string) => {
	return await getApi(`/employee/docs/${employeeId}`);
};

const deleteEmployee = async (employeeId: any) => {
	return await deleteApi(`/employee/delete/${employeeId}`);
};

const uploadEmployeeCsv = async (data: any) => {
	return await postApi('/employee/csv-upload', data);
};

const downloadSalaryCsvTemplate = async () => {
	return await getApiCSV('/employee/salary/csv-download');
};

const downloadEmployeeCsvTemplate = async () => {
	return await getApiCSV('/employee/csv-download');
};

const uploadSalaryCsv = async (data: any) => {
	return await postApi('/employee/salary/csv-upload', data);
};

const getHourlyEmployeeOptions = async (data: any) => {
	return await getApi('/employee/hourly/employee-options', data);
};

export const employeeApi = {
	createEmployee,
	getEmployeeDetailsById,
	getEmployeeActivityData,
	updateEmployee,
	createEmployeeEarnings,
	getEmployeeEarnings,
	getEmployeeDeductions,
	createEmployeeDeductions,
	uploadEmployeeDocument,
	fetchEmployeeDocuments,
	deleteEmployee,
	// 	uploadCsv,
	// 	downloadCsvTemplate,
	downloadSalaryCsvTemplate,
	uploadSalaryCsv,
	uploadEmployeeCsv,
	downloadEmployeeCsvTemplate,
	getHourlyEmployeeOptions,
};
