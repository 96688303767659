import { Layout, Menu } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import './index.scss';
import { SidebarProps } from './types';
import { PermissionContext } from '../AuthLayout';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyWizard } from '../../../redux/actions/payrollSettingWizardAction';

const PayrollSettingsSidebar: FC<SidebarProps> = (props) => {
	const { Sider } = Layout;
	const { handleSidebar, selectedSidebar } = props;
	const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
	const { allowedPermissions } = useContext(PermissionContext);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const wizardDetails = useSelector(
		(state: any) => state.payrollSettingWizard.data
	);

	const hasPermission = (key: string) => {
		const permissionMap: { [key: string]: string } = {
			'company-setup': 'Allow_Company_Setup',
			'pay-period': 'Allow_Pay_Period',
			'statutory-components': 'Allow_Statutory_Components',
			'pay-group': 'Allow_Pay_Group',
			'earning-code': 'Allow_Earnings_Code',
			'deduction-code': 'Allow_Deduction_Code',
			'mid-month-pay': 'Allow_Mid-Month_Pay_Setup',
		};

		return allowedPermissions.includes(permissionMap[key]);
	};
	useEffect(() => {
		if (wizardDetails) {
			const completedSteps =
				Object.values(wizardDetails).filter(Boolean).length;
			const totalSteps = Object.values(wizardDetails).length;
			setIsAllStepsCompleted(completedSteps === totalSteps);
		}
	}, [wizardDetails]);
	useEffect(() => {
		dispatch(fetchCompanyWizard());
	}, [dispatch]);

	const menuItems = [
		{
			key: 'company-setup',
			label: 'Company Setup',
		},
		{
			key: 'pay-group',
			label: 'Pay Group',
		},
		{
			key: 'pay-period',
			label: 'Pay Period',
		},
		{
			key: 'statutory-components',
			label: 'Statutory Components',
		},

		{
			key: 'earning-code',
			label: 'Earning Code',
		},
		{
			key: 'deduction-code',
			label: 'Deduction Code',
		},
		{
			key: 'mid-month-pay',
			label: 'Mid-Month Pay Setup',
		},
	];

	return (
		<Sider
			style={{
				background: '#fff',
				maxHeight: '100%',
				height: '100%',
				overflow: 'auto',
			}}
			className={styles.sidebar}
		>
			{wizardDetails && Object.values(wizardDetails).length > 0 && (
				<div className={styles.sidebar__wrapper}>
					<div
						className={styles.getStarted}
						onClick={() => navigate('/settings/payroll-wizard')}
					>
						<div className={styles.getStarted__title}>
							{isAllStepsCompleted ? 'Finished' : 'Get started'}
						</div>
						<div className={styles.getStarted__progress}>
							{
								Object.values(wizardDetails).filter(Boolean)
									.length
							}
							/{Object.values(wizardDetails).length} Completed
						</div>
						<div className={styles.getStarted__bar}>
							<div
								className={styles.getStarted__bar__filled}
								style={{
									width: `${
										(Object.values(wizardDetails).filter(
											Boolean
										).length /
											Object.values(wizardDetails)
												.length) *
										100
									}%`,
								}}
							></div>
						</div>
					</div>

					<div className={styles.sidebar__wrapper_menuItems}>
						<Menu
							mode="inline"
							selectedKeys={[selectedSidebar]}
							defaultSelectedKeys={[selectedSidebar]}
							items={menuItems.map((item) => ({
								...item,
								label: (
									<div className={styles.menu_item}>
										<div
											className={`${styles.stepIcon} Icon`}
										>
											{wizardDetails[item.key] ? (
												<CheckCircleOutlined />
											) : (
												<div className="uncheckIcon" />
											)}
										</div>
										<p>{item.label}</p>
									</div>
								),
								disabled: !hasPermission(item.key),
								className: `${
									wizardDetails[item.key]
										? styles.completed
										: styles.incomplete
								} ${
									!hasPermission(item.key)
										? styles.disabled
										: ''
								}`,
							}))}
							onClick={handleSidebar}
							className="payrollSetting-menu-item"
						/>
					</div>
				</div>
			)}
		</Sider>
	);
};

export default PayrollSettingsSidebar;
