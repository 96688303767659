import { Button, Flex } from 'antd';

interface ButtonInterface {
	text: string;
	isLoading: boolean;
	className: string;
	isSubmit: boolean;
	onclick: () => void;
	fontSize?: string;
	minWidth?: string;
	minHeight?: string;
	icon?: any;
	styles?: any;
	size?: any;
	disabled?: boolean;
}

interface Prop {
	buttons: ButtonInterface[];
	gap?: 'large' | 'middle' | 'small';
}

const Buttons = (props: Prop) => {
	const { buttons, gap } = props;
	return (
		<div>
			<Flex gap={gap ? gap : 'middle'} align="center" wrap="wrap">
				{buttons.map((button: ButtonInterface) => (
					<Button
						key={Math.random()}
						className={button.className}
						loading={button.isLoading}
						size={button.size}
						style={{
							fontSize: button.fontSize ?? '1.7rem',
							minWidth: button.minWidth ?? '15rem',
							minHeight: button.minHeight ?? '4rem',
							...button.styles,
						}}
						icon={button.icon}
						htmlType={button.isSubmit ? 'submit' : 'button'}
						onMouseDown={button.onclick}
						disabled={button.disabled}
					>
						{button.text}
					</Button>
				))}
			</Flex>
		</div>
	);
};

export default Buttons;
