import { Button, Modal, Spin, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import './index.scss';
import styles from './index.module.scss';
import ViewCompanyDetails from './ViewCompanyDetails';
import ViewBankDetails from './ViewBankDetails';
import ViewCurrencyDetails from './ViewCurrencyDetails';

type Props = {
	isOpen: boolean;
	onCancel: () => void;
	company: any;
};

const ViewCompanyModal = (props: Props) => {
	const { isOpen, onCancel, company } = props;
	const [activeKey, setActiveKey] = React.useState('companyDetails');
	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	let tabItems = [
		{
			key: 'companyDetails',
			label: 'Company details',
			children: (
				<ViewCompanyDetails
					changeTab={changeTab}
					onCancel={onCancel}
					companyData={company}
				/>
			),
		},
		{
			key: 'bankDetails',
			label: 'Bank details',
			children: (
				<ViewBankDetails
					changeTab={changeTab}
					onCancel={onCancel}
					companyData={company}
				/>
			),
		},
		{
			key: 'currency',
			label: 'Currency Setup',
			children: (
				<ViewCurrencyDetails
					changeTab={changeTab}
					onCancel={onCancel}
					companyData={company}
				/>
			),
		},
	];

	return (
		<Modal
			open={isOpen}
			onCancel={onCancel}
			footer={null}
			width={1000}
			className="edit-company"
			closable={false}
		>
			<div className={styles['modal-header']}>
				<div className={styles['title']}>
				</div>
				<Button
					className={styles['close-button']}
					icon={<CloseOutlined />}
					onClick={onCancel}
				/>
			</div>
			<div className={styles['company-component']}>
				<div className="company-component-tabs">
					<Tabs
						defaultActiveKey={activeKey}
						activeKey={activeKey}
						items={tabItems}
						onChange={(key) => changeTab(key)}
						style={{
							fontSize: '18px',
							marginBottom: '40px',
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ViewCompanyModal;
