import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type headerProps = {
	sidebarDrawerOpen: () => void;
	CompensationExists: boolean;
	isLoading: boolean;
};

const WorkerCompensation = ({
	sidebarDrawerOpen,
	CompensationExists,
	isLoading,
}: headerProps) => {
	//const permissionContext = useContext(PermissionContext);
	// const isAdd = permissionContext.allowedPermissions.includes(
	// 	'Add_Statutory_Components'
	// );
	const buttonTitle = CompensationExists ? 'Edit Compensation' : 'Add Compensation';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['compensation-header']}>
			<p>Worker's Compensation</p>
			{/* {isAdd && ( */}
				<div>
					<Buttons buttons={buttons} />
				</div>
			{/* )} */}
		</div>
	);
};

export default WorkerCompensation;
