import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	ExclamationCircleOutlined,
	InboxOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Col, Modal, Row, Select, Space, Table, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import Title from 'antd/es/typography/Title';
import Upload, { UploadChangeParam, UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { employeeApi } from 'Api/employee';
import Buttons from 'components/Global/Buttons';
import SearchComponent from 'components/Global/SearchComponent';
import { useState } from 'react';
import { CloseSvg, DownloadSvg, UploadSvg } from 'utils/svgs';
import { parseCSV, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { validateEmployee } from './employeeValidations';

const statusOptions = [
	{
		label: 'All Status',
		value: '',
	},
	{
		label: 'Active',
		value: 'ACTIVE',
	},
	{
		label: 'Suspended',
		value: 'SUSPENDED',
	},
	{
		label: 'Redundant',
		value: 'REDUNDANT',
	},
	{
		label: 'Retired',
		value: 'RETIRED',
	},
	{
		label: 'Reassigned',
		value: 'REASSIGNED',
	},
	{
		label: 'Dismissed',
		value: 'DISMISSED',
	},
	{
		label: 'Deceased',
		value: 'DECEASED',
	},
];

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	// supervisorFilterValue: string;
	// handleSupervisorFilter: (value: string) => void;
	// options: any;
	handleSearchApi: (value: string) => void;
	fetchRecords: any;
};

const EmployeeHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		statusFilterValue,
		handleStatusFilter,
		fetchRecords,
		handleSearchApi,
	} = props;
	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isUploadLoading, setIsUploadLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDataModalOpen, setIsDataModalOpen] = useState(false);
	const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

	const [isSubmitClick, setIsSubmitClick] = useState(false);
	const [correctData, setCorrectData] = useState<any[]>([]);
	const [incorrectData, setIncorrectData] = useState<any[]>([]);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [validationResults, setValidationResults] = useState([]);
	console.log('validationResults: ', validationResults);

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				closeModal();
			},
			minWidth: '10rem',
		},
		{
			text: 'Upload',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				handleSave();
			},
			minWidth: '10rem',
		},
	];

	const columns = [
		...Object.keys(validationResults[0] || {})
			.filter((key) => !key.startsWith('_'))
			.map((key) => ({
				title: key,
				dataIndex: key,
				key: key,
			})),
		{
			title: 'Validation Status',
			key: '_validationStatus',
			render: (_: any, record: any) =>
				record._isValid ? (
					<div className="text-green-500">
						<CheckCircleOutlined /> Valid
					</div>
				) : (
					<div className="text-red-500">
						<CloseCircleOutlined /> Invalid
					</div>
				),
		},
		{
			title: 'Validation Errors',
			key: '_errors',
			render: (_: any, record: any) =>
				record._validationErrors ? (
					<ul className="text-red-500">
						{Object.entries(record._validationErrors).map(
							([field, error]: any) => (
								<li key={field}>
									{field}: {error}
								</li>
							)
						)}
					</ul>
				) : null,
		},
	];

	const mybuttons = [
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			minWidth: '10rem',
		},
	];

	const dataButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				closeModal();
			},
			minWidth: '10rem',
		},
		{
			text: 'Proceed ',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				setIsDataModalOpen(false);
				setIsAlertModalOpen(true);
			},
			minWidth: '10rem',
		},
	];

	const handleSave = async () => {
		if (!file) {
			toastText('Please select csv file', 'error');
			return;
		}
		setIsDataModalOpen(true);
		setIsAlertModalOpen(false);
		setIsModalOpen(false);
	};

	const downloadCsv = async () => {
		try {
			const response = await employeeApi.downloadEmployeeCsvTemplate();
			console.log('response: ', response);
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'text/csv',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `employees.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toastText(
				'Something went wrong in downloading csv template',
				'error'
			);
		}
	};

	const uploadCsv = (file: any) => {
		console.log('file: ', file);
		const reader = new FileReader();
		console.log('Upload');
		reader.onload = (e: any) => {
			console.log('e: ', e);
			try {
				const csvText = e.target.result;
				const parsedData = parseCSV(csvText);
				console.log('parsedData: ', parsedData);
				const validatedData = parsedData.map(validateEmployee);
				console.log('validatedData: ', validatedData);

				setValidationResults(validatedData);
				setIsModalVisible(true);
			} catch (error) {
				toastText('Something went wrong in uploading csv.', 'error');
				console.error(error);
			}
		};

		reader.onerror = () => {
			toastText('Something went wrong in uploading csv.', 'error');
		};

		reader.readAsText(file);
		return false; // Prevent default upload
	};

	// const uploadCsv = async (data: any) => {
	// 	try {
	// 		const reader = new FileReader();
	// 		reader.onload = async (e: any) => {
	// 			const csvData = e.target.result;

	// 			const headers = csvData.split('\n')[0].split(',');

	// 			const jsonData = await csvToJson({
	// 				headers, // Don't use first row as headers
	// 				includeColumns: /./, // Include all columns
	// 			}).fromString(csvData);

	// 			const _jsonData: any[] = [];

	// 			jsonData.forEach((data) => {
	// 				const obj: any = {};
	// 				Object.keys(data).forEach((key) => {
	// 					obj[key.trim()] = data[key];
	// 				});
	// 				_jsonData.push(obj);
	// 			});
	// 			try {
	// 				setIsUploadLoading(true);
	// 				await employeeApi.uploadSalaryCsv({
	// 					salaries: _jsonData,
	// 				});

	// 				fetchRecords();
	// 				toastText('Csv uploaded successfully.', 'success');
	// 				closeModal();
	// 			} catch (err: any) {
	// 				let message =
	// 					'Something went wrong in fetching employee details';

	// 				if (err.response.data.message) {
	// 					message = err.response.data.message;
	// 				}
	// 				toastText(message, 'error');
	// 			} finally {
	// 				setIsUploadLoading(false);
	// 			}
	// 		};
	// 		reader.readAsText(data.Attachment);
	// 	} catch (err) {
	// 		toastText('Something went wrong in uploading csv.', 'error');
	// 	}
	// };

	const closeModal = () => {
		setFile(null);
		setFileList([]);
		isRemoving = false;
		setIsModalOpen(false);
		setIsDataModalOpen(false);
		setIsSubmitClick(false);
		setIsAlertModalOpen(false);
	};

	let isRemoving = false;

	const propsUpload = {
		name: 'file',
		accept: '.csv',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}
			const isValidType = ['text/csv', 'application/csv'].includes(
				file.type!
			);

			if (!isValidType) {
				toastText(
					'Invalid file type! Only Excel files CSV files (.csv) are allowed.',
					'error'
				);
				return Upload.LIST_IGNORE;
			}

			const isLt1M = file.size! / 1024 / 1024 < 5;
			if (!isLt1M) {
				toastText('File must be smaller than 5MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);

			return false; // Prevents automatic upload
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};

	const handleSubmit = async () => {
		setIsSubmitClick(true);
		const data = {
			Attachment: file,
		};
		setIsLoading(true);
		try {
			uploadCsv(data);
		} catch (error) {
			toastText('Something went wrong in uploading csv.', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className={styles['employee-header']}>
				<Space>
					<SearchComponent
						className={styles['employee-header-item']}
						placeHolder="Search employee..."
						prefixIcon={<SearchOutlined />}
						handleChange={handleSearch}
						handleChangeApi={handleSearchApi}
						value={searchValue}
						size="large"
					/>
				</Space>
				<Space>
					<Select
						// showSearch
						// optionFilterProp="label"
						className={styles['employee-header-item']}
						value={statusFilterValue}
						options={statusOptions}
						onChange={(value) => {
							handleStatusFilter(value);
						}}
						size="large"
						placeholder="Select Status"
					/>
				</Space>

				{/* <Select
					className={styles['employee-header-item']}
					value={supervisorFilterValue}
					options={options}
					onChange={(value) => {
						handleSupervisorFilter(value);
					}}
					size="large"
					placeholder="Select Supervisor"
				/>
				{/* <p className={styles['csv-button']} onClick={downloadCsv}>
					<DownloadSvg />
				</p>

				<p
					className={styles['csv-button']}
					onClick={() => setIsModalOpen(true)}
				>
					<UploadSvg />
				</p> */}
			</div>

			<Modal
				open={isModalOpen}
				onCancel={closeModal}
				maskClosable={true}
				closable={false}
				footer={null}
				width="500px"
				className="uploadCsvModal"
				centered
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Upload</Title>
						<div className={styles['right-align-items']}>
							<div
								className={styles['close-icon']}
								onClick={closeModal}
							>
								<CloseOutlined />
							</div>
						</div>
					</div>
					<Row className={styles['form-container-card']} gutter={25}>
						<Col span={24} className={styles['col']}>
							<div className={styles['document-upload']}>
								<label
									className={styles['document-form-label']}
								>
									Upload the Document{' '}
									<span className={styles['required']}>
										*{' '}
									</span>
								</label>
								<Dragger {...propsUpload} className="test">
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-hint">
										Drag and drop a file or choose file from
										Device
										<br />
										PDF, JPG, JPEG, PNG |{' '}
										<span className="color-purple">
											Maximum file size : 5MB
										</span>
									</p>
								</Dragger>
								{!file && isSubmitClick ? (
									<p
										style={{
											color: 'red',
											fontSize: '12px',
										}}
									>
										Select document
									</p>
								) : (
									''
								)}
							</div>
						</Col>
					</Row>
					<Row className={styles['modal-buttons']}>
						<Buttons buttons={buttons} />
					</Row>
				</div>
			</Modal>

			<Modal
				open={isDataModalOpen}
				onCancel={() => setIsDataModalOpen(false)}
				footer={null}
				width="800px"
			>
				<div className="header-employee-container">
					<Tabs defaultActiveKey="1">
						<TabPane tab="Correct Data" key="1"></TabPane>
						<TabPane tab="Incorrect Data" key="2"></TabPane>
					</Tabs>
				</div>
				<Row gutter={24}>
					<Col span={12}>
						<Buttons buttons={dataButtons} />
					</Col>
				</Row>
			</Modal>

			<Modal
				title={
					<div className={styles['confirm-delete-model']}>
						<ExclamationCircleOutlined
							style={{
								fontSize: '30px',
								color: '#faad14',
							}}
						/>
						<h6 className={styles['confirm-delete-model__title']}>
							<span
								className={
									styles[
										'confirm-delete-model__title--delete'
									]
								}
							>
								{
									'Only correctly formatted data will be uploaded. Incorrect data will be discarded. Kindly review the data carefully before proceeding.'
								}
							</span>
						</h6>
					</div>
				}
				open={isAlertModalOpen}
				onOk={handleSubmit}
				onCancel={closeModal}
				centered={true}
				width={400}
				closeIcon={<CloseSvg />}
				footer={[
					<div
						key={'wrapper'}
						className={styles['confirm-delete-model__button']}
					>
						<Buttons buttons={mybuttons}></Buttons>
					</div>,
				]}
			></Modal>

			<Modal
				title="CSV Validation Results"
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				width="90%"
				footer={null}
			>
				<Table
					columns={columns}
					dataSource={validationResults}
					scroll={{ x: 'max-content' }}
				/>
			</Modal>
		</>
	);
};

export default EmployeeHeader;
