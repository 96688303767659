import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import { getApproveRequestApi } from 'Api/approveRequest';
import { timeSheetsApi } from 'Api/timeSheet';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedTimeSheet: string | null;
	selectedTimeSheetLabel: string;
	approvalId: string;
	fetchApprovalRequest: any;
	isDelete: boolean;
	notificationId: string | null;
};

const NotificationTimeSheetModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		selectedTimeSheet,
		selectedTimeSheetLabel,
		approvalId,
		fetchApprovalRequest,
		isDelete,
		notificationId,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const [approveIsLoading, setApproveIsLoading] = useState(false);
	const [rejectIsLoading, setRejectIsLoading] = useState(false);

	const [employeeHours, setEmployeeHours] = useState<any>([]);

	const fetchTimeSheetLogs = async (id: string) => {
		try {
			setIsLoading(true);
			const timeLogs = await timeSheetsApi.getTimeSheetsLogs(id);
			setEmployeeHours(timeLogs.data.data);
		} catch (err) {
			toastText(
				'Something went wrong in getting time sheet logs',
				'error'
			);
		} finally {
			setIsLoading(false);
		}
	};

	const approveTimeSheet = async () => {
		if (isDelete) {
			return;
		}
		try {
			setApproveIsLoading(true);
			const timeLogs =
				await getApproveRequestApi.acceptTimeSheetApprovalRequest({
					timeSheetId: selectedTimeSheet,
					approvalId,
					userNotificationId: notificationId,
				});
			setEmployeeHours(timeLogs.data.data);
			toastText('Time sheet approved successfully.', 'success');
			handleCancel();
		} catch (err) {
			toastText(
				'Something went wrong in approving time sheet logs',
				'error'
			);
		} finally {
			setApproveIsLoading(false);
		}
	};

	const rejectTimeSheet = async () => {
		if (isDelete) {
			return;
		}
		try {
			setRejectIsLoading(true);
			const timeLogs =
				await getApproveRequestApi.rejectTimeSheetApprovalRequests({
					timeSheetId: selectedTimeSheet,
					approvalId,
					userNotificationId: notificationId,
				});
			setEmployeeHours(timeLogs.data.data);
			toastText('Time sheet rejected successfully.', 'success');
			handleCancel();
		} catch (err) {
			toastText(
				'Something went wrong in getting time sheet logs',
				'error'
			);
		} finally {
			setRejectIsLoading(false);
		}
	};

	useEffect(() => {
		if (selectedTimeSheet) {
			fetchTimeSheetLogs(selectedTimeSheet);
		}
	}, [selectedTimeSheet]);

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			isSubmit: true,
			className: 'secondary-button',
			onclick: () => {
				handleCancel();
			},
			disabled: false,
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
		{
			text: 'Approve',
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				approveTimeSheet();
			},
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			size: 'small',
			isLoading: approveIsLoading,
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
		{
			text: 'Reject',
			isSubmit: true,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			onclick: () => {
				rejectTimeSheet();
			},
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			isLoading: rejectIsLoading,
		},
	];

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{
				minWidth: '1200px',
				zIndex: '99999',
			}}
			onCancel={handleCancel}
			width={600}
			footer={() => {
				return (
					<div style={{ marginTop: '2rem' }}>
						<Buttons buttons={buttons} />
					</div>
				);
			}}
		>
			<div>
				<div>
					<Title level={4}>
						<span>{selectedTimeSheetLabel}</span>
					</Title>
				</div>
				<div>
					<Table
						dataSource={employeeHours}
						scroll={{ y: 'calc(100vh - 400px)' }}
						pagination={false}
						rowKey={(record) => record.employeeId}
						loading={isLoading}
						rowClassName={(record: any) => {
							return record.isTotal ? 'total-row' : '';
						}}
					>
						<Column
							title="Date"
							dataIndex="date"
							key="date"
							className="bg-white"
							width="15%"
							render={(record) =>
								dayjs(record).format('DD/MM/YYYY')
							}
						/>
						<Column
							title="Employee name & code"
							dataIndex="employeeName"
							key="employeeName"
							className="bg-white"
							width="25%"
						/>
						<Column
							title="Regular hours"
							dataIndex="regularHours"
							key="regularHours"
							className="bg-white"
							width="15%"
						/>
						<Column
							title="Overtime hours"
							dataIndex="overtimeHours"
							key="overtimeHours"
							className="bg-white"
							width="15%"
						/>
						<Column
							title="Time shift difference"
							dataIndex="timeShiftHours"
							key="timeShiftHours"
							className="bg-white"
							width="15%"
						/>
						<Column
							title="Total hours"
							dataIndex="totalHours"
							key="totalHours"
							className="bg-white"
							width="15%"
							render={(record: any, data: any) => {
								const totalMinutes =
									timeStringToMinutes(data.regularHours) +
									timeStringToMinutes(data.overtimeHours) +
									timeStringToMinutes(data.timeShiftHours);
								return minutesToTimeString(totalMinutes);
							}}
						/>
					</Table>
				</div>
			</div>
		</Modal>
	);
};

export default NotificationTimeSheetModal;
