import { Col, Row } from 'antd';
import { NetPayImage } from 'utils/svgs';
import GlobalHeader from '../GlobalHeader';
import NetPayTable from './Table';
import styles from './index.module.scss';

type NetPayProps = {
	selectedPayrollId: string;
	headerData: any;
	setTotalNetPay: any;
	setIsUniversalLoading: any;
};

const NetPayComponent = (props: NetPayProps) => {
	const {
		selectedPayrollId,
		headerData,
		setTotalNetPay,
		setIsUniversalLoading,
	} = props;
	return (
		<Row>
			<GlobalHeader headerData={headerData} toggleRequired={true} />
			<Col span={14}>
				<div className={styles['work-hours__table']}>
					<NetPayTable
						selectedPayrollId={selectedPayrollId}
						setTotalNetPay={setTotalNetPay}
						setIsUniversalLoading={setIsUniversalLoading}
					/>
				</div>
			</Col>
			<Col span={1}></Col>
			<Col span={9}>
				<div>
					<NetPayImage />
				</div>
			</Col>
		</Row>
	);
};

export default NetPayComponent;
