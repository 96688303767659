import { Col, Row } from 'antd';
import { employeeApi } from 'Api/employee';
import { Loader } from 'components/Global';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

const EarningsModal: React.FC<any> = ({
	activeTab,
	selectedPayPeriod,
	onDataChange,
}) => {
	const [data, setData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchParams] = useSearchParams();

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	const fetchEmployeeEarnings = async () => {
		setIsLoading(true);
		try {
			const apiData = await employeeApi.getEmployeeEarnings({
				payPeriodId: selectedPayPeriod,
				employeeId: searchParams.get('employeeId'),
			});
			if (apiData?.data?.data?.length > 0) {
				const filteredData = apiData.data.data.filter(
					(item: any) => item.isChecked
				);
				setData(filteredData);
				onDataChange(filteredData);
			}
		} catch (error: any) {
			console.error('Error fetching earnings:', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (activeTab === 'earnings' && selectedPayPeriod) {
			fetchEmployeeEarnings();
		}
	}, [activeTab, selectedPayPeriod]);

	return (
		<>
			{isLoading ? (
				<div className={styles.loaderWrapper}>
					<Loader />
				</div>
			) : (
				<div className={styles.earningsContainer}>
					<Row gutter={16}>
						{data.map((item: any) => (
							<Col
								key={item.id}
								span={12}
								className={styles.earningsItem}
							>
								<div className={styles.earningsItem}>
									<label className={styles.label}>
										{item?.description?.toUpperCase()}
									</label>{' '}
									:{' '}
									<p className={styles.amount}>
										{item.amount}{' '}
										{profileData?.currencySymbol || 'ZMW'}
									</p>
								</div>
							</Col>
						))}
					</Row>
				</div>
			)}
		</>
	);
};

export default EarningsModal;
