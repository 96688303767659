export const currencyData: { [key: string]: string } = {
	USD: 'United States Dollar',
	ZMW: 'Zambian Kwacha',
};

export const currencyDataForCompanySetup: { [key: string]: string } = {
	USD: 'United States Dollar',
	//ZMW: 'Zambian Kwacha',
};

// export const currencySymbols: { [key: string]: string } = {
// 	USD: '$',
// 	EUR: '€',
// 	INR: '₹',
// 	ZMW: 'ZK',
// };
export const currencySymbols: { [key: string]: string } = {
	USD: '$', // United States Dollar
	ZMW: 'ZK', // Zambian Kwacha
};

export const countryPhoneCodes: { [key: string]: string } = {
	US: '+1',
	AF: '+93',
	UK: '+44',
	IN: '+91',
	ZM: '+260',
	AX: '+358',
	AL: '+355',
	DZ: '+213',
	CA: '+1', // Canada
	AU: '+61', // Australia
	CN: '+86', // China
	DE: '+49', // Germany
	FR: '+33', // France
	IT: '+39', // Italy
	JP: '+81', // Japan
	MX: '+52', // Mexico
	BR: '+55', // Brazil
	RU: '+7', // Russia
	SA: '+966', // Saudi Arabia
	ZA: '+27', // South Africa
	KR: '+82', // South Korea
	NG: '+234', // Nigeria
	AE: '+971', // United Arab Emirates
	GB: '+44', // United Kingdom (alternate code for UK)
	ES: '+34', // Spain
	NL: '+31', // Netherlands
	SE: '+46', // Sweden
	CH: '+41', // Switzerland
	NZ: '+64', // New Zealand
	SG: '+65', // Singapore
	MY: '+60', // Malaysia
	TH: '+66', // Thailand
	ID: '+62', // Indonesia
	PH: '+63', // Philippines
	PK: '+92', // Pakistan
	BD: '+880', // Bangladesh
	VN: '+84', // Vietnam
	EG: '+20', // Egypt
	KE: '+254', // Kenya
	AR: '+54', // Argentina
	CL: '+56', // Chile
	CO: '+57', // Colombia
	PE: '+51', // Peru
	VE: '+58', // Venezuela
	TR: '+90', // Turkey
	IR: '+98', // Iran
	IQ: '+964', // Iraq
	IL: '+972', // Israel
	NO: '+47', // Norway
	FI: '+358', // Finland
	DK: '+45', // Denmark
	AT: '+43', // Austria
	BE: '+32', // Belgium
	PL: '+48', // Poland
	CZ: '+420', // Czech Republic
	GR: '+30', // Greece
	PT: '+351', // Portugal
};