import GlobalHeader from '../GlobalHeader';
import TotalEarningTable from './Table';
import styles from './index.module.scss';

type TotalEarningProps = {
	selectedPayrollId: string;
	setEarningJSON: any;
	onlyView: boolean;
	headerData: any;
	setIsUniversalLoading: any;
};
const TotalEarningComponent = (props: TotalEarningProps) => {
	const {
		selectedPayrollId,
		setEarningJSON,
		onlyView,
		headerData,
		setIsUniversalLoading,
	} = props;

	return (
		<div className={styles['total-earning__table']}>
			<GlobalHeader headerData={headerData} toggleRequired={true} />
			<TotalEarningTable
				selectedPayrollId={selectedPayrollId}
				setEarningJSON={setEarningJSON}
				onlyView={onlyView}
				setIsUniversalLoading={setIsUniversalLoading}
			/>
		</div>
	);
};

export default TotalEarningComponent;
