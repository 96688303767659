import { Collapse } from 'antd';
import { CollapseClose, CollapseOpen } from 'utils/svgs';
import styles from './index.module.scss';
import './index.scss';
import { boolean } from 'mathjs';

const { Panel } = Collapse;

type CustomCollapsePanelProps = {
	header: string;
	icon: JSX.Element;
	children: JSX.Element;
	activeKey?: string | null;
	setActiveKey?: (key: string | null) => void;
	panelKey: string;
};

const CustomCollapsePanel = ({
	header,
	icon,
	children,
	activeKey,
	setActiveKey,
	panelKey,
	isOpen,
}: CustomCollapsePanelProps & { isOpen: boolean }) => {
	const onHandleChange = (key: string | string[]) => {
		if (setActiveKey) {
			setActiveKey(activeKey === panelKey ? null : panelKey);
		}
	};

	return (
		<Collapse
			className={'items'}
			bordered={false}
			activeKey={activeKey ? [activeKey] : []}
			onChange={onHandleChange}
			expandIcon={() => icon}
		>
			<Panel
				className={styles.collapse}
				header={<span className={styles.customHeader}>{header}</span>}
				key={panelKey}
				extra={isOpen ? <CollapseOpen /> : <CollapseClose />}
			>
				{children}
			</Panel>
		</Collapse>
	);
};

export default CustomCollapsePanel;
