import { Button, Switch } from 'antd';
import styles from './index.module.scss';
import { DownloadOutlined } from '@ant-design/icons';
import Ellipse from 'components/Global/Ellipse';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { toggleUsdCurrency } from '../../../../redux/slices/usdCurrencyToggleSlice';
import { toastText } from 'utils/utils';
import { getApiExcel } from 'apis';
import { useState } from 'react';

type headerProps = {
	headerData: any;
	toggleRequired?: boolean;
	Payslip?: boolean;
	//onDownloadPayslips?: () => void;
	selectedPaySlips?: any;
	selectedPayrollId?: string;
};

const Header = ({
	headerData,
	Payslip,
	toggleRequired,
	selectedPaySlips,
	selectedPayrollId,
}: headerProps) => {
	const { isUsdCurrency, usdCurrencyToggle } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const dispatch = useDispatch<AppDispatch>();
	const [isDownLoading, setIsDownLoading] = useState(false);
	const handleCurrencyChange = () => {
		dispatch(toggleUsdCurrency());
	};

	const downloadSelectedPayslips = async () => {
		if (!selectedPayrollId || selectedPaySlips.length === 0) {
			toastText(
				'Payroll ID and selected payslip IDs are required',
				'warning'
			);
			return;
		}
		try {
			setIsDownLoading(true);

			for (let i = 0; i < selectedPaySlips.length; i++) {
				const payload = {
					payrollId: selectedPayrollId,
					payslipId: selectedPaySlips[i],
				};

				const response = await getApiExcel(
					'/payroll/download/multiselected-payslip',
					payload
				);

				if (response && response.data) {
					const pdfBlob = new Blob([response.data], {
						type: 'application/pdf',
					});

					const pdfUrl = URL.createObjectURL(pdfBlob);
					const link = document.createElement('a');
					link.href = pdfUrl;
					link.setAttribute('download', 'payslips.pdf');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}

			toastText('Payslips downloaded successfully!', 'success');
			//		setSelectedPayslipIds([]);
		} catch (error: any) {
			const message =
				error.response?.data?.message || 'Failed to download payslips.';
			toastText(message, 'error');
		} finally {
			setIsDownLoading(false);
		}
	};

	return (
		<>
			<div className={styles['global-header']}>
				<div className={styles['inline-container']}>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Pay Period:</p>
						<p className={styles['label-value']}>
							{' '}
							<Ellipse
								maxLength={20}
								message={headerData?.payPeriodName}
								isTooltip={true}
								key={headerData?.payPeriodName}
								tooltipMessage={headerData?.payPeriodName}
							/>
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Pay Group:</p>
						<p className={styles['label-value']}>
							{' '}
							<Ellipse
								maxLength={20}
								message={headerData?.payGroupNames}
								isTooltip={true}
								key={headerData?.payGroupNames}
								tooltipMessage={headerData?.payGroupNames}
							/>
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Currency:</p>
						<p className={styles['label-value']}>
							{' '}
							<Ellipse
								maxLength={20}
								message={headerData?.currency}
								isTooltip={true}
								key={headerData?.currency}
								tooltipMessage={headerData?.currency}
							/>
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Department:</p>
						<p className={styles['label-value']}>
							{' '}
							<Ellipse
								maxLength={20}
								message={headerData?.payGroupDepartment}
								isTooltip={true}
								key={headerData?.payGroupDepartment}
								tooltipMessage={headerData?.payGroupDepartment}
							/>
						</p>
					</div>
					<div className={styles['inline-label']}>
						<p className={styles['label']}>Branch:</p>
						<p className={styles['label-value']}>
							{' '}
							<Ellipse
								maxLength={20}
								message={headerData?.payGroupBranch}
								isTooltip={true}
								key={headerData?.payGroupBranch}
								tooltipMessage={headerData?.payGroupBranch}
							/>
						</p>
					</div>
					{isUsdCurrency && (
						<>
							<div className={styles['inline-label']}>
								<p className={styles['label']}>
									Conversion Rate:
								</p>
								<p className={styles['label-value']}>
									{' '}
									{headerData?.conversionRate}
								</p>
							</div>
						</>
					)}
				</div>

				{isUsdCurrency && toggleRequired && (
					<div className={styles.usdToggleContainer}>
						<p>{`Currency in ${
							!usdCurrencyToggle ? 'USD' : 'Kwacha'
						}`}</p>
						<Switch
							value={usdCurrencyToggle}
							onChange={handleCurrencyChange}
						/>
					</div>
				)}
				{Payslip && (
					<div className={styles['download-button-container']}>
						<Button
							type="primary"
							className={styles['download-slip-button']}
							onClick={downloadSelectedPayslips}
							loading={isDownLoading}
						>
							<DownloadOutlined /> Download Pay Slip
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

export default Header;
