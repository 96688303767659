import { PermissionContext } from 'components/Global/AuthLayout';
import P18ReportComponent from 'components/Report/P18Report';
import { useContext } from 'react';
const P18Report = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return <>{check && <P18ReportComponent />}</>;
};

export default P18Report;
