import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import Upload, { UploadChangeParam, UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { holidayApi } from 'Api/masters/holiday';
import Buttons from 'components/Global/Buttons';
import csvToJson from 'csvtojson';
import React, { useState } from 'react';
import { DownloadSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { AddHolidaysProps } from './types';

const AddHolidayModal: React.FC<AddHolidaysProps> = (props) => {
	const {
		handleCancel,
		isAddHolidayModalOpen,
		fetchHolidays,
		showAddHolidayManuallyModal,
	} = props;

	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isUploadLoading, setIsUploadLoading] = useState(false);

	const uploadCsv = async (data: any) => {
		try {
			const reader = new FileReader();
			reader.onload = async (e: any) => {
				const csvData = e.target.result;

				const headers = csvData.split('\n')[0].split(',');

				const jsonData = await csvToJson({
					headers, // Don't use first row as headers
					includeColumns: /./, // Include all columns
				}).fromString(csvData);

				const _jsonData: any[] = [];

				jsonData.forEach((data) => {
					const obj: any = {};
					Object.keys(data).forEach((key) => {
						obj[key.trim()] = data[key];
					});
					_jsonData.push(obj);
				});
				try {
					setIsUploadLoading(true);
					await holidayApi.uploadCsv({
						holidays: _jsonData,
					});

					fetchHolidays();
					toastText('Csv uploaded successfully.', 'success');
					closeModal();
				} catch (err: any) {
					let message =
						'Something went wrong in fetching employee details';

					if (err.response.data.message) {
						message = err.response.data.message;
					}
					toastText(message, 'error');
				} finally {
					setIsUploadLoading(false);
				}
			};
			reader.readAsText(data.Attachment);
		} catch (err) {
			toastText('Something went wrong in uploading csv.', 'error');
		}
	};

	const closeModal = () => {
		setFile(null);
		setFileList([]);
		isRemoving = false;
		handleCancel();
	};

	const handleSubmit = async () => {
		if (!file) {
			toastText('Please select csv file', 'error');
			return;
		}
		const data = {
			Attachment: file,
		};
		setIsLoading(true);
		try {
			uploadCsv(data);
		} catch (error) {
			toastText('Something went wrong in uploading csv.', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	let isRemoving = false;

	const propsUpload = {
		name: 'file',
		accept: '.csv',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}
			const isValidType = ['text/csv', 'application/csv'].includes(
				file.type!
			);

			if (!isValidType) {
				toastText(
					'Invalid file type! Only Excel files CSV files (.csv) are allowed.',
					'error'
				);
				return Upload.LIST_IGNORE;
			}

			const isLt1M = file.size! / 1024 / 1024 < 5;
			if (!isLt1M) {
				toastText('File must be smaller than 5MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);

			return false; // Prevents automatic upload
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};

	const handleManualHoliday = () => {
		showAddHolidayManuallyModal();
		closeModal();
	};

	const myButtons = [
		{
			text: 'Upload',
			isLoading: isUploadLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			disabled: isLoading || isUploadLoading,
			onclick: () => {
				handleSubmit();
			},
		},
	];

	const addManualButton = [
		{
			text: 'Add Holiday manually',
			isLoading: false,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			disabled: isLoading || isUploadLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				handleManualHoliday();
			},
		},
	];

	const downloadCsv = async () => {
		try {
			const response = await holidayApi.getCsvTemplate();
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `holidays.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toastText(
				'Something went wrong in downloading csv template',
				'error'
			);
		}
	};

	return (
		<>
			<Modal
				open={isAddHolidayModalOpen}
				onOk={handleSubmit}
				onCancel={closeModal}
				maskClosable={true}
				okText={'Save'}
				closable={false}
				width={600}
				footer={null}
				className="addHolidayModal"
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Add Holiday</Title>
						<div className={styles['right-align-items']}>
							<div>
								<p
									className={styles['template-link']}
									onClick={downloadCsv}
								>
									<DownloadSvg />
									Download Template
								</p>
							</div>
							<div
								className={styles['close-icon']}
								onClick={closeModal}
							>
								<CloseOutlined />
							</div>
						</div>
					</div>
					<div>
						<div className={styles['modalUploadContainer']}>
							<Form.Item name="file">
								<Dragger {...propsUpload} className="test">
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-hint">
										Drag and drop a file or choose file from
										Device Supported file formats.
										<br />
										XLS, XLSX, CSV |{' '}
										<span className="color-purple">
											Maximum file size: 5MB
										</span>
									</p>
								</Dragger>
							</Form.Item>
						</div>

						<div className={styles['buttons']}>
							<Buttons buttons={myButtons} />
						</div>

						<div className={styles['centerLabel']}>
							<h3>OR</h3>
						</div>
						<div className={styles['centerButtons']}>
							<Buttons buttons={addManualButton} />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AddHolidayModal;
