// import axios from 'axios';
import { LoginLayoutBody } from 'components/Login';
import { FORMDATA } from 'constants/Data';
import { LoginLayout } from 'layouts/Login';
import { useState } from 'react';
import { postApi } from '../../apis';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	// Handle form submission
	const onSubmit = async (values: any) => {
		setIsLoading(true);
		try {
			// Send a POST request to the server to handle password reset
			const response = await postApi('/auth/forgot-password', values);
			const message = response?.data?.message;

			if (message) {
				// Show a success toast with the message
				toastText(message, 'success');
			} else {
				throw new Error('No message in response.');
			}
			// Reset loading state and navigate back to the login page
			setIsLoading(false);
			navigate('/login');
		} catch (error: any) {
			setIsLoading(false);
			toastText(error.response.data?.message, 'error');
		}
	};

	// JSX
	return (
		<LoginLayout>
			<LoginLayoutBody
				title="Forgot Password"
				formData={FORMDATA.forgotPassword}
				buttonTitle="Verify"
				description="Please Enter your Registered Email Address, we will send reset password link there."
				redirectUrl="/login"
				redirectText="Back to login"
				// action={loginAction}
				onSubmit={onSubmit}
				isLoading={isLoading}
				isRememberMe={false}
				setIsRememberMe={() => {}}
			></LoginLayoutBody>
		</LoginLayout>
	);
};

export default ForgotPassword;
