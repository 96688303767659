import { InboxOutlined } from '@ant-design/icons';
import { employeeApi } from 'Api/employee';
import { Col, Modal, Row, Upload } from 'antd';
import Buttons from 'components/Global/Buttons';
import InputField from 'components/Global/InputField';
import { useState } from 'react';
import { CloseSvg } from 'utils/svgs';
import { invalidText, toastText } from 'utils/utils';
import style from './index.module.scss';
import './index.scss';

const { Dragger } = Upload;

const AddDocumentModal: any = (props: any) => {
	const {
		handleCancel,
		isAddDocumentModalOpen,
		employeeId,
		fetchDocumentData,
	} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<any[]>([]);
	const [hasError, setHasError] = useState(false);
	const [documentName, setDocumentName] = useState('');
	const [isSubmitClick, setIsSubmitClick] = useState(false);
	let isRemoving = false;

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			disabled : isLoading,
			minWidth: '60px',
			minHeight: '42px',
			isSubmit: false,
			onclick: () => {
				handleModalCancel();
			},
		},
		{
			text: 'Upload',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			isSubmit: false,
			onclick: () => {
				handleSubmit();
			},
		},
	];

	const handleSubmit = async () => {
		setIsSubmitClick(true);
		let _documentName = documentName.trim();
		if (invalidText(documentName)) {
			setHasError(true);
			return;
		}
		_documentName = _documentName.replace(/ /g, '_');
		if (!file) {
			return;
		}
		try {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('documentName', _documentName);
			formData.append('employeeId', employeeId);
			formData.append('file', file);
			await employeeApi.uploadEmployeeDocument(formData);
			// await postApi('/employee/upload-docs', formData, true);
			fetchDocumentData();

		} catch (error: any) {
			const message = error?.response?.data?.error?.description || 'Something went wrong in upload document';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			handleModalCancel()
		}
	};

	const propsUpload = {
		name: 'file',
		accept: '.pdf,.jpg,.jpeg,.png',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: any) => {
			return false;
		},
		onChange(info: any) {
			const { file } = info;
			if (file?.size) {
				const isLt1M = file?.size / 1024 / 1024 < 5;

				if (!isLt1M) {
					toastText('Image must smaller than 5MB!', 'error');
					return;
				}
			}

			if (!isRemoving) {
				setFile(info.file);
				setFileList([info.file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};

	const handleChange = (value: string) => {
		if (value) {
			setDocumentName(value);
			setHasError(false);
		} else {
			setDocumentName('');
			setHasError(true);
		}
	};

	const handleModalCancel = () => {
		setIsSubmitClick(false);
		setDocumentName('');
		setHasError(false);
		setFile(null);
		setFileList([]);
		handleCancel();
	}

	return (
		<Modal
			open={isAddDocumentModalOpen}
			onOk={handleSubmit}
			onCancel={handleModalCancel}
			okText={'Save'}
			closable={false}
			footer={null}
			className="document__popup"
			width="650px"
		>
			<Row className={style['document-modal__header']}>
				<Col span={20}>
					<p className={style['salaryDetailsTitle']}>Add Document</p>
				</Col>
				<Col span={4} style={{ textAlign: 'right' }}>
					<div onClick={handleCancel} className="pointer">
						<CloseSvg />
					</div>
				</Col>
			</Row>
			<div className="document-modal__body">
				<Row>
					<Col span={24}>
						<InputField
							name="documentName"
							value={documentName}
							regex='^[a-zA-Z0-9 ]*$'
							label="Name of the Document"
							required={true}
							helperText='Document name can only contain letters and spaces.'
							onChange={(value) => {
								handleChange(value);
							}}
							width="100%"
							isError={hasError}
						/>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={24}>
						<>
							<label className="document-form-label">
								Upload the Document{' '}
							</label>
							<Dragger {...propsUpload} className="test">
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-hint">
									Drag and drop a file or choose file from
									Device Supported file formats.
									<br />
									PDF, JPG, JPEG, PNG |{' '}
									<span className="color-purple">
										Maximum image size : 5MB
									</span>
								</p>
							</Dragger>
							{(!file && isSubmitClick) ? (
								<p style={{ color: 'red', fontSize: '12px' }}>
									Select document
								</p>
							) : (
								''
							)}
						</>
					</Col>
				</Row>
			</div>
			<div
				className="document_modal_footer"
				style={{ textAlign: 'right' }}
			>
				<Buttons buttons={myButtons}></Buttons>
			</div>
		</Modal>
	);
};

export default AddDocumentModal;
