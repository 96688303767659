import { HistoryOutlined } from '@ant-design/icons';
import { nhimaApi } from 'Api/nhima';
import { Button, Table, Tooltip } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useState } from 'react';
import { handleDownload, invalidText } from 'utils/utils';

const { Column } = Table;

type Props = {
	nhimaLatestData: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};

const ShareComponent = (data: any) => {
	const { record } = data;

	return (
		<Tooltip
			title={
				<>
					<p>EmployeeShare: {record.employeeShare} %</p>
					<p>EmployerShare: {record.employerShare} %</p>
				</>
			}
			placement="right"
			overlayInnerStyle={{ width: 'fit-content', height: 'fit-content' }}
		>
			{record.employerShare + record.employeeShare} %
		</Tooltip>
	);
};

const NhimaLatestTable = (props: Props) => {
	const { nhimaLatestData, isLoading, showModal, totalRecords } = props;
	const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
		null
	);
	const handleRecordDownload = async (
		recordId: string,
		recordName: string
	) => {
		return await handleDownload(
			recordId,
			nhimaApi.downloadNhimaAttachment,
			setDownloadingRowId,
			recordName
		);
	};

	return (
		<div>
			<Table
				dataSource={nhimaLatestData}
				rowKey={(record) => record.id}
				loading={isLoading}
				pagination={false}
			>
				<Column
					title="Effective Date"
					dataIndex="effectiveDate"
					key="effectiveDate"
					className="bg-white"
					width={150}
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Percentage Share"
					key="percentageShare"
					className="bg-white"
					width={200}
					render={(text, record: any) => (
						<ShareComponent record={record} />
					)}
				/>
				<Column
					title="Updated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					width={150}
					render={(text) => text?.fullName}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					key="notes"
					className="bg-white"
					width={200}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title="Attachment"
					dataIndex="attachment"
					key="attachment"
					className="bg-white"
					width={'18%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<div>
									<div
										onClick={() =>
											downloadingRowId === record.id
												? () => {}
												: handleRecordDownload(
														record.id,
														record.documentName
												  )
										}
										style={{
											cursor:
												downloadingRowId === record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingRowId === record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.documentName}
											maxLength={20}
											key={record.documentName}
											tooltipMessage={record.documentName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				{totalRecords >= 1 && (
					<Column
						title="History"
						dataIndex="history"
						key="history"
						className="bg-white"
						width={100}
						render={() => (
							<Button
								type="link"
								icon={<HistoryOutlined />}
								onClick={showModal}
							/>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default NhimaLatestTable;
