import { UploadOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Paid } from 'utils/svgs';
import UploadRecord from '../uploadTransferModel';
import styles from './index.module.scss';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchAllPayrolls: any;
};

const PayrollHistoryTable = (props: TableProps) => {
	const [uploadRecordModal, setUploadRecordModal] = useState(false);
	const [payrollHistoryId, setPayrollHistoryId] = useState<any>(null);
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		fetchAllPayrolls,
	} = props;

	const openUploadRecordModal = (historyId: string) => {
		setUploadRecordModal(true);
		setPayrollHistoryId(historyId);
	};

	const closeAllModal = () => {
		setUploadRecordModal(false);
		setPayrollHistoryId(null);
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period"
					dataIndex="payPeriod"
					key="payPeriod"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Pay Group"
					dataIndex="payGroup"
					key="payGroup"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Approved By"
					dataIndex="approvedOn"
					key="approvedOn"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Paid On"
					dataIndex="paidOn"
					key="paidOn"
					sorter={false}
					className="bg-white"
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Bank File"
					dataIndex="bankFile"
					key="bankFile"
					sorter={false}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.bankFileLink ? (
								<a
									href={record.bankFileLink}
									target="_blank"
									rel="noopener noreferrer"
									style={{ cursor: 'pointer', opacity: 1 }}
								>
									<Ellipse
										message={record.bankFileName}
										maxLength={20}
										key={record.bankFileName}
										tooltipMessage={record.bankFileName}
										isTooltip={true}
										isLink={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Transfer Receipt"
					dataIndex="transferReceipt"
					key="transferReceipt"
					sorter={false}
					className="bg-white"
					render={(_: any, record: any) => (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Button
								icon={<UploadOutlined />}
								type="text"
								style={{ color: '#584495' }}
								onClick={() => openUploadRecordModal(record.id)}
							>
								Upload file
							</Button>
							{record.transferReceiptLink ? (
								<a
									href={record.transferReceiptLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.transferReceiptName}
										maxLength={20}
										key={record.transferReceiptName}
										tooltipMessage={
											record.transferReceiptName
										}
										isTooltip={true}
									/>
								</a>
							) : (
								<></>
							)}
						</div>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					className="bg-white"
					render={(status) => {
						return (
							<Tag bordered={false} color="green">
								<Paid /> {status}
							</Tag>
						);
					}}
				/>
			</Table>

			{uploadRecordModal && (
				<UploadRecord
					open={uploadRecordModal}
					closeUploadRecordModal={closeAllModal}
					payrollHistoryId={payrollHistoryId}
					fetchAllPayrolls={fetchAllPayrolls}
				/>
			)}
		</div>
	);
};

export default PayrollHistoryTable;
