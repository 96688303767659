import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
type headerProps = {
	sidebarDrawerOpen: () => void;
	NhimaExists: boolean;
};

const NhimaHeader = ({ sidebarDrawerOpen, NhimaExists }: headerProps) => {
		const permissionContext = useContext(PermissionContext);
		const isAdd = permissionContext.allowedPermissions.includes(
			'Add_Statutory_Components'
		);
	const buttonTitle = NhimaExists ? 'Edit NHIMA' : 'Add NHIMA';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: false,
		},
	];
	return (
		<div className={styles['nhima-header']}>
			<p>NHIMA</p>
			{isAdd && (
			<div>
				<Buttons buttons={buttons} />
			</div>
			)}
		</div>
	);
};

export default NhimaHeader;
