import { Col, Input, Modal, Row } from 'antd';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { AddHolidayManuallyProps } from './types';
import InputField from 'components/Global/InputField';
import { holidayApi } from 'Api/masters/holiday';
import Title from 'antd/es/typography/Title';
import { CloseOutlined } from '@ant-design/icons';

const AddHolidayManuallyModal: React.FC<AddHolidayManuallyProps> = (props) => {
	const { handleCancel, isAddHolidayManuallyModalOpen, edit, fetchHoliday } =
		props;

	const [manualHolidayDetails, setManualHolidayDetails] = useState<any>({
		holidayDate: dayjs(new Date().setDate(new Date().getDate() + 1)),
		holidayName: null,
	});
	const [formError, setFormError] = useState<any>({
		holidayDate: false,
		holidayName: false,
	});
	const [hasError, setHasError] = useState(false);

	const [addManuaHolidayLoading, setIsAddManuaHolidayLoading] =
		useState<boolean>(false);
	const myButtons = [
			{
			text: 'Cancel',
			disabled: addManuaHolidayLoading,
			className: 'secondary-button',
			isLoading: false,
			font: '16px',
			minHeight: '48px',
			minWidth: '60px',
			isSubmit: false,
			onclick: () => {
				closeModal();
			},
		},
		{
			text: "Save",
			isLoading: addManuaHolidayLoading,
			className: 'btn-blue',
			font: '16px',
			minHeight: '48px',
			minWidth: '110px',
			disabled: false,
			isSubmit: true,
			onclick: handleSubmit,
		},
	
	];

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		if (name === 'holidayDate') {
			setManualHolidayDetails((prev: any) => {
				return {
					...prev,
					holidayDate: value,
				};
			});
		}
		if (name === 'holidayName') {
			setManualHolidayDetails((prev: any) => {
				return {
					...prev,
					holidayName: value,
				};
			});
		}
		// OnChange(value, name);
	};

	const closeModal = () => {
		setManualHolidayDetails({
			holidayDate: dayjs(new Date().setDate(new Date().getDate() + 1)),
			holidayName: null,
		});
		setFormError({
			holidayDate: false,
			holidayName: false,
		});
		setHasError(false);
		handleCancel();
	};

	async function handleSubmit() {
		let checkFormError = validateFormData(
			{ ...manualHolidayDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		}
		const data = {
			holidayDate: formatToDateOnly(manualHolidayDetails.holidayDate),
			holidayName: manualHolidayDetails.holidayName,
		};

		setIsAddManuaHolidayLoading(true);

		try {
			let response;
			if (edit) {
				response = await holidayApi.updateHoliday(edit.id, data);
			} else {
				response = await holidayApi.createHolidayManually(data);
			}

			fetchHoliday();
			let message =
				response?.data?.message ||
				(edit
					? 'Holiday Updated successfully'
					: 'Holiday Added successfully');
			toastText(message, 'success');
			closeModal();
		} catch (error: any) {
			let message =
				error.response?.data?.message ||
				(edit
					? 'Something went wrong in updating Holiday'
					: 'Something went wrong in creating Holiday');
			toastText(message, 'error');
		} finally {
			setIsAddManuaHolidayLoading(false);
		}
	}

	useEffect(() => {
		if (edit) {
			setManualHolidayDetails({
				holidayDate: dayjs(edit?.holidayDate),
				holidayName: edit?.holidayName,
			});
			setFormError({
				holidayDate: false,
				holidayName: false,
			});
		}
	}, [edit]);
	return (
		<Modal
			open={isAddHolidayManuallyModalOpen}
			closable={false}
			maskClosable={true}
			onCancel={closeModal}
			width={600}
			onOk={handleSubmit}
			className="holidayManualModal"
			footer={<Buttons buttons={myButtons} />}
		>
			{' '}
			<div className={styles['modal-header']}>
				<Title level={4}>
					{edit ? 'Edit Holiday' : 'Add Holiday'}
				</Title>
				<div className={styles['right-align-items']}>
					<div className={styles['close-icon']} onClick={closeModal}>
						<CloseOutlined />
					</div>
				</div>
			</div>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<Row gutter={16}>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="holidayDate"
						value={manualHolidayDetails.holidayDate}
						label="Date "
						required={true}
						helperText="Holiday Date is required"
						isError={formError.holidayDate}
						onChange={(value) =>
							handleChange(value, 'holidayDate', true)
						}
						disabledBeforeDates={dayjs(
							new Date().setDate(new Date().getDate() + 1)
						).startOf('day')}
					/>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="holidayName"
						label="Holiday Name"
						required={true}
						helperText="Holiday Name required"
						value={manualHolidayDetails.holidayName}
						disabled={false}
						isError={formError.holidayName}
						onChange={(value) =>
							handleChange(value, 'holidayName', true)
						}
					/>
				</Col>
			</Row>
		</Modal>
	);
};

export default AddHolidayManuallyModal;
