import Buttons from 'components/Global/Buttons';
import TableActionHeader from 'components/Global/TableActionHeader';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { AddSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { SideDrawerWrapper } from 'components/Global';
import { deleteApiWithData, getApi } from 'apis';
import { toastText } from 'utils/utils';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import DepartmentTable from './Table/DepartmentTable';
import AddDepartmentBody from './AddDepartmentBody';
import DepartmentHeader from './Table/Header';
import useDebounce from 'components/Global/Hooks/UseDebounce';
const DepartmentComponent = () => {
	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [branchOptions, setBranchOptions] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [edit, setEdit] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [drawerInfo, setDrawerInfo] = useState({
		drawerTitle: 'Job Title',
	});
	const [departmentData, setDepartmentData] = useState([]);
	const debouncedSearchValue = useDebounce(searchValue, 500);
	const buttons = [
		{
			text: 'Add Department',
			isLoading: false,
			className: 'btn-blue',
			icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				openDrawerHandler();
			},
			disabled: isLoading,
		},
	];

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	// Open side drawer with animation
	const openDrawerHandler = () => {
		setDrawerInfo({ drawerTitle: 'Add Department' });
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};
	// Remove the side drawer
	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	// Close the side drawer with animation
	const closeDrawerByAnimation = () => {
		setEdit(null); //!isAddUserLoading &&
		setDrawerAnimation(false); //!isAddUserLoading &&
	};

	const fetchDepartment = async () => {
		try {
			setIsLoading(true);

			const query = {
				page: currentPage,
				search: debouncedSearchValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			const apiRes = await getApi('/department', query);

			setDepartmentData(apiRes.data.data);
			setTotalRecords(apiRes.data.total);
		} catch (err: any) {
			if (err.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching departments',
					'error'
				);
			}
		} finally {
			setIsLoading(false);
		}
	};
	// Change data and title between components
	const setDrawerInfoHandler = (drawerTitle: any) => {
		setDrawerInfo({ drawerTitle });
	};

	// Confirm operation
	const handleOk = () => {
		setIsModalOpen(false);
		setEdit(null);
	};

	// Cancel operation
	const handleCancel = () => {
		setIsModalOpen(false); // !isAddUserLoading &&
		setEdit(null);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	// Delete user
	const deleteHandler = async () => {
		if (edit) {
			try {
				setIsDeleteLoading(true);
				const response = await deleteApiWithData('/department', {
					departmentId: edit.id,
				});
				if (totalRecords > 1 && totalRecords % pageSize === 1) {
					setCurrentPage(currentPage - 1);
				}
				let message =
					response?.data?.message ||
					'Department deleted successfully';
				toastText(message, 'success');
				handleCancel();
				fetchDepartment();
			} catch (err: any) {
				if (err.response?.data?.error?.code !== 103) {
					toastText(
						'Something went wrong in delete department',
						'error'
					);
				}
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const fetchBranchCodes = async () => {
		try {
			const apiRes: any = await getApi('/branchCode');

			if (apiRes.data.data) {
				const branches = apiRes.data.data.map((branch: any) => {
					return {
						id: branch.id,
						name: branch.name,
					};
				});
				setBranchOptions(branches);
			}
		} catch (err) {
			//error
			// toastText('Something went wrong in fetching branch ', 'error');
		}
	};

	useEffect(() => {
		fetchBranchCodes();
	}, []);

	useEffect(() => {
		fetchDepartment();
	}, [pageSize, debouncedSearchValue, currentPage, sortOrder, sortField]);
	return (
		<div>
			{/* <TableActionHeader title={'Department'}>
				<div>
					<Buttons buttons={buttons} />
				</div>
			</TableActionHeader> */}
			<div className={styles['employee-container']}>
				<DepartmentHeader
					searchValue={searchValue}
					handleSearch={handleSearch}
					handleSearchApi={handleSearchApi}
					button={buttons}
				/>
				<DepartmentTable
					departmentData={departmentData}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					fetchRecords={fetchDepartment}
					setEditData={setEdit}
					openDrawerHandler={openDrawerHandler}
					setDrawerInfoHandler={setDrawerInfoHandler}
					showModal={showModal}
					isLoading={isLoading}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="right"
					width="half"
				>
					<AddDepartmentBody
						closeDrawerByAnimation={closeDrawerByAnimation}
						fetchDepartment={fetchDepartment}
						editSelected={edit}
						setEditSelectedUser={setEdit}
						branchOptions={branchOptions}
					/>
				</SideDrawerWrapper>
			)}
			<ConfirmDelete
				handleCancel={handleCancel}
				handleOk={handleOk}
				isModalOpen={isModalOpen}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading} //isLoading
				// isAddUserLoading={false} //isAddUserLoading
			/>
		</div>
	);
};

export default DepartmentComponent;
