import { getApi } from 'apis';

const getAllEmployeeWorkHours = async (id: string) => {
	return await getApi('/payroll/view/employeeWorkHours', {
		payrollId: id,
	});
};
const getAllPaySlips = async (id: string) => {
	return await getApi('/payroll/view/employee-pay-slips', {
		payrollId: id,
	});
};
const getAllEmployeeDeductions = async (id: string) => {
	return await getApi('/payroll/view/employee-deductions', {
		payrollId: id,
	});
};
const getAllEmployeeEarnings = async (id: string) => {
	return await getApi('/payroll//view/employee-earnings', {
		payrollId: id,
	});
};
const getAllEmployeeLeaves = async (id: string) => {
	return await getApi('/payroll//view/employee-leaves', {
		payrollId: id,
	});
};
export const viewPayrollApi = {
	getAllEmployeeWorkHours,
	getAllPaySlips,
	getAllEmployeeDeductions,
	getAllEmployeeEarnings,
	getAllEmployeeLeaves,
};
