import { PermissionContext } from 'components/Global/AuthLayout';
import PayeReportComponent from 'components/Report/PayeReport';
import { useContext } from 'react';

const PayeReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return <>{check && <PayeReportComponent />}</>;
};

export default PayeReport;
