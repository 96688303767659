import { employeeApi } from 'Api/employee';
import { Col, Empty, Row } from 'antd';
import { Loader } from 'components/Global';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import { DeductionsModalProps } from './type';

const DeductionsModal: React.FC<DeductionsModalProps> = ({
	onDataChange,
	year,
	month,
	employeeId,
	onLoadingChange,
	activeTab,
	payPeriodId,
	isUSD,
}) => {
	const [data, setData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	useEffect(() => {
		onDataChange(data);
	}, [data]);

	useEffect(() => {
		onLoadingChange(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (activeTab === 'deductions' && !invalidText(payPeriodId)) {
			fetchEmployeeDeductions();
		}
	}, [payPeriodId, activeTab]);

	const fetchEmployeeDeductions = async () => {
		setIsLoading(true);
		try {
			const apiData = await employeeApi.getEmployeeDeductions({
				payPeriodId: payPeriodId,
				employeeId,
			});

			if (
				apiData?.data?.message ===
				'Setup Napsa Details in statutory components for selected pay period'
			) {
				setData([]);
				onDataChange([]);
			} else if (apiData?.data?.data?.length > 0) {
				const filteredData = apiData.data.data.filter(
					(item: any) => item.isChecked
				);
				setData(filteredData);
				onDataChange(filteredData);
			}
		} catch (error: any) {
			if (
				error.response?.data?.message !==
				'Setup Napsa Details in statutory components for selected pay period'
			) {
				toastText(error.response?.data?.message, 'error');
			}
		}
		setIsLoading(false);
	};

	return (
		<>
			{isLoading ? (
				<div className={styles.loaderWrapper}>
					<Loader />
				</div>
			) : (
				<div className={styles.earningsContainer}>
					{/* If no data, show "No Data" message */}
					{data.length === 0 ? (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					) : (
						<Row gutter={16}>
							{data.map((item: any) => (
								<Col
									key={item.id}
									span={12}
									className={styles.earningsItem}
								>
									<div className={styles.earningsItem}>
										<label className={styles.label}>
											{item?.description?.toUpperCase()}
										</label>{' '}
										:{' '}
										<p className={styles.amount}>
											{item.amount}{' '}
											{profileData?.currencySymbol ||
												'ZMW'}
										</p>
									</div>
								</Col>
							))}
						</Row>
					)}
				</div>
			)}
		</>
	);
};

export default DeductionsModal;
