import { Space, Table, Tooltip } from 'antd';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { formatNumber, invalidText } from 'utils/utils';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import Ellipse from 'components/Global/Ellipse';

const { Column } = Table;

type Props = {
	deductionData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	openDrawerHandler: any;
	setDrawerInfoHandler: any;
	showModal: any;
	isLoading: boolean;
};

const DeductionTable = (props: Props) => {
	const {
		deductionData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditData,
		showModal,
		isLoading,
	} = props;

	const permissions = useContext(PermissionContext);
	// Edit user data handler
	const editDataHandler = (selectedObj: any) => {
		openDrawerHandler();
		setDrawerInfoHandler('Edit Deduction');
		setEditData(selectedObj);
	};
	const { data } = useSelector((state: any) => state?.userProfile);
	// Delete user data handler
	const deleteDataHandler = (userObject: any) => {
		setEditData(userObject);
		showModal();
	};
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={deductionData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Deduction Code"
					dataIndex="code"
					key="code"
					sorter={true}
					className="bg-white"
				/>
				{/* <Column
					title="Description"
					// dataIndex="description"
					key="description"
					sorter={true}
					className="bg-white"
					render={(text, record: any) =>
						record?.description.toUpperCase()
					}
				/> */}
				<Column
					title=" Deduction"
					dataIndex="displayName"
					key="displayName"
					className="bg-white"
					sorter={true}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title={
						<span>
							<b>Amount</b>{' '}
							<Tooltip title="The amount is in ZMW">
								<InfoCircleOutlined
									style={{ marginLeft: 4, fontSize: '14px' }}
								/>
							</Tooltip>
						</span>
					}
					dataIndex="amount"
					key="amount"
					className="bg-white"
					render={(value: string) => {
						return formatNumber(
							Number(value),
							data?.CompanyCurrencies
						);
					}}
				/>
				{(permissions.allowedPermissions.includes(
					'Edit_Deduction_Code'
				) ||
					permissions.allowedPermissions.includes(
						'Delete_Deduction_Code'
					)) && (
					<Column
						title="Action"
						key="action"
						className="bg-white"
						render={(_: any, data: any) => (
							<Space size={20}>
								{permissions.allowedPermissions.includes(
									'Edit_Deduction_Code'
								) ? (
									<div
										className="cursor-pointer  flex align-center justify-center"
										onClick={() => editDataHandler(data)}
									>
										<EditActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed  flex align-center justify-center">
										<EditActionSvg color="#928F8F" />
									</div>
								)}
								{permissions.allowedPermissions.includes(
									'Delete_Deduction_Code'
								) ? (
									<div
										className="cursor-pointer flex align-center justify-center"
										onClick={() => deleteDataHandler(data)}
									>
										<DeleteActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed flex align-center justify-center">
										<DeleteActionSvg color="#928F8F" />
									</div>
								)}
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default DeductionTable;
