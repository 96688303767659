import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type headerProps = {
	sidebarDrawerOpen: () => void;
	SkillLevyExists: boolean;
	isLoading: boolean;
};

const SkillLevyHeader = ({
	sidebarDrawerOpen,
	SkillLevyExists,
	isLoading,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	// const buttonTitle = 'Setup';
	const buttonTitle = SkillLevyExists ? 'Edit Skills Levy' : 'Add Skills Levy';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['others-header']}>
			<p>Skills Levy</p>
			{isAdd && (
				<div>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default SkillLevyHeader;
