const Gender = {
	MALE: 'MALE',
	FEMALE: 'FEMALE',
	OTHER: 'OTHER',
};

const MaritalStatus = {
	MARRIED: 'MARRIED',
	UNMARRIED: 'UNMARRIED',
	DIVORCED: 'DIVORCED',
	WIDOWED: 'WIDOWED',
};

const Country = {
	ZAMBIA: 'ZAMBIA',
	MALAWI: 'MALAWI',
	MOZAMBIQUE: 'MOZAMBIQUE',
	BOTSWANA: 'BOTSWANA',
	ZIMBABWE: 'ZIMBABWE',
	TANZANIA: 'TANZANIA',
	NAMIBIA: 'NAMIBIA',
};

export const validateEmployee = (employee: any) => {
	const errors: any = {};

	// First Name validation
	if (!employee['First Name'] || employee['First Name'].trim() === '') {
		errors['First Name'] = 'First name is required';
	}

	// Last Name validation
	if (!employee['Last Name'] || employee['Last Name'].trim() === '') {
		errors['Last Name'] = 'Last name is required';
	}

	// Date of Birth validation
	if (!isValidDate(employee.DOB)) {
		errors.DOB = 'Date of birth must be a valid date';
	}

	// Gender validation
	if (!Object.values(Gender).includes(employee.Gender)) {
		errors.Gender = 'Gender must be one of the provided options';
	}

	// Country validation
	if (!Object.values(Country).includes(employee.Country)) {
		errors.Country = 'Country must be one of the provided options';
	}

	// Marital Status validation
	if (!Object.values(MaritalStatus).includes(employee['Marital Status'])) {
		errors['Marital Status'] = 'Marital status is invalid';
	}

	// Joining Date validation
	if (!isValidDate(employee['Joining Date'])) {
		errors['Joining Date'] = 'Joining date must be a valid date';
	}

	// Staff Code validation
	if (!employee['Staff Code'] || employee['Staff Code'].trim() === '') {
		errors['Staff Code'] = 'Staff code is required';
	}

	// National Registration Number validation
	if (
		!isValidNationalRegistrationNumber(
			employee['National Registration Number']
		)
	) {
		errors['National Registration Number'] =
			'National registration number must be in the format "000000/00/0"';
	}

	// Add more specific validations for other fields like Branch, Department, etc.

	return {
		...employee,
		_validationErrors: Object.keys(errors).length > 0 ? errors : null,
		_isValid: Object.keys(errors).length === 0,
	};
};

// Utility validation functions
const isValidDate = (dateString:string) => {
	return !isNaN(Date.parse(dateString));
};

const isValidNationalRegistrationNumber = (number:string) => {
	const regex = /^\d{6}\/\d{2}\/\d$/;
	return regex.test(number);
};
