import { payrollApi } from 'Api/payroll';
import { viewPayrollApi } from 'Api/payrollView';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, toastText } from 'utils/utils';
// import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	setWorkHoursJSON: any;
	onlyView: boolean;
	setIsUniversalLoading: any;
};
const WorkingHoursTable = (props: Props) => {
	const {
		selectedPayrollId,
		setWorkHoursJSON,
		onlyView,
		setIsUniversalLoading,
	} = props;
	const [workHoursData, setWorkHoursData] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const { data } = useSelector((state: any) => state?.userProfile);

	const { conversionRate, usdCurrencyToggle, isUsdCurrency } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const fetchWorkHours = async (payrollId: string) => {
		try {
			setIsLoading(true);
			setIsUniversalLoading(true);
			const workHours = await payrollApi.getAllEmployeeWorkHours(
				payrollId
			);
			setWorkHoursData(workHours.data.data);
			setWorkHoursJSON(workHours.data.data);
		} catch (err: any) {
			let message = 'Something went wrong in fetching work hours.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			setIsUniversalLoading(false);
		}
	};

	const fetchWorkHoursForView = async (payrollId: string) => {
		try {
			setIsLoading(true);

			setIsUniversalLoading(true);
			const workHours = await viewPayrollApi.getAllEmployeeWorkHours(
				payrollId
			);
			setWorkHoursData(workHours.data.data);
			// setWorkHoursJSON(workHours.data.data);
		} catch (err: any) {
			let message = 'Something went wrong in fetching work hours.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			setIsUniversalLoading(false);
		}
	};

	useEffect(() => {
		if (onlyView) {
			fetchWorkHoursForView(selectedPayrollId);
		} else {
			fetchWorkHours(selectedPayrollId);
		}
	}, [selectedPayrollId]);

	return (
		<>
			<div>
				<Table
					dataSource={workHoursData}
					scroll={{ y: 'calc(90vh - 400px)' }}
					// pagination={{
					// 	total: totalRecords,
					// 	current: currentPage,
					// 	pageSize: pageSize,
					// 	showSizeChanger: false,
					// 	// onShowSizeChange: pageSizeHandler,
					// 	pageSizeOptions: [10, 20, 50, 100, 200],
					// }}
					pagination={false}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'20%'}
						className="bg-white"
						// sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>{' '}
								|{' '}
								<span className="color-purple">
									{record.employeeCode}
								</span>
							</>
						)}
					/>

					<Column
						title="Regular Hours"
						dataIndex="regularHours"
						key="regularHours"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Weekday OT"
						dataIndex="weekdaysOT"
						key="weekdaysOT"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Sunday OT"
						dataIndex="sundayOT"
						key="sundayOT"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Shift Difference"
						dataIndex="timeShiftHours"
						key="timeShiftHours"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Hourly rate"
						dataIndex="hourlySalary"
						key="hourlySalary"
						width={'10%'}
						className="bg-white"
						render={(value: any) => {
							if (!usdCurrencyToggle && isUsdCurrency) {
								return formatNumber(
									Number(value),
									data?.CompanyCurrencies,
									true
								);
							} else {
								return formatNumber(
									Number(value * (conversionRate ?? 1)),
									data?.CompanyCurrencies
								);
							}
						}}
					/>
					<Column
						title="Total Hours"
						key="totalHours"
						dataIndex="totalHours"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
				</Table>
			</div>
		</>
	);
};
export default WorkingHoursTable;
