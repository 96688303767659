import { EyeOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { EditActionSvg } from 'utils/svgs';
// import AddLogsModal from '../AddLogsModal';
import { companySetup } from 'Api/companySetup';
import { PermissionContext } from 'components/Global/AuthLayout';
import { currencyData } from 'constants/CurrencyData';
import { useContext, useState } from 'react';
import { toastText } from 'utils/utils';
import EditCompanyModal from '../EditCompany';
import ViewCompanyModal from '../ViewCompany';
import styles from './index.module.scss';

type Props = {
	tableData: any;
	isLoading: boolean;
	fetchCompany: () => void;
};

const CompanyTable = (props: Props) => {
	const { tableData, isLoading, fetchCompany } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isViewModalOpen, setIsViewModalOpen] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState<any>(null);
	const [edit, setEdit] = useState<any>(null);
	const [verifyStanbicDetailsLoading, setVerifyStanbicDetailsLoading] =
		useState(false);

	const verifyStanbicDetails = async (companyId: string) => {
		try {
			setVerifyStanbicDetailsLoading(true);
			const response = await companySetup.verifyStanbicDetails(companyId);
			const isSuccess = response.data.isSuccess;
			if (isSuccess) {
				toastText('Connect is Establish to stanbic bank', 'success');
			} else {
				toastText(
					'Failed to connect to Stanbic bank please contact admin',
					'error'
				);
			}
		} catch (error: any) {
			let errorMessage = 'Failed to verify Stanbic details';
			if (error.response?.data?.message) {
				errorMessage = error.response.data.message;
			}
			toastText(errorMessage, 'error');
		} finally {
			setVerifyStanbicDetailsLoading(false);
		}
	};

	const permissionContext = useContext(PermissionContext);
	const isAdmin = permissionContext.isAdmin;

	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Company_Setup');

	const isEdit =
		permissionContext.allowedPermissions.includes('Edit_Company_Setup');

	const showModal = (company: any) => {
		setSelectedCompany(company);
		setIsModalOpen(true);
	};

	const showViewModal = (company: any) => {
		// Function to open view modal
		setSelectedCompany(company);
		setIsViewModalOpen(true);
	};

	const onCancelView = () => {
		setIsViewModalOpen(false); // Close the view modal
	};

	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const renderStatutoryDetails = (record: any) => {
		const details = (
			<div>
				<p>
					<strong>NHIMA:</strong> {record.CompanyDetails?.nihma}
				</p>
				<p>
					<strong>NAPSA:</strong> {record.CompanyDetails?.napsa}
				</p>
				<p>
					<strong>TPIN:</strong> {record.tpin}
				</p>
			</div>
		);

		return (
			<Tooltip title={details}>
				<Button type="link">View Details</Button>
			</Tooltip>
		);
	};

	// const renderCurrency = (record: any) => {
	// 	const baseCurrency = record.CompanyCurrency?.find(
	// 		(currency: any) => currency.isBaseCurrency
	// 	);
	// 	return baseCurrency ? baseCurrency.currency : 'N/A';
	// };
	const renderCurrency = (record: any) => {
		const baseCurrency = record.CompanyCurrency?.find(
			(currency: any) => currency.isBaseCurrency
		);
		return baseCurrency ? currencyData[baseCurrency.currency] : 'N/A';
	};

	const onCancel = () => {
		setIsModalOpen(false);
		fetchCompany();
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				rowKey={(record: any) => record.id}
				loading={isLoading}
				pagination={false}
			>
				<Column
					title="Company Name"
					dataIndex="name"
					key="name"
					width={'22%'}
					className="bg-white"
				/>
				<Column
					title="Email Address"
					dataIndex="email"
					key="email"
					className="bg-white"
				/>
				<Column
					title="Statutory components"
					key="statutoryComponents"
					className="bg-white"
					render={(text: any, record: any) =>
						renderStatutoryDetails(record)
					}
				/>
				<Column
					title="Start of year"
					key="financialYear"
					className="bg-white"
					render={(text, record: any) =>
						monthNames[record.CompanyDetails?.financialYear - 1]
					}
				/>
				<Column
					title="Currency"
					key="currency"
					className="bg-white"
					render={(text, record: any) => renderCurrency(record)}
				/>
				<Column
					title="Action"
					dataIndex="action"
					key="action"
					className="bg-white"
					render={(record: any, data: any) => (
						<Space size={20}>
							{isAdd && isEdit && (
								<div
									className="cursor-pointer  flex align-center justify-center"
									onClick={() => {
										setEdit(data);
										showModal(data);
									}}
								>
									<EditActionSvg />
								</div>
							)}
							<div
								className="cursor-pointer flex align-center justify-center"
								onClick={() => showViewModal(data)} // Open view modal on click
							>
								<EyeOutlined style={{ fontSize: '22px' }} />
							</div>
						</Space>
					)}
				/>

				{isAdmin && (
					<Column
						title="Connect to Stanbic"
						dataIndex="action"
						align="center"
						key="action"
						className="bg-white"
						render={(record: any, data: any) =>
							verifyStanbicDetailsLoading ? (
								<LoadingOutlined spin />
							) : (
								<LinkOutlined
									onClick={() =>
										verifyStanbicDetails(data.id)
									}
									style={{
										fontSize: '24px',
										color: '#1890ff',
										cursor: 'pointer',
									}}
								/>
							)
						}
					/>
				)}
			</Table>
			{isModalOpen && (
				<EditCompanyModal
					isOpen={isModalOpen}
					onCancel={onCancel}
					edit={edit}
				/>
			)}

			{isViewModalOpen && (
				<ViewCompanyModal
					isOpen={isViewModalOpen}
					onCancel={onCancelView}
					company={selectedCompany} // Pass the selected company to the view modal
				/>
			)}
		</div>
	);
};

export default CompanyTable;
