import { getApi, postApi, putApi, deleteApi } from 'apis';

const createLeaveRequest = async (data: any) => {
	return await postApi('/leaveRequest', data, true);
};

const updateLeaveRequest = async (id: string, data: any) => {
	return await putApi(`/leaveRequest/${id}`, data, true);
};

const deleteLeaveRequest = async (id: string) => {
	return await deleteApi(`/leaveRequest/delete/${id}`);
};

const getLeaveRequests = async (params: any) => {
	return await getApi('/leaveRequest', params);
};

const getLeaveRequestById = async (id: string) => {
	return await getApi(`/leaveRequest/${id}`);
};

const approveLeave = async (data: any) => {
	return await postApi(`/leaveRequest/approve`, data);
};

const rejectLeave = async (data: any) => {
	return await postApi(`/leaveRequest/reject`, data);
};

const leaveEncashmentDetails = async (params: any) => {
	return await getApi(`/leaveRequest/encashment`, params);
};

export const leaveRequestApi = {
	createLeaveRequest,
	updateLeaveRequest,
	deleteLeaveRequest,
	getLeaveRequests,
	getLeaveRequestById,
	approveLeave,
	rejectLeave,
	leaveEncashmentDetails,
};
