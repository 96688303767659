import React, { useState, useEffect } from 'react';
import {
	Modal,
	Table,
	Space,
} from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import DatePickerField from 'components/Global/DatePicker';
import InputHoursField from 'components/InputHoursField';
import {
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import { timeLogsApi } from 'Api/timeLogs';
import './index.scss';
import SearchComponent from 'components/Global/SearchComponent';
import { SearchOutlined } from '@ant-design/icons';
import useDebounce from 'components/Global/Hooks/UseDebounce';

const { Column } = Table;

const MonthlyTimeLogsModal = ({ isOpen, handleCancel }: any) => {
	const [employeeMonthlyHours, setEmployeeMonthlyHours] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(30);
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [date, setDate] = useState<any>(dayjs());
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};
	const debounceSearch = useDebounce(searchValue, 500);

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const handleDate = (value: string) => {
		setDate(value);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		let hours = timeStringToMinutes('00:00');
		let _data = employeeMonthlyHours;

		const totalRow = employeeMonthlyHours.pop();

		_data = _data.map((log: any) => {
			if (
				newSelectedRowKeys.includes(log.employeeId) &&
				log.employeeId !== 'total'
			) {
				return {
					...log,
					hours:
						log.hours === '00:00'
							? minutesToTimeString(hours)
							: log.hours,
				};
			} else {
				return {
					...log,
					totalHours: '00:00',
				};
			}
		});

		_data.forEach((item: any) => {
			hours += timeStringToMinutes(item.hours);
		});

		totalRow.hours = minutesToTimeString(hours);
		_data.push(totalRow);
		setEmployeeMonthlyHours(_data);

		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record: any) => ({
			disabled: record.isTotal,
		}),
	};

	const handleLogChange = (id: string, name: string, value: any) => {
		let totalHours = 0;

		const _data = employeeMonthlyHours.map((item: any, index: number) => {
			if (item.employeeId === id) {
				totalHours += timeStringToMinutes(
					name === 'hours' ? value : item.hours
				);
				return {
					...item,
					invalidTotalHours: false,
					[name]: value,
				};
			} else if (index === employeeMonthlyHours.length - 1) {
				return {
					...item,
					invalidTotalHours: false,
					totalHours: minutesToTimeString(totalHours),
				};
			} else {
				totalHours += timeStringToMinutes(item.hours);
				return {
					...item,
					invalidTotalHours: false,
				};
			}
		});
		setEmployeeMonthlyHours(_data);
	};

	const fetchMonthlyTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				// jobGrade: selectedJobGrade,
				page: currentPage,
				pageSize: pageSize,
				search: debounceSearch,
			};
			const response = await timeLogsApi.getMonthlyTimeLogs(query);

			setTotalRecords(response.data.total);

			console.log('response: ', response);
			setEmployeeMonthlyHours(response.data.data);
		} catch (err) {
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	const validateEmployeeHours = () => {
		let invalidData = false;

		const _data = employeeMonthlyHours.map((item: any) => {
			let data = { ...item };

			if (item.totalHours === '00:00') {
				data['invalidTotalHours'] = true;
			}

			if (data.invalidTotalHours) {
				invalidData = true;
			}

			return data;
		});

		setEmployeeMonthlyHours(_data);

		return invalidData;
	};

	const handleSubmit = async () => {
		try {
			setIsUpdateLoading(true);
			console.log('employeeMonthlyHours: ', employeeMonthlyHours);
			const data = {
				date: dayjs(date).format('YYYY-MM-DD'),
				employeeHoursData: employeeMonthlyHours
					.filter((item: any) => item.employeeName !== 'Total')
					.map((log: any) => ({
						employeeId: log.employeeId,
						totalHours: timeStringToMinutes(log.hours),
					})),
			};

			const isInvalid = validateEmployeeHours();
			if (!isInvalid) {
				await timeLogsApi.createMonthlyTimeLogs(data);
			}

			toastText('Monthly Attendance updated successfully', 'success');
			handleCancel();
		} catch (error: any) {
			let message = 'Something went wrong in submitting Daily Attendance';
			if (error.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsUpdateLoading(false);
			fetchMonthlyTimeLogs();
		}
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleCancel();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
		{
			text: 'Submit',
			isLoading: isUpdateLoading,
			className: 'btn-blue',
			isSubmit: false,

			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
	];

	useEffect(() => {
		fetchMonthlyTimeLogs();
	}, [date, pageSize, currentPage, debounceSearch]);

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{ minWidth: '1200px' }}
			onCancel={handleCancel}
			footer={() => {
				return (
					<div className={`${styles['modal']} modal`}>
						<div className={styles['modal-header']}>
							<Title level={4}>
								<span>Add Logs For Monthly Employee</span>
							</Title>
							<div className={styles['modal-header-filter']}>
								<Space>
									<SearchComponent
										className={
											styles['employee-header-item']
										}
										placeHolder="Search employee..."
										prefixIcon={<SearchOutlined />}
										handleChange={handleSearch}
										handleChangeApi={handleSearchApi}
										value={searchValue}
										size="large"
									/>
								</Space>
								<DatePickerField
									name="date"
									onChange={handleDate}
									required={false}
									value={date && dayjs(date)}
									isError={false}
									disabled={isTimeLogsLoading}
									placeholder="Select Date"
								/>
							</div>
						</div>
						<div className={styles['modal-body']}>
							<Table
								dataSource={employeeMonthlyHours}
								scroll={{ y: 'calc(100vh - 360px)' }}
								pagination={{
									total: totalRecords,
									current: currentPage,
									pageSize,
									showSizeChanger: false,
									pageSizeOptions: [10, 20, 50, 100, 200],
								}}
								onChange={tableChangeHandler}
								rowKey="employeeId"
								loading={isTimeLogsLoading}
								rowSelection={rowSelection}
								rowClassName={(record) =>
									record.isTotal ? 'total-row' : ''
								}
							>
								<Column
									title="Employee name & code"
									dataIndex="employeeName"
									key="employeeName"
									width="50%"
								/>
								<Column
									title="Total hours"
									dataIndex="hours"
									key="hours"
									width="30%"
									render={(
										text: any,
										data: any,
										index: number
									) =>
										index ===
										employeeMonthlyHours.length - 1 ? (
											<span>{text}</span> 
										) : (
											<InputHoursField
												value={text}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'hours',
														value
													);
												}}
												disabled={false}
												label="Total Hours"
												required={true}
												name="hours"
												isError={data.invalidTotalHours}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										)
									}
								/>
							</Table>
						</div>
						<div>
							<Buttons buttons={buttons} />
						</div>
					</div>
				);
			}}
		>
			{/* Any additional modal content */}
		</Modal>
	);
};

export default MonthlyTimeLogsModal;
