import { PermissionContext } from 'components/Global/AuthLayout';
import NetPayReportComponent from 'components/Report/NetPayReport';
import { useContext } from 'react';

const NetPayReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return <>{check && <NetPayReportComponent />}</>;
};

export default NetPayReport;
