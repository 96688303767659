import { timeActivitiesApi } from 'Api/timeActivities';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../redux/actions/constantDropdownAction';
import TimeActivityHeader from './Header';
import TimeActivitiesTable from './TimeActivitiesTable';
import styles from './index.module.scss';

const TimeActivitiesComponent = () => {
	const navigate = useNavigate();

	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);
	const { isLoading: dropdownOptionLoading } = useSelector(
		(state: any) => state.constantDropdown
	);
	const buttons = [
		{
			text: 'View Daily Attendance',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				navigate('/time-logs');
			},
			disabled: false,
		},
	];
	const dispatch = useDispatch<AppDispatch>();

	const [statusFilterValue, setStatusFilterValue] = useState('');
	const [timeActivity, setTimeActivity] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [date, setDate] = useState(dayjs(new Date()));
	const [staffCode, setStaffCode] = useState('MONTHLY');

	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const handleStatusFilter = (value: string) => {
		setStatusFilterValue(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		// setSortOrder(sorter.order);
		setSortField(sorter.field);
	};
	const handleStaffCodeChange = (newStaffCode: string) => {
		setStaffCode(newStaffCode);
	};
	const fetchEmployeesTimeActivities = async () => {
		try {
			console.log('staffCode: ', staffCode);
			setIsLoading(true);
			const query = {
				page: currentPage,
				pageSize: pageSize,
				sortBy: sortField,
				sortOrder: sortOrder,
				supervisorId: statusFilterValue,
				staffCode: staffCode,
				month: dayjs(date).format('MM'),
				year: dayjs(date).format('YYYY'),
			};

			const employeesTimeActivity =
				await timeActivitiesApi.getEmployeeTimeActivities(query);

			setTimeActivity(employeesTimeActivity.data.timeActivities);
			setTotalRecords(employeesTimeActivity.data.totalRecords);
		} catch (err: any) {
			if (err.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching employees',
					'error'
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	useEffect(() => {
		fetchEmployeesTimeActivities();
	}, [
		currentPage,
		statusFilterValue,
		sortField,
		sortOrder,
		pageSize,
		date,
		staffCode,
	]);

	return (
		<div>
			{/* <TableActionHeader title={'Employees'}>
				<div>
					<Buttons buttons={buttons} />
				</div>
			</TableActionHeader> */}
			<div className={styles['monthly-summary-header']}>
				<p>Monthly Summary</p>
				<div>
					<Buttons buttons={buttons} />
				</div>
			</div>
			<div className={styles['employee-container']}>
				<TimeActivityHeader
					statusFilterValue={statusFilterValue}
					handleStatusFilter={handleStatusFilter}
					options={dropdownOptions?.supervisors}
					handleDatePicker={handleDatePicker}
					date={date}
					employeeCount={totalRecords}
					dropdownOptionLoading={dropdownOptionLoading}
					handleStaffCodeChange={handleStaffCodeChange}
					staffCode={staffCode}
				/>
				<TimeActivitiesTable
					tableData={timeActivity}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};

export default TimeActivitiesComponent;
