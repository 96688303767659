import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type headerProps = {
	sidebarDrawerOpen: () => void;
	NapsaExists: boolean;
	isLoading: boolean;
};

const NapsaHeader = ({
	sidebarDrawerOpen,
	NapsaExists,
	isLoading,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const buttonTitle = NapsaExists ? 'Edit NAPSA' : 'Add NAPSA';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['napsa-header']}>
			<p>NAPSA</p>
			{isAdd && (
				<div>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default NapsaHeader;
