import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
import DatePickerField from 'components/Global/DatePicker';
import SearchComponent from 'components/Global/SearchComponent';
import { Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type headerProps = {
	sidebarDrawerOpen: () => void;
	PayPeriodExists: boolean;
	isLoading: boolean;
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	yearValue: any;
	handleYearDatePicker: any;
};

const PayPeriodHeader = ({
	sidebarDrawerOpen,
	PayPeriodExists,
	isLoading,
	searchText,
	handleSearch,
	handleSearchApi,
	handleYearDatePicker,
	yearValue,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Pay_Period');
	const buttonTitle = PayPeriodExists ? 'Edit Pay Period' : 'Add Pay Period';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['payPeriod-header']}>
			<div>
				<Space>
					<SearchComponent
						className={styles['payPeriod-header-item']}
						placeHolder="Search Name "
						prefixIcon={<SearchOutlined />}
						handleChange={handleSearch}
						handleChangeApi={handleSearchApi}
						value={searchText}
						size="large"
					/>
					<DatePickerField
						name={'dateFilter'}
						value={yearValue}
						required={false}
						picker="year"
						isError={false}
						onChange={handleYearDatePicker}
					/>
				</Space>
			</div>
			{isAdd && <Buttons buttons={buttons} />}
		</div>
	);
};

export default PayPeriodHeader;
