import { getApproveRequestApi } from 'Api/approveRequest';
import { Button, Checkbox, Collapse, Modal, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { Loader } from 'components/Global';
import InputField from 'components/Global/InputField';
import { useEffect, useState } from 'react';
import {
	fieldNameMapping,
	invalidText,
	sectionMapping,
	toastText,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	isNotificationModalOpen: boolean;
	handleCancel: () => void;
	approvalId: string | null;
	notificationId: string | null;
};

const { Panel } = Collapse;

const UpdateNotificationModal = (props: Props) => {
	const [notificationData, setNotificationData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAcceptLoading, setIsAcceptLoading] = useState(false);
	const [isRejectLoading, setIsRejectLoading] = useState(false);
	const [checkedState, setCheckedState] = useState<any>([]);
	const [reasonState, setReasonState] = useState<{ [key: string]: string }>(
		{}
	);
	const [errorState, setErrorState] = useState<{ [key: string]: boolean }>(
		{}
	);

	const {
		isNotificationModalOpen,
		handleCancel,
		approvalId: _approvalId,
		notificationId,
	} = props;

	useEffect(() => {
		fetchApprovalRequest();
	}, []);

	const fetchApprovalRequest = async () => {
		setIsLoading(true);
		try {
			const resData = await getApproveRequestApi.getApprovalRequests({
				approvalId: _approvalId,
			});
			setNotificationData(resData.data.data);
		} catch (error) {}
		setIsLoading(false);
	};

	const handleHeaderCheckboxChange = (
		employeeId: string,
		section: string,
		checked: boolean
	) => {
		setCheckedState((prevState: any) => {
			const updatedState = { ...prevState };
			notificationData?.forEach((employee: any) => {
				if (employee.entityId === employeeId) {
					employee.ApprovalRequest.forEach((request: any) => {
						if (request.section === section) {
							updatedState[request.id] = checked;
							if (!checked) {
								setErrorState((prevState) => ({
									...prevState,
									[request.id]: false,
								}));
							}
						}
					});
				}
			});
			return updatedState;
		});
	};

	const handleCheckboxChange = (id: string, checked: boolean) => {
		setCheckedState((prevState: any) => ({
			...prevState,
			[id]: checked,
		}));

		if (!checked) {
			setErrorState((prevState) => ({
				...prevState,
				[id]: false,
			}));
		}
	};

	const handleReasonChange = (id: string, value: string) => {
		setReasonState((prevState) => ({
			...prevState,
			[id]: value,
		}));
		setErrorState((prevState) => ({
			...prevState,
			[id]: false,
		}));
	};

	const handleApproveClick = (employeeId: string) => {
		const selectedIds = Object.keys(checkedState).filter(
			(key) => checkedState[key]
		);

		if (selectedIds.length > 0) {
			const result = selectedIds.map((id) => ({
				id,
				reason: reasonState[id] || '',
			}));
			acceptApprovalRequest(result);
		}
	};

	const acceptApprovalRequest = async (ids: any) => {
		setIsAcceptLoading(true);
		try {
			await getApproveRequestApi.acceptAllNotificationApproval({
				ids,
				userNotificationId: notificationId,
			});
			handleCancel();
			toastText('Request approved successfully', 'success');
		} catch (error) {}
		setCheckedState([]);
		setIsAcceptLoading(false);
	};

	const handleRejectClick = (employeeId: string) => {
		const selectedIds = Object.keys(checkedState).filter(
			(key) => checkedState[key]
		);

		if (selectedIds.length > 0) {
			let isValid = true;
			const newErrorState: { [key: string]: boolean } = {};

			selectedIds.forEach((id) => {
				if (!reasonState[id]) {
					isValid = false;
					newErrorState[id] = true;
				}
			});

			if (isValid) {
				const result = selectedIds.map((id) => ({
					id,
					reason: reasonState[id],
				}));
				rejectApprovalRequest(result);
			} else {
				setErrorState(newErrorState);
			}
		}
	};

	const rejectApprovalRequest = async (ids: any) => {
		setIsRejectLoading(true);
		try {
			await getApproveRequestApi.rejectAllNotificationApproval({
				ids,
				userNotificationId: notificationId,
			});
			toastText('Request rejected successfully', 'success');
			handleCancel();
		} catch (error) {}
		setCheckedState([]);
		setIsRejectLoading(false);
	};

	const mapEmployeesToAccordionItems = (notification: any) => {
		return notification.map((employee: any) => {
			const approvalRequestsBySection: { [key: string]: any[] } = {};

			employee.ApprovalRequest.forEach((request: any) => {
				if (!approvalRequestsBySection[request.section]) {
					approvalRequestsBySection[request.section] = [];
				}
				approvalRequestsBySection[request.section].push(request);
			});

			const sectionName = Object.keys(approvalRequestsBySection);
			const section = sectionName[0];

			return {
				key: employee.entityId,
				label: (
					<h4
						className="color-purple"
						style={{
							fontWeight: 500,
							fontSize: '1.7rem',
							marginBottom: '12px',
						}}
					>
						{sectionMapping[section as keyof typeof sectionMapping]}{' '}
						details
					</h4>
				),
				children: (
					<div className="notification-table">
						{Object.entries(approvalRequestsBySection).map(
							([section, requests], index) => (
								<div key={section}>
									{/* <h4
										className="color-purple"
										style={{
											fontWeight: 500,
											fontSize: '1.7rem',
											marginBottom: '12px',
										}}
									>
										{
											sectionMapping[
												section as keyof typeof sectionMapping
											]
										}
									</h4> */}
									<Table
										dataSource={requests}
										pagination={false}
										rowKey="id"
									>
										<Column
											title={
												<Checkbox
													checked={requests.every(
														(request: any) =>
															checkedState[
																request.id
															]
													)}
													onChange={(e) =>
														handleHeaderCheckboxChange(
															employee.entityId,
															section,
															e.target.checked
														)
													}
												/>
											}
											width={'10%'}
											key="checkbox"
											render={(text, record: any) => (
												<Checkbox
													checked={
														checkedState[record.id]
													}
													onChange={(e) =>
														handleCheckboxChange(
															record.id,
															e.target.checked
														)
													}
												/>
											)}
										/>

										<Column
											title="Field Name"
											key="fieldName"
											className="bg-white"
											width={'20%'}
											render={(text, record: any) =>
												fieldNameMapping[
													record.fieldName as keyof typeof fieldNameMapping
												]
											}
										/>
										<Column
											title="Old Value"
											dataIndex="oldValue"
											key="oldValue"
											width={'20%'}
											className="bg-white"
											render={(record, data: any) => {
												let _record = record;

												if (
													data.changes.isSelectField
												) {
													_record =
														data.changes
															.oldValueName;
												}

												return _record;
											}}
										/>
										<Column
											title="Updated Value"
											dataIndex="newValue"
											key="newValue"
											width={'20%'}
											className="bg-white"
											render={(record, data: any) => {
												let _record = record;

												if (
													data.changes.isSelectField
												) {
													_record =
														data.changes
															.newValueName;
												}

												return _record;
											}}
										/>
										<Column
											title="Reason"
											key="reason"
											width={'30%'}
											className="bg-white"
											render={(text, record: any) => (
												<div>
													<InputField
														name={`reason-${record.id}`}
														label=""
														value={
															reasonState[
																record.id
															] || ''
														}
														required={false}
														isError={
															errorState[
																record.id
															] || false
														}
														helperText={
															'Reason is required'
														}
														disabled={
															!checkedState[
																record.id
															]
														} // Disable if the checkbox is not checked
														onChange={(
															value: string
														): void =>
															handleReasonChange(
																record.id,
																value
															)
														}
													/>
												</div>
											)}
										/>
									</Table>
								</div>
							)
						)}
						{Object.values(checkedState).some(
							(value) => value === true
						) && (
							<>
								<Space>
									<Button
										className="btn-cancel"
										onClick={() =>
											handleRejectClick(employee.entityId)
										}
										loading={isRejectLoading}
										disabled={
											isAcceptLoading || isRejectLoading
										}
									>
										Reject
									</Button>
									<Button
										className="btn-blue"
										onClick={() =>
											handleApproveClick(
												employee.entityId
											)
										}
										loading={isAcceptLoading}
										disabled={
											isAcceptLoading || isRejectLoading
										}
									>
										Approve
									</Button>
								</Space>
							</>
						)}
					</div>
				),
			};
		});
	};

	const items = mapEmployeesToAccordionItems(notificationData);

	return (
		<Modal
			open={isNotificationModalOpen}
			onCancel={handleCancel}
			okText="Save"
			closable={false}
			width={900}
			footer={null}
		>
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '40vh',
					}}
				>
					<Loader />
				</div>
			) : (
				<div className={styles['notification-modal']}>
					<h2 className={styles['notification-modal__title']}>
						Notifications
					</h2>
					{notificationData?.length ? (
						<div className={styles['notification-modal__list']}>
							<div
								className={
									styles['notification-modal__list--items']
								}
							>
								<Collapse
									defaultActiveKey={['1']}
									style={{
										padding: '0px',
									}}
									className="collapse"
								>
									{items.map((item: any) => {
										if (item.children) {
											return (
												<Panel
													key={item.key}
													header={item.label}
													disabled={invalidText(
														item.children
													)}
													style={{
														padding: '5px 0px',
													}}
												>
													{item.children}
												</Panel>
											);
										}
									})}
								</Collapse>
							</div>
						</div>
					) : (
						<>
							<div
								className={
									styles['notification-modal--icon__text']
								}
							>
								No approval request present!
							</div>
						</>
					)}
				</div>
			)}
		</Modal>
	);
};

export default UpdateNotificationModal;
