import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import CheckBox from 'components/Global/Checkbox';
import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import { getApproveRequestApi } from 'Api/approveRequest';
import { LoadingOutlined } from '@ant-design/icons';

type EmployeeItem = {
	id: number;
	label: string;
	fieldName: string;
	sectionName: string;
	moduleName: string;
	sendApproval: boolean;
};

type Props = {
	data: EmployeeItem[];
	handleEmployeeFields: (data: any) => void;
};

const EmployeeApproval = () => {
	const [employeeFields, setEmployeeFields] = useState<EmployeeItem[]>([]);
	const [loading, setLoading] = useState(false);
	const [isOnSaveLoading, setIsOnSaveLoading] = useState(false);

	const handleCheckboxChange = (id: number, checked: boolean) => {
		const updatedData = employeeFields.map((item: any) =>
			item.id === id ? { ...item, sendApproval: checked } : item
		);
		setEmployeeFields(updatedData);
	};

	const handleSave = async () => {
		// const checkedItems = employeeFields.filter((item) => item.sendApproval);
		if (employeeFields.length > 0) {
			try {
				setIsOnSaveLoading(true);

				const data = await getApproveRequestApi.updateApprovalFields({
					fields: employeeFields,
				});
				toastText(
					'Approval fields has been updated successfully.',
					'success'
				);
			} catch (err) {
				toastText(
					'Something went wrong in updating employee fields',
					'error'
				);
			}
			setIsOnSaveLoading(false);
		}
	};

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			disabled: isOnSaveLoading,
			isSubmit: false,
			onclick: () => {},
		},
		{
			text: 'Save',
			isLoading: isOnSaveLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '100px',
			minHeight: '42px',
			isSubmit: true,
			disabled: false,
			onclick: handleSave,
		},
		
	];

	const fetchEmployeeFields = async () => {
		try {
			setLoading(true);
			const data = await getApproveRequestApi.getApprovalFields({
				moduleName: 'EMPLOYEE',
			});

			const empData = data.data?.data.filter(
				(item: any) =>
					item.sectionName === 'Employee Details' ||
					item.sectionName === 'Personal Details' ||
					item.sectionName === 'Identification Details'
			);

			setEmployeeFields(empData);
			setLoading(false);
		} catch (err) {
			toastText(
				'Something went wrong in fetching employee fields',
				'error'
			);
		}
	};

	useEffect(() => {
		fetchEmployeeFields();
	}, []);

	return (
		<>
			{loading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '70vh',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : (
				<div className={styles['employeeApprovalContainer']}>
					<div
						className={
							styles['employeeApprovalContainer--checkbox']
						}
					>
						<Row gutter={[16, 16]}>
							{employeeFields.map((checkbox) => (
								<Col
									key={checkbox.id}
									xs={24}
									sm={12}
									md={12}
									lg={8}
								>
									<CheckBox
										label={checkbox.label}
										checked={checkbox.sendApproval}
										onChange={(checked) =>
											handleCheckboxChange(
												checkbox.id,
												checked
											)
										}
									/>
								</Col>
							))}
						</Row>
					</div>
					<div className={styles.buttonsContainer}>
						<Buttons buttons={myButtons}></Buttons>
					</div>
				</div>
			)}
		</>
	);
};

export default EmployeeApproval;
