import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import { loanRequestApi } from 'Api/loanRequest';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';

const { Title, Text } = Typography;

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedLoanRequest: string;
	selectedLoanRequestLabel: string;
	approvalId: string;
	fetchApprovalRequest: () => void;
};

const NotificationLoanRequestModal: React.FC<Props> = ({
	isOpen,
	handleCancel,
	selectedLoanRequest,
	selectedLoanRequestLabel,
	approvalId,
	fetchApprovalRequest,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [approveIsLoading, setApproveIsLoading] = useState(false);
	const [rejectIsLoading, setRejectIsLoading] = useState(false);
	const [loanDetails, setLoanDetails] = useState<any>(null);

	const fetchLoanRequest = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await loanRequestApi.getLoanRequestDetails(id);
			setLoanDetails(response.data.data);
		} catch (err) {
			toastText('Something went wrong in getting loan request', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	async function approveLoanRequest() {
		try {
			setApproveIsLoading(true);
			await loanRequestApi.approveLoan({
				approvalId,
				loanRequestId: selectedLoanRequest,
			});
		} catch (error) {
			toastText(
				'Something went wrong in approving loan request',
				'error'
			);
		} finally {
			setApproveIsLoading(false);
			toastText('Loan approved successfully', 'success');
			handleCancel();
			fetchApprovalRequest();
		}
	}

	async function rejectLoanRequest() {
		try {
			setRejectIsLoading(true);
			await loanRequestApi.rejectLoan({
				approvalId,
				loanRequestId: selectedLoanRequest,
			});
		} catch (error) {
			toastText(
				'Something went wrong in rejecting loan request',
				'error'
			);
		} finally {
			setRejectIsLoading(false);
			toastText('Loan rejected successfully', 'success');
			handleCancel();
			fetchApprovalRequest();
		}
	}

	const calculateMonthlyDeductions = (
		amount: number,
		installments: number,
		startDate: Date
	) => {
		const monthlyAmount = amount / installments;
		const deductions = [];
		let currentDate = new Date(startDate);


		currentDate.setMonth(currentDate.getMonth() + 1);
		for (let i = 0; i < installments; i++) {
			deductions.push({
				date: `${dayjs(currentDate).format(
					'MMMM'
				)} ${currentDate.getFullYear()}`,
				amount: `${monthlyAmount.toFixed(2)}`,
			});
			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		return deductions;
	};

	const buttons = [
			{
			text: 'Cancel',
			isLoading: false,
			isSubmit: true,
			className: 'secondary-button',
			onclick: handleCancel,
			disabled: isLoading,
		},
		{
			text: 'Approve',
			isSubmit: true,
			className: 'btn-blue',
			onclick: approveLoanRequest,
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			size: 'small',
			isLoading: approveIsLoading,
		},
		{
			text: 'Reject',
			isSubmit: true,
			className: 'secondary-button',
			onclick: rejectLoanRequest,
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			isLoading: rejectIsLoading,
		},
	];

	useEffect(() => {
		if (selectedLoanRequest) {
			fetchLoanRequest(selectedLoanRequest);
		}
	}, [selectedLoanRequest]);

	return (
		<Modal
			open={isOpen}
			className="notificationLoanRequest"
			closable={false}
			onCancel={handleCancel}
			width={900}
			footer={() => (
				<div className={styles.footerButtons}>
					<Buttons buttons={buttons} />
				</div>
			)}
		>
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '40vh',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : loanDetails ? (
				<div className={styles.container}>
					<Title level={3}>{selectedLoanRequestLabel}</Title>
					<div className={styles.infoSection}>
						<div className={styles.infoItem}>
							<p>Employee</p>
							<p>{`${loanDetails.employee.firstName} ${loanDetails.employee.lastName} (${loanDetails.employee.employeeId})`}</p>
						</div>
						<div className={styles.infoItem}>
							<p>Loan Amount</p>
							<p>{`${loanDetails.amount.toFixed(2)}`}</p>
						</div>
						{/* <div className={styles.infoItem}>
							<p>Effective Date</p>
							<p>{new Date(loanDetails.createdAt).toLocaleDateString()}</p>
						</div> */}
						<div className={styles.infoItem}>
							<p>Installments</p>
							<p>{loanDetails.installment}</p>
						</div>
						<div className={styles.infoItem}>
							<p>Submitted By</p>
							<p>{loanDetails.requestedBy.fullName}</p>
						</div>
					</div>

					<Title level={4}>Monthly Deductions</Title>
					<div className={styles.tableWrapper}>
						<table className={styles.deductionsTable}>
							<thead>
								<tr>
									<th>Date</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								{calculateMonthlyDeductions(
									loanDetails.amount,
									loanDetails.installment,
									new Date(loanDetails.createdAt)
								).map((deduction, index) => (
									<tr key={index}>
										<td>{deduction.date}</td>
										<td>{deduction.amount}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div>No loan details available</div>
			)}
		</Modal>
	);
};

export default NotificationLoanRequestModal;
