import { Form, Input } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';

const AddJobGradeBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const { closeDrawerByAnimation, fetchJobGrades, editSelected } = props;

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
			font: '16px',
			minHeight: '48px',
			minWidth: '60px',
		},
		{
			text: `Save`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			font: '16px',
			minHeight: '48px',
			minWidth: '110px',
		},
	];
	const handleSubmit = async (values: any) => {
		if (editSelected) {
			let data: any = {
				jobGradeId: editSelected?.id,
				...values,
				lowerRate: Number(values.lowerRate),
				upperRate: Number(values.upperRate),
				salRate: Number(values.salRate),
			};

			await updateCategoryCode(data);
		} else {
			let data: any = {
				...values,
				lowerRate: Number(values.lowerRate),
				upperRate: Number(values.upperRate),
				salRate: Number(values.salRate),
			};
			await addCategoryCode(data);
		}
	};

	const updateCategoryCode = async (categoryCodeData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/jobGrade', categoryCodeData);
			fetchJobGrades();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Job grade updated successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong in updating job grade';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addCategoryCode = async (finalData: any) => {
		try {
			setLoading(true);
			const response = await postApi('/jobGrade', finalData);
			fetchJobGrades();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Job grade created successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating job grade';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={editSelected}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Job Grade'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'jobGrade'}
							rules={[
								{
									required: true,
									message: 'Job grade required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'job grade length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Position'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'position'}
							rules={[
								{
									required: true,
									message: 'Position required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Position length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Lower Rate'}{' '}
							{/* <span className="required-color">*</span> */}
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'lowerRate'}
							rules={[
								{
									// required: true,
									message: 'lower rate must be a number',
									pattern: /^[0-9]+(\.[0-9]+)?$/,
									validateTrigger: 'onChange',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Upper Rate'}{' '}
							{/* <span className="required-color">*</span> */}
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'upperRate'}
							rules={[
								{
									// required: true,
									message: 'upper rate must be a number',
									pattern: /^[0-9]+(\.[0-9]+)?$/,
									validateTrigger: 'onChange',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Sal Rate'}{' '}
							{/* <span className="required-color">*</span> */}
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'salRate'}
							rules={[
								{
									// required: true,
									message: 'sal rate must be a number',
									pattern: /^[0-9]+(\.[0-9]+)?$/,
									validateTrigger: 'onChange',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddJobGradeBody;
