import { Form, Input, Select } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';

const AddDepartmentBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const {
		closeDrawerByAnimation,
		fetchDepartment,
		editSelected,
		branchOptions,
	} = props;
	const [isSelected, setIsSelected] = useState<boolean>();
	const [selectedBranch, setSelectedBranch] = useState(
		editSelected ? editSelected?.branchId : null
	);

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
			font: '16px',
			minHeight: '48px',
			minWidth: '60px',
		},
		{
			text: `Save`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			font: '16px',
			minHeight: '48px',
			minWidth: '110px',
		},
	];
	const handleSubmit = async (values: any) => {
		if (editSelected) {
			let data: any = {
				departmentId: editSelected?.id,
			};

			if (selectedBranch) {
				data = {
					...data,
					branchId: selectedBranch,
					name: values.name,
					code: values.code,
					// isChangeStatus: false,
				};

				await updatedepartmentData(data);
			}
		} else {
			if (selectedBranch) {
				setIsSelected(true);
				const finalData = {
					branchId: selectedBranch,
					name: values.name,
					code: values.code,
				};
				await addDepartment(finalData);
			} else {
				selectedBranch ? setIsSelected(true) : setIsSelected(false);
			}
		}
	};
	// If form fails
	const onFinishFailed = () => {
		if (selectedBranch) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	};

	const updatedepartmentData = async (departmentData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/department', departmentData);
			fetchDepartment();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Department updated successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong in updating department';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addDepartment = async (finalData: any) => {
		try {
			setLoading(true);
			const response = await postApi('/department', finalData);
			fetchDepartment();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Department created successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to job title';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					onFinishFailed={onFinishFailed}
					initialValues={editSelected}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Department Code'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'code'}
							rules={[
								{
									required: true,
									message: 'Department code required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Department code length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Department Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Department name required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Department Name length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
						<Form.Item
							className="side-drawer-form__role"
							name={'branchId'}
						>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Branch'}{' '}
								{true && (
									<span className="required-color">*</span>
								)}
							</label>

							<Select
								placeholder="Select Branch"
								className={styles['side-drawer-form--select']}
								size="large"
								disabled={false}
								value={selectedBranch}
								onSelect={(role) => {
									setSelectedBranch(role);
									setIsSelected(true);
								}}
							>
								{branchOptions?.map(
									(branch: any, key: number) => {
										return (
											<Select.Option
												value={branch?.id}
												key={'key'}
											>
												{branch?.name}
											</Select.Option>
										);
									}
								)}
							</Select>
							{isSelected === false && (
								<p className="ant-form-item-explain-error">
									Branch required
								</p>
							)}
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddDepartmentBody;
