import { createSlice } from '@reduxjs/toolkit';
import { fetchPayPeriodAction } from '../actions/payPeriodAction';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const PayPeriodSlice = createSlice({
	name: 'payPeriod',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPayPeriodAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(fetchPayPeriodAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(fetchPayPeriodAction.rejected, (state, action: any) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default PayPeriodSlice;
