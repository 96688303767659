import Employees from 'components/Employees';
import './index.scss';
import { useContext, useEffect, useState } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/Global';

export const EmployeesPage = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const [permissionsLoaded, setPermissionsLoaded] = useState(false);
	useEffect(() => {
		if (
			context.allowedPermissions &&
			context.allowedPermissions.length > 0
		) {
			setPermissionsLoaded(true);
		}
	}, [context.allowedPermissions]);
	useEffect(() => {
		if (permissionsLoaded) {
			const check =
				context.allowedPermissions.includes('Allow_Employment');
			if (!check) {
				navigate('/');
			}
		}
	}, [permissionsLoaded, context.allowedPermissions, navigate]);

	if (!permissionsLoaded) {
		return <Loader />;
	}
	return (
		<div>
			{context.allowedPermissions.includes('Allow_Employment') && (
				<Employees />
			)}
		</div>
	);
};
