import { PermissionContext } from 'components/Global/AuthLayout';
import CoaTables from 'components/Settings/Coa';
import { useContext } from 'react';
import './index.scss';

export const Coa = () => {
	const context = useContext(PermissionContext);
	const check = context.isAdmin;
	return <div>{check && <CoaTables />}</div>;
};
