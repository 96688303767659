import { Modal, Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import { EyeIcon } from 'utils/svgs';
import './index.scss';

type HistoryModalProps = {
	isModalOpen: boolean;
	handleCancel: () => void;
	historyLeaveData: any;
};

const TooltipContent = ({ maximumLeavePerMonth, configuration }: any) => {
	const configurationRules = configuration
		? configuration?.sort((a: any, b: any) => a.minMonth - b.minMonth)
		: [];

	configurationRules.forEach((rule: any) => {
		if (rule.maxMonth === -1) {
			rule.maxMonth = Infinity;
		}
	});
	return (
		<div className={styles['tooltip-content']}>
			<div className={styles['column-tooltip']}>
				<p className={styles['grey-color']}>
					Minimum paid leave per month:
				</p>
				<p className={styles['bold-content']}>
					{' '}
					{maximumLeavePerMonth}
				</p>
			</div>
			{configurationRules?.map((config: any, index: number) => (
				<div key={index} className={styles['row']}>
					<div className={styles['column-tooltip']}>
						<p className={styles['grey-color']}>
							Minimum number of months:
						</p>
						<p className={styles['bold-content']}>
							{' '}
							{config.minMonth}
						</p>
					</div>
					<div className={styles['column-tooltip']}>
						<p className={styles['grey-color']}>
							Maximum number of months:
						</p>
						<p className={styles['bold-content']}>
							{' '}
							{config.maxMonth}
						</p>
					</div>
					<div className={styles['column-tooltip']}>
						<p className={styles['grey-color']}>=</p>
					</div>
					<div className={styles['column-tooltip']}>
						<p className={styles['grey-color']}>Total leaves:</p>
						<p className={styles['bold-content']}>
							{' '}
							{config.leaves}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

const HistoryPopOver = (data: any) => {
	const { maximumLeavePerMonth, configuration } = data.data;
	return (
		<div className="ant-tooltip-container">
			<Tooltip
				title={
					<TooltipContent
						maximumLeavePerMonth={maximumLeavePerMonth}
						configuration={configuration}
					/>
				}
				color="#fff"
				placement="bottomLeft"
				overlayInnerStyle={{ width: 'max-content' }}
				className="ant-tooltip-paye"
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<EyeIcon />
					<span>View Details</span>
				</div>
			</Tooltip>
		</div>
	);
};

const LeaveHistoryModal = (props: HistoryModalProps) => {
	const { isModalOpen, handleCancel, historyLeaveData } = props;

	const { leaveHistory } = historyLeaveData;
	return (
		<>
			<Modal
				open={isModalOpen}
				onCancel={handleCancel}
				footer={null}
				destroyOnClose={true}
				closable={false}
				width={1000}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>
							{' '}
							{historyLeaveData?.name} History
						</Title>
						<div
							className={styles['close-icon']}
							onClick={handleCancel}
						>
							<CloseOutlined />
						</div>
					</div>
					<div className="history-table">
						<Table dataSource={leaveHistory} pagination={false}>
							<Column
								title="Leave Name"
								dataIndex="name"
								key="name"
								width={'20%'}
							/>
							<Column
								title="Description"
								dataIndex="description"
								key="description"
								width={'20%'}
								render={(text) => (
									<Ellipse
										message={text}
										maxLength={20}
										key={text}
										tooltipMessage={text}
										isTooltip={true}
									/>
								)}
							/>
							<Column
								title="Leave History"
								dataIndex="configuration"
								key="configuration"
								width={'30%'}
								render={(text, data: any) => (
									<HistoryPopOver data={data} />
								)}
							/>
						</Table>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default LeaveHistoryModal;
