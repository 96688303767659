import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';

import { handleDownload, invalidText } from 'utils/utils';

import EmployeeShareComponent from '../ToolTip';
import dayjs from 'dayjs';
import { useState } from 'react';
import { payeApi } from 'Api/payeApi';

type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allPayeDetails: any;
	isLoading: boolean;
	fetchAllPayeDetails: any;
};
const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allPayeDetails,
		isLoading,
	} = props;
	const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
		null
	);

	const handleRecordDownload = async (
		recordId: string,
		recordName: string
	) => {
		return await handleDownload(
			recordId,
			payeApi.downloadPayeAttachment,
			setDownloadingRowId,
			recordName
		);
	};
	return (
		<>
			<Modal
				title="History"
				open={open}
				onCancel={closeModal}
				maskClosable={true}
				width={1000}
				closable={true}
				footer={null}
			>
				<Table
					dataSource={allPayeDetails}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record?.id}
					loading={isLoading}
				>
					<Column
						title="Updated By"
						dataIndex="createdBy"
						key="createdBy"
						className="bg-white"
						render={(text) => text.fullName}
					/>
					<Column
						title="Effective Date"
						dataIndex="effectiveDate"
						key="effectiveDate"
						sorter={true}
						className="bg-white"
						render={(text) => dayjs(text).format('DD/MM/YYYY')}
					/>

					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						sorter={true}
						className="bg-white"
						render={(text) =>
							invalidText(text) ? (
								'-'
							) : (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)
						}
					/>

					<Column
						title="Attachment"
						dataIndex="attachment"
						key="attachment"
						className="bg-white"
						render={(text, record: any) => (
							<div>
								{record?.documentName ? (
									<div>
										<div
											onClick={() =>
												downloadingRowId === record.id
													? () => {}
													: handleRecordDownload(
															record.id,
															record.documentName
													  )
											}
											style={{
												cursor:
													downloadingRowId ===
													record.id
														? 'not-allowed'
														: 'pointer',
												opacity:
													downloadingRowId ===
													record.id
														? 0.5
														: 1,
											}}
										>
											<Ellipse
												message={record.documentName}
												maxLength={50}
												key={record.documentName}
												tooltipMessage={
													record.documentName
												}
												isTooltip={true}
												isLink={true}
											/>
										</div>
									</div>
								) : (
									'-'
								)}
							</div>
						)}
					/>
					<Column
						title="Employee Share"
						dataIndex="PAYEEmployeeShare"
						key="PAYEEmployeeShare"
						className="bg-white"
						render={(employeeShare) => (
							<EmployeeShareComponent
								employeeShare={employeeShare}
							/>
						)}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
