import React, { useEffect, useState } from 'react';
// import ApproveRejectComponent from './ApproveRejectComponent';
import { postApi } from 'apis';
import ApproveRejectComponent from 'components/Payroll/payrollApproveReject';
import { ApprovalLayout } from 'layouts/ApprovalLayout';
import { useSearchParams } from 'react-router-dom';
import { toastText } from 'utils/utils';

const ApproveRejectPage: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<
		'idle' | 'approved' | 'rejected' | 'error' | ''
	>('');
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');

	useEffect(() => {
		handleAction();
	}, []);

	const handleAction = async () => {
		setIsLoading(true);
		setStatus('idle');

		if (!token) {
			setIsLoading(false);
			return;
		}

		try {
			const isToken = await verifyToken(token);

			if (!isToken) {
				setStatus('error');
				return; // Error message already handled in verifyToken
			}

			const approvalRequestId = isToken.data.approvalId;
			const action = searchParams.get('action');

			if (action === 'approve') {
				await approvePayroll(approvalRequestId);
			} else {
				await rejectPayroll(approvalRequestId);
			}
		} catch (error: any) {
			setStatus('error');
			toastText(
				'An unexpected error occurred. Please try again.',
				'error'
			);
		} finally {
			setIsLoading(false);
		}
	};

	const verifyToken = async (token: string) => {
		try {
			const isToken = await postApi(
				`/auth/payrollapproval-verify-token?token=${token}`
			);
			return isToken;
		} catch (error: any) {
			let errorMessage = '';
			if (error.response?.data?.responseStatus === 401) {
				errorMessage = 'Token verification failed please login first';
			} else {
				errorMessage = error.response?.data?.message;
			}
			toastText(errorMessage, 'error');
			return null; // Return null to indicate failure
		}
	};

	const approvePayroll = async (approvalRequestId: string) => {
		try {
			await postApi(`/payroll/approve`, { approvalRequestId });
			setStatus('approved');
			toastText('Payroll approved successfully', 'success');
		} catch (error: any) {
			setStatus('error');
			const errorMessage =
				error.response?.data?.message || 'Failed to approve payroll';
			toastText(errorMessage, 'error');
		}
	};

	const rejectPayroll = async (approvalRequestId: string) => {
		try {
			await postApi(`/payroll/reject`, { approvalRequestId });
			setStatus('rejected');
			toastText('Payroll rejected successfully', 'success');
		} catch (error: any) {
			setStatus('error');
			const errorMessage =
				error.response?.data?.message || 'Failed to reject payroll';
			toastText(errorMessage, 'error');
		}
	};

	return (
		<ApprovalLayout>
			<ApproveRejectComponent
				isLoading={isLoading}
				status={status}
				errorMessage={''}
			/>
		</ApprovalLayout>
	);
};

export default ApproveRejectPage;
