import { Table } from 'antd';
import { FaFileImage, FaFilePdf } from 'react-icons/fa';
import styles from './index.module.scss';

const { Column } = Table;

// Define a type for each document item
interface DocumentItem {
	id: string;
	documentName: string;
	documentUrl: string;
	documentLink: string;
}

// Props interface with documentData as an array of DocumentItem
interface Props {
	documentData: DocumentItem[];
	isLoading: boolean;
}

// Mapping file extensions to icons
const iconMapping: { [key: string]: JSX.Element } = {
	pdf: <FaFilePdf color="#d32f2f" size={20} />,
	jpg: <FaFileImage color="#fbc02d" size={20} />,
	jpeg: <FaFileImage color="#fbc02d" size={20} />,
	png: <FaFileImage color="#fbc02d" size={20} />,
	//default: <FaFileAlt />,
};

const getIconByExtension = (url: any) => {
	const extension = url.split('.').pop()?.toLowerCase();
	return extension && iconMapping[extension]
		? iconMapping[extension]
		: iconMapping['default'];
};

const DocumentTable = ({ documentData, isLoading }: Props) => {
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={documentData}
				pagination={false}
				scroll={{ y: 'calc(100vh - 200px)' }}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Sr. No."
					key="serialNumber"
					className="bg-white"
					render={(text, record, index) => index + 1}
				/>
				<Column
					title="Document Name"
					dataIndex="documentName"
					key="documentName"
					className="bg-white"
				/>
				<Column
					title="Document"
					dataIndex="documentUrl"
					key="document"
					className="bg-white"
					render={(text, record: DocumentItem) => (
						<div className={styles['fileContainer']}>
							{getIconByExtension(record.documentUrl)}{' '}
							<a
								href={record.documentLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<span className={styles['fileIcon']}>
									{record.documentName}
								</span>
							</a>
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default DocumentTable;
