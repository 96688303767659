import TableActionHeader from 'components/Global/TableActionHeader';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { roleColumns } from 'constants/Data';
import { UserProfileInterface } from 'interfaces/user.interface';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { AddSvg } from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import { SideDrawerWrapper } from '../../../../src/components/Global';
import AddRoleBody from './AddRoleBody';
import PermissionBody from './PermissionBody';
import DynamicTable from './Table';
import styles from './index.module.scss';
import { getApi } from 'apis';

const RoleTable = () => {
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [selectedRole, setSelectedRole] = useState<any>(null);
	const [sort, setSort] = useState('asc');
	const [isPermissionDrawerOpen, setPermissionSideDrawerOpen] =
		useState<boolean>(false);
	const [filteredData, setFilterData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchValue, setSearchValue] = useState('');
	const [filterValue, setFilterValue] = useState('all');
	const [drawerInfo, setDrawerInfo] = useState({
		drawerTitle: 'Add Role',
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [sortDirection, SetsortDirection] = useState('ascend');
	const [PageSize, setPageSize] = useState(10);

	const [permissions, setPermissions] = useState([]);
	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		// Initialize page size from local storage
		const pageSizeFromLocalStorage = localStorage.getItem('roleTableSize');
		if (pageSizeFromLocalStorage !== null) {
			setPageSize(parseInt(pageSizeFromLocalStorage));
		}
	}, []);

	// Remove from drawer handler
	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
		setPermissionSideDrawerOpen(false);
	};
	// For open the sideDrawer with animation
	const openDrawerHandler = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};
	// For open the sideDrawer with animation
	const openPermissionsHandler = () => {
		setDrawerAnimation(true);
		setPermissionSideDrawerOpen(true);
	};
	// For perform the close animation
	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
		setPermissions([]);
	};

	// Handle the pagination for the table
	const paginationChangeHandler = (pageNo: number, pageSize: number) => {
		setCurrentPage(pageNo);
		setPageSize(pageSize);
	};

	// For perform the search operation
	const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setSearchValue(value);
		setCurrentPage(1);
	};

	// Perform Filter
	const performFilterHandler = (value: any) => {
		setFilterValue(value);
		setCurrentPage(1);
	};

	// For perform the sorting operation
	const performSortHandler = (sortType: string) => {
		if (sortType !== sortDirection) {
			SetsortDirection(sortType);
			setSort(sortType === 'ascend' ? 'asc' : 'desc');
			setCurrentPage(1);
		}
	};

	// To modify the pageSize
	const modifyPageSize = (current: number, size: number) => {
		if (!size) {
			setPageSize(10);
		} else {
			setPageSize(size);
			localStorage.setItem('roleTableSize', size.toString());
			setCurrentPage(1);
		}
	};

	//   For open the model
	const showModal = () => {
		setIsModalOpen(true);
	};

	// For change the data and title between components
	const setDrawerInfoHandler = (drawerTitle: any) => {
		setDrawerInfo({ drawerTitle });
	};

	//   For conform operation
	const handleOk = () => {
		setIsModalOpen(false);
	};

	//   For cancel operation
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	// For fetch the all permission of some role
	const fetchRolePermissions = async (permissionDetails: any) => {
		setSelectedRole(permissionDetails);

		try {
			const permissions = await getApi(
				`/permission/${permissionDetails.id}`
			);
			setPermissions(permissions?.data.data);
		} catch (err) {
			//error
		}
	};

	const fetchRoles = async () => {
		setIsLoading(true);
		try {
			const query = {
				page: currentPage,
				limit: PageSize,
				search: searchValue,
				sort: sort,
			};

			const roles = await getApi('/role', query);
			setRoles(roles.data.data);
		} catch (err) {
			toastText('Something went wrong in fetching roles', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchRoles();
	}, [currentPage, PageSize, filterValue, searchValue, sort]);

	return (
		<>
			<div className={styles['role-table']}>
				<>
					<TableActionHeader title={'Roles'}>
						<div className={styles['role-table__action']}></div>
					</TableActionHeader>
					<div>
						<DynamicTable
							roleDataSource={roles}
							roleColumns={roleColumns}
							paginationChangeHandler={paginationChangeHandler}
							currentPage={currentPage}
							totalRecords={30}
							performSearchHandler={performSearchHandler}
							searchValue={searchValue}
							showModal={showModal}
							openDrawerHandler={openDrawerHandler}
							setDrawerInfoHandler={setDrawerInfoHandler}
							openPermissionsHandler={openPermissionsHandler}
							fetchRolePermissions={fetchRolePermissions}
							performSortHandler={performSortHandler}
							performFilterHandler={performFilterHandler}
							filterValue={filterValue}
							permissions={[]}
							PageSize={PageSize}
							modifyPageSize={modifyPageSize}
							sortDirection={sortDirection}
							isLoading={isLoading}
						/>
					</div>
				</>
			</div>
			<ConfirmDelete
				handleCancel={handleCancel}
				handleOk={handleOk}
				isModalOpen={isModalOpen}
				deleteHandler={() => {}}
				isLoading={false}
			/>
			{/* {isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="right"
					width="half"
				>
					<AddRoleBody
						closeDrawerByAnimation={closeDrawerByAnimation}
						editSelectedRole={editSelectedRole}
						setEditSelectedRole={setEditSelectedRole}
					/>
				</SideDrawerWrapper>
			)} */}
			{isPermissionDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="right"
					width="half"
				>
					<PermissionBody
						closeDrawerByAnimation={closeDrawerByAnimation}
						permissions={permissions}
						selectedRole={selectedRole}
						isLoading={permissions?.length > 0 ? false : true}
					/>
				</SideDrawerWrapper>
			)}
		</>
	);
};

export default RoleTable;
