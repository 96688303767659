import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, List, Select } from 'antd';
import { getApi, postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { currencyData } from 'constants/CurrencyData';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { invalidText, toastText } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';
import AddModal from '../AddModal';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import SelectDropdown from 'components/Global/SelectDropdown';

const AddPayGroupBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [isSelected, setIsSelected] = useState<boolean>();

	const { closeDrawerByAnimation, fetchPayGroup, editSelected } = props;

	const [selectedBranch, setSelectedBranch] = useState<string[]>(
		editSelected?.branches || []
	);
	const [selectedDepartment, setSelectedDepartment] = useState<any[]>(
		editSelected?.departments || []
	);
	const [payGroupName, setPayGroupName] = useState<string>('');
	const [departmentOptions, setDepartmentOptions] = useState<any[]>([]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedCurrency, setSelectedCurrency] = useState<any>(
		editSelected ? editSelected?.currency : null
	);
	const [isEmployeesLoading, setIsEmployeesLoading] =
		useState<boolean>(false);

	const [employees, setEmployees] = useState<any[]>(
		editSelected?.employees || []
	);

	const [totalEmployees, setTotalEmployees] = useState<number>(0);
	const [employeeOptions, setEmployeeOptions] = useState<any[]>([]);
	const [selectedEmployee, setSelectedEmployee] = useState<string[]>([]);
	const dispatch = useDispatch<AppDispatch>();
	const [payGroupEmployees, setPayGroupEmployees] = useState<any[]>(
		editSelected?.employees || []
	);

	const { isLoading } = useSelector((state: any) => state.constantDropdown);

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
		{
			text: `Save`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			fontSize: '1.8rem',
			minWidth: '100px',
			minHeight: '42px',
			disabled: false,
			onclick: () => {},
			font: '16px',
			// onclick: () => handleSubmit(),
		},
	];

	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const mappedCurrencyOptions = dropdownOptions?.currencyData?.map(
		(currency: any) => ({
			value: currency.value,
			label: invalidText(currency.value)
				? 'Select Currency'
				: `${currency.label} - ${
						currencyData[currency.label] || currency.label
				  }`,
		})
	);

	const handleSubmit = async (values: any) => {
		setLoading(true);

		if (editSelected) {
			try {
				let data: any = {
					payGroupId: editSelected?.id,
					name: values.name,
					employeeIds: payGroupEmployees.map(
						(employee) => employee.id
					),
				};
				const response = await postApi('/payGroup/update', data);

				if (response && response.data) {
					closeDrawerByAnimation();
					fetchPayGroup();
					toastText('Pay Group updated successfully', 'success');
				}
			} catch (error) {
				toastText('Someting went wrong in updating pay group', 'error');
			} finally {
				setLoading(false);
			}
		} else {
			try {
				if (selectedCurrency) {
					setIsSelected(true);
					setPayGroupName(values.name);
					await fetchEmployeeData();
				} else {
					selectedCurrency
						? setIsSelected(true)
						: setIsSelected(false);
				}
			} catch (error) {
				//error
			} finally {
				setLoading(false);
			}
		}
	};

	const onFinishFailed = () => {
		if (selectedCurrency) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		const allDepartments: any[] = [];
		if (selectedBranch.length > 0) {
			selectedBranch.forEach((branchId) => {
				const departments = dropdownOptions?.department?.filter(
					(b: any) => b.branchId === branchId
				);

				allDepartments.push(...departments);
			});
		}
		const uniqueDepartments = allDepartments.reduce(
			(acc: any[], current: any) => {
				if (!acc.some((dept) => dept.value === current.value)) {
					acc.push(current);
				}
				return acc;
			},
			[]
		);
		setDepartmentOptions(uniqueDepartments);
	}, [selectedBranch]);

	const fetchEmployeeData = async () => {
		setIsEmployeesLoading(true);
		try {
			const query = {
				currencyId: selectedCurrency || '',
				branchIds: selectedBranch,
				departmentIds: selectedDepartment,
			};

			const response = await getApi('/payGroup/employee', query);
			const employeeData = response.data.data || [];
			setEmployees(employeeData);
			setTotalEmployees(employeeData.length);
			const options = employeeData.map((employee: any) => ({
				value: employee.id,
				label: `${employee.employeeId} - ${employee.firstName} ${employee.lastName} `,
			}));
			setEmployeeOptions(options);
			!editSelected && setIsModalOpen(true);
		} catch (err) {
			//error
		} finally {
			setIsEmployeesLoading(false);
		}
	};

	const fetchPayGroupEmployees = async () => {
		if (editSelected && editSelected.employees) {
			try {
				const data = { employeeIds: editSelected.employees };
				const result = await postApi('/payGroup/empId/', data);
				setPayGroupEmployees(result.data.data); // Set the state for displaying employees
			} catch (err) {}
		}
	};

	const handleRemoveEmployee = (employeeId: string) => {
		setEmployees((prevEmployees) =>
			prevEmployees.filter(
				(employee) => employee.employeeId !== employeeId
			)
		);
		setPayGroupEmployees((prevEmployees) =>
			prevEmployees.filter(
				(employee) => employee.employeeId !== employeeId
			)
		);

		setSelectedEmployee((prevSelected) =>
			prevSelected.filter((id) => id !== employeeId)
		);
		setTotalEmployees((prevTotal) => prevTotal - 1);
	};

	const handleEmployeeSelect = (employeeIds: string[]) => {
		const selectedEmployees = employees.filter((employee) =>
			employeeIds.includes(employee.id)
		);

		setPayGroupEmployees((prevEmployees) => [
			...prevEmployees,
			...selectedEmployees.filter(
				(selectedEmployee) =>
					!prevEmployees.some(
						(employee) => employee.id === selectedEmployee.id
					)
			),
		]);
	};

	useEffect(() => {
		if (editSelected) {
			fetchEmployeeData();
		}
	}, [editSelected]);

	useEffect(() => {
		if (editSelected) {
			fetchPayGroupEmployees();
		}
	}, [editSelected]);

	const getFilteredEmployeeOptions = () => {
		return employeeOptions.filter(
			(option) => !selectedEmployee.includes(option.value)
		);
	};
	useEffect(() => {
		dispatch(fetchConstantDropdownAction({})); // Assuming this fetches the currency options // Call the function
	}, [dispatch]);

	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					initialValues={editSelected}
					onFinishFailed={onFinishFailed}
					onFinish={handleSubmit}
					autoComplete="off"
				>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Pay Group Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Pay group name required',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item name={'currency'} wrapperCol={{ span: 24 }}>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Currency'}{' '}
								{true && (
									<span className="required-color">*</span>
								)}
							</label>

							<Select
								placeholder="Select Currency"
								className={styles['side-drawer-form--select']}
								size="large"
								disabled={
									editSelected || isLoading ? true : false
								}
								loading={isLoading}
								value={selectedCurrency}
								onSelect={(currency) =>
									setSelectedCurrency(currency)
								}
								options={mappedCurrencyOptions}
							/>
							{isSelected === false && (
								<p className="ant-form-item-explain-error">
									Currency required
								</p>
							)}
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item name={'branchId'} wrapperCol={{ span: 24 }}>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Branch'}{' '}
							</label>

							<SelectDropdown
								defaultOption={false}
								defaultLabel="Select Branch"
								placeholder="Select Branch"
								options={dropdownOptions?.branchCode.filter(
									(branch: any) => branch.value
								)}
								value={selectedBranch}
								size="large"
								required={false}
								mode="multiple"
								helperText="Branch is required"
								loading={isLoading}
								label=""
								onChange={(branches: any) => {
									setSelectedBranch(branches);
									setSelectedDepartment([]);
									setIsSelected(true);
								}}
								disabled={
									editSelected || isLoading ? true : false
								}
								isError={false}
							/>
						</Form.Item>
					</div>

					<div className={styles['side-drawer-form__inputs']}>
						<Form.Item
							name={'department'}
							wrapperCol={{ span: 24 }}
						>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Department'}
							</label>

							<SelectDropdown
								defaultOption={false}
								defaultLabel="Select Department"
								placeholder="Select Department"
								options={departmentOptions}
								value={selectedDepartment}
								size="large"
								required={false}
								mode="multiple"
								helperText="Department is required"
								label=""
								onChange={(departments: any) =>
									setSelectedDepartment(departments)
								}
								disabled={
									selectedBranch.length === 0 ||
									!!editSelected
								}
								isError={false}
							/>
						</Form.Item>
					</div>

					{editSelected && (
						<div className={styles['side-drawer-form__inputs']}>
							<Form.Item
								name={'employee'}
								wrapperCol={{ span: 24 }}
							>
								<label
									className={
										styles['side-drawer-form__role--label']
									}
								>
									{'Select Employee'}
								</label>
								<Select
									mode="multiple"
									placeholder="Search/Add Employee"
									className={
										styles['side-drawer-form--select']
									}
									size="large"
									loading={isEmployeesLoading}
									value={selectedEmployee}
									onChange={(employeeIds) => {
										setSelectedEmployee(employeeIds);
										handleEmployeeSelect(employeeIds);
									}}
									tagRender={(props) => (
										<span style={{ display: 'none' }}>
											{props.label}
										</span>
									)}
									showArrow
									showSearch
									notFoundContent="No employees available"
									optionLabelProp="label"
									disabled={employeeOptions.length === 0}
									dropdownRender={(menu) => (
										<div
											style={{
												maxHeight: '200px', // Set a fixed max height
												overflowY: 'auto', // Enable vertical scrolling
												padding: 8,
											}}
										>
											{employeeOptions.length > 0 ? (
												<List
													dataSource={getFilteredEmployeeOptions()}
													renderItem={(item) => (
														<List.Item
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
																borderBottom:
																	'1px solid #f0f0f0', // Optional: add subtle separation
															}}
															actions={[
																<Button
																	type="default"
																	onClick={() => {
																		handleEmployeeSelect(
																			[
																				item.value,
																			]
																		);
																		setEmployeeOptions(
																			(
																				prev
																			) =>
																				prev.filter(
																					(
																						option
																					) =>
																						option.value !==
																						item.value
																				)
																		);
																	}}
																	style={{
																		borderColor:
																			'#584495',
																		color: '#584495',
																	}}
																>
																	Add
																</Button>,
															]}
														>
															{item.label}
														</List.Item>
													)}
												/>
											) : (
												<div
													style={{
														textAlign: 'center',
														padding: '8px 0',
													}}
												>
													No employees available
												</div>
											)}
										</div>
									)}
								/>
							</Form.Item>
						</div>
					)}

					{editSelected && (
						<div>
							<div className={styles['side-drawer-form__inputs']}>
								<div
									style={{
										marginBottom: '8px',
										fontWeight: 'bold',
									}}
								>
									Total number of Employees:{' '}
									{payGroupEmployees.length}
								</div>
								<List
									dataSource={payGroupEmployees}
									style={{
										maxHeight: '230px', // Set a maximum height
										overflowY: 'auto', // Enable vertical scrolling
									}}
									renderItem={(item) => (
										<List.Item
											actions={[
												<Button
													type="text"
													icon={
														<CloseOutlined
															style={{
																color: '#d9d9d9',
															}}
														/>
													}
													onClick={() =>
														handleRemoveEmployee(
															item.employeeId
														)
													}
												/>,
											]}
										>
											{item.firstName} {item.lastName} -{' '}
											<span style={{ color: '#584495' }}>
												{item.employeeId}
											</span>
										</List.Item>
									)}
								/>
							</div>
						</div>
					)}
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>

			{isModalOpen && (
				<AddModal
					isModalOpen={isModalOpen}
					onClose={handleModalClose}
					currency={
						mappedCurrencyOptions?.find(
							(option: any) => option.value === selectedCurrency
						) || {}
					}
					branches={dropdownOptions?.branchCode.filter(
						(branch: any) => selectedBranch.includes(branch.value)
					)}
					departments={departmentOptions.filter((department: any) =>
						selectedDepartment.includes(department.value)
					)}
					totalEmployees={totalEmployees}
					employees={employees}
					onRemoveEmployee={handleRemoveEmployee}
					payGroupName={payGroupName}
					fetchPayGroup={fetchPayGroup}
					closeDrawerByAnimation={closeDrawerByAnimation}
				/>
			)}
		</>
	);
};

export default AddPayGroupBody;
