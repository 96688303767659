import TableActionHeader from 'components/Global/TableActionHeader';
import ApprovalLayout from 'layouts/Approval';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

interface SelectedValue {
	key: string;
}

export default function Approval() {
	const navigate = useNavigate();

	const pathname = window.location.pathname;
	const pathSegments = pathname.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];

	const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);

	// Function to handle sidebar changes
	const sideBarChangeHandler = (selectedValue: SelectedValue) => {
		setSelectedSidebar(selectedValue.key);
		navigate(selectedValue.key);
	};

	useEffect(() => {
		// const allowedConfiguration = getAllowedModule(context.permissions, [
		// 	'employee',
		// ]);
		if (lastSegment === 'approval') {
			// if (allowedConfiguration) {
			// 	const key =
			// 		keyWiseApprovalRouteMapping[
			// 			allowedConfiguration.toLowerCase() as keyof typeof keyWiseApprovalRouteMapping
			// 		];
			// 	navigate(`/settings/configuration/${key}`);
			// } else {
			navigate(`/settings/approval/employee`); //testing
			// }
		}
		setSelectedSidebar(lastSegment);
	}, [lastSegment]);

	return (
		<>
			<TableActionHeader title={'Configuration'} />
			<div className={styles['Configuration']}>
				<ApprovalLayout
					onSideBarChange={sideBarChangeHandler}
					selectedSidebar={selectedSidebar}
				>
					<Outlet />
				</ApprovalLayout>
			</div>
		</>
	);
}
