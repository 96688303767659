import { useNavigate } from 'react-router-dom';
import './index.scss';
import PayrollComponent from 'components/Payroll/PayrollComponent';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext, useEffect } from 'react';

export const Payroll = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Run_Payroll');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);
	return <div>{check && <PayrollComponent />}</div>;
};
