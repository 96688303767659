import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext, useEffect } from 'react';
import RunPayrollComponent from 'components/Payroll/RunPayroll';
import { toastText } from 'utils/utils';

export const RunPayroll = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const add = context.allowedPermissions.includes('Add_Run_Payroll');
	const Edit = context.allowedPermissions.includes('Edit_Run_Payroll');
	const view = context.allowedPermissions.includes('View_Run_Payroll');

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const payRollId = searchParams.get('payrollId');
		const onlyView = searchParams.get('onlyView');
		if (payRollId) {
			if (onlyView !== 'true' && !Edit) {
				navigate(`/run-payroll?payrollId=${payRollId}&onlyView=true`);
			}
		}
		if (!add && !payRollId) {
			toastText('Missing Permission', 'error');
			navigate('/');
		}
	}, [searchParams, Edit]);

	return <div>{(add || Edit || view) && <RunPayrollComponent />}</div>;
};
