import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	conversionRate: 1,
	isUsdCurrency: false,
	usdCurrencyToggle: false,
};

const currencySlice = createSlice({
	name: 'currency',
	initialState,
	reducers: {
		setConversionRate: (state, action) => {
			state.conversionRate = action.payload;
		},
		setIsUsdCurrency: (state, action) => {
			state.isUsdCurrency = action.payload;
		},
		toggleUsdCurrency: (state) => {
			state.usdCurrencyToggle = !state.usdCurrencyToggle;
		},
	},
});

export const { setConversionRate, setIsUsdCurrency, toggleUsdCurrency } =
	currencySlice.actions;

export default currencySlice;
