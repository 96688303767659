import { Form, Input, Select } from 'antd';
import { getApi, postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';

const AddCostCenterBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const {
		closeDrawerByAnimation,
		fetchCostCenterData,
		editSelected,
		branchOptions,
	} = props;
	const [isSelected, setIsSelected] = useState<boolean>();
	const [isSelectedDepartment, setIsSelectedDepartment] = useState<boolean>();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const [selectedBranch, setSelectedBranch] = useState(
		editSelected ? editSelected?.branchId : null
	);
	const [selectedDepartment, setSelectedDepartment] = useState(
		editSelected ? editSelected?.departmentId : null
	);

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
			font: '16px',
			minHeight: '48px',
			minWidth: '60px',
		},
		{
			text: `Save`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			font: '16px',
			minHeight: '48px',
			minWidth: '110px',
		},
	];
	const handleSubmit = async (values: any) => {
		if (editSelected) {
			let data: any = {
				costCenterId: editSelected.id,
			};

			if (selectedBranch && selectedDepartment) {
				data = {
					...data,
					branchId: selectedBranch,
					departmentId: selectedDepartment,
					name: values.name,
					code: values.code,
					// isChangeStatus: false,
				};

				await updateCostCentertData(data);
			} else {
				selectedBranch ? setIsSelected(true) : setIsSelected(false);
				selectedDepartment
					? setIsSelectedDepartment(true)
					: setIsSelectedDepartment(false);
			}
		} else {
			if (selectedBranch && selectedDepartment) {
				setIsSelected(true);
				const finalData = {
					branchId: selectedBranch,
					departmentId: selectedDepartment,
					name: values.name,
					code: values.code,
				};
				await addDepartment(finalData);
			} else {
				selectedBranch ? setIsSelected(true) : setIsSelected(false);
				selectedDepartment
					? setIsSelectedDepartment(true)
					: setIsSelectedDepartment(false);
			}
		}
	};
	// If form fails
	const onFinishFailed = () => {
		selectedBranch ? setIsSelected(true) : setIsSelected(false);
		selectedDepartment
			? setIsSelectedDepartment(true)
			: setIsSelectedDepartment(false);
	};

	const fetchDepartmentCodes = async (branchId: string) => {
		setIsLoading(true);
		try {
			const apiRes: any = await getApi(`/department/${branchId}`);

			if (apiRes.data.data) {
				const departments = apiRes.data.data.map((department: any) => {
					return {
						id: department.id,
						name: department.name,
					};
				});
				setDepartmentOptions(departments);
			}
		} catch (err) {
			toastText('Something went wrong in fetching department', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		selectedBranch && fetchDepartmentCodes(selectedBranch);
	}, []);

	const updateCostCentertData = async (departmentData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/costCenter', departmentData);
			fetchCostCenterData();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Cost center updated successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong in updating cost center';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addDepartment = async (finalData: any) => {
		try {
			setLoading(true);
			const response = await postApi('/costCenter', finalData);
			fetchCostCenterData();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Cost center created successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to cost center';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					onFinishFailed={onFinishFailed}
					initialValues={editSelected}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Cost Code'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'code'}
							rules={[
								{
									required: true,
									message: 'Cost code required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Cost code length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Cost Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Cost name required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Cost Name length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
						<Form.Item
							className="side-drawer-form__role"
							name={'branchId'}
						>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Branch'}{' '}
								{true && (
									<span className="required-color">*</span>
								)}
							</label>

							<Select
								placeholder="Select Branch"
								className={styles['side-drawer-form--select']}
								size="large"
								disabled={false}
								value={selectedBranch}
								onSelect={(branch) => {
									setSelectedBranch(branch);

									fetchDepartmentCodes(branch);
									// setIsSelectedDepartment()
									setSelectedDepartment(null);
									setIsSelected(true);
								}}
							>
								{branchOptions?.map(
									(branch: any, key: number) => {
										return (
											<Select.Option
												value={branch?.id}
												key={key}
											>
												{branch?.name}
											</Select.Option>
										);
									}
								)}
							</Select>
							{isSelected === false && (
								<p className="ant-form-item-explain-error">
									Branch required
								</p>
							)}
						</Form.Item>
						<Form.Item
							className="side-drawer-form__role"
							name={'departmentId'}
						>
							<label
								className={
									styles['side-drawer-form__role--label']
								}
							>
								{'Select Department'}{' '}
								{true && (
									<span className="required-color">*</span>
								)}
							</label>

							<Select
								placeholder="Select Department"
								className={styles['side-drawer-form--select']}
								size="large"
								loading={isLoading}
								disabled={
									!(isSelected || selectedBranch) || isLoading
								}
								value={selectedDepartment}
								onSelect={(department) => {
									setSelectedDepartment(department);
									setIsSelectedDepartment(true);
								}}
							>
								{departmentOptions?.map(
									(branch: any, key: number) => {
										return (
											<Select.Option
												value={branch?.id}
												key={key}
											>
												{branch?.name}
											</Select.Option>
										);
									}
								)}
							</Select>
							{isSelectedDepartment === false && (
								<p className="ant-form-item-explain-error">
									Department required
								</p>
							)}
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddCostCenterBody;
