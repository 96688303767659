import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useContext, useEffect, useState } from 'react';
import PaymentInfoDetails from './Table';
// import EmployeeHeader from './Header';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { toastText } from 'utils/utils';
//import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { directDepositApi } from '../../../Api/directDeposit';
type Props = {
	employeeId: string;
};
const PaymentDetails = (props: Props) => {
	const { employeeId} = props;
	const permissionsContext = useContext(PermissionContext);


	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [paymentData, setPaymentData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchPayment = async () => {
		try {
			const employeeId = searchParams.get('employeeId');
			setIsLoading(true);
			const query = {
				employeeId: employeeId,
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
     
      const salaries = await directDepositApi.getPaymentHistory(query)
			// const salaries = await getApi('/directDeposit/', query);
			setPaymentData(salaries.data.data);
			setTotalRecords(salaries.data.count);
		} catch (err: any) {
			if (err.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching salaries', 'error');
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (searchParams.get('employeeId')) {
			fetchPayment();
		}
	}, [pageSize, currentPage, sortOrder, sortField, selectedYear]);


	useEffect(() => {
		if (
			employeeId &&
			!permissionsContext.allowedPermissions.includes(
				'Edit_Employment'
			)
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [employeeId, permissionsContext.allowedPermissions]);

	return (
		<div>
			{/* <div className={styles['employee-salary-container']}> */}
      <div>
				<PaymentInfoDetails
					paymentInfoData={paymentData}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					fetchRecords={fetchPayment}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};

export default PaymentDetails;
