import JournalEntryReportComponent from 'components/Report/JournalEntryReport';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

const JournalEntryReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return <>{check && <JournalEntryReportComponent />}</>;
};

export default JournalEntryReport;
