import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Col, Image, Popover, Row, Select, Tooltip } from 'antd';
import { postApi } from 'apis';
import UserProfileModal from 'components/Profile';
import { configurationModules, payrollModules } from 'constants/Data';
import { jwtDecode } from 'jwt-decode';
import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationSvg, NotificationSvgRed } from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import { logoutAction } from '../../../redux/actions/logoutAction';
import { clearProfileRedux } from '../../../redux/slices/profileSlice';
import { PermissionContext } from '../AuthLayout';
import UserNameBox from '../UserNameBox';
import styles from './index.module.scss';
import './index.scss';
import { notificationApi } from 'Api/notification';
import Notifications from 'components/Notification/Notifications';
import NotificationModal from 'components/Notification';
import useDebounce from '../Hooks/UseDebounce';

interface NotificationState {
	isOpen: boolean;
	data: any[];
	loading: boolean;
	pageSize: number;
	pageNumber: number;
	searchValue: string;
	totalCount: number;
	hasMore: boolean;
}

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const permissionContext = useContext(PermissionContext);
	const context = useContext(PermissionContext);

	// Profile related state
	const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
	const [isNotificationModalOpen, setNotificationModalOpen] =
		useState<boolean>(false);
	const [isLogoutLoading, setIsLogoutLoading] = useState(false);

	// Company related state
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

	// Permission related state
	const [isUserPermission, setIsUserPermission] = useState(true);
	const [isRolePermission, setIsRolePermission] = useState(true);
	const [isConfigurationPermission, setIsConfigurationPermission] =
		useState(true);
	const [isPayrollSettingsPermission, setIsPayrollSettingsPermission] =
		useState(true);

	// Notification counter
	const [notifications, setNotifications] = useState(0);

	// Centralized notification state

	// Logout handler
	const logoutHandler = async () => {
		setIsLogoutLoading(true);
		try {
			const logoutData = {
				email: userData?.email,
				accessToken: userData?.accessToken,
			};
			const data = { logoutData };
			const response = await dispatch(logoutAction(data) as any);

			dispatch(clearProfileRedux());
			context.permissionHandler('', '', '', false, false, true);
			toastText(response?.payload?.message, 'success');
			navigate('/login');
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
			localStorage.clear();
		} finally {
			setIsLogoutLoading(false);
			localStorage.removeItem('accessToken');
		}
	};

	// Company handler
	const companyHandler = async (id: string) => {
		try {
			const company = await postApi('/company', {
				companyId: id,
			});
			localStorage.setItem('accessToken', company.data.accessToken);
			setSelectedCompany(id);
			window.location.reload();
		} catch (err) {}
	};

	// Profile handlers
	const myProfileHandler = () => setProfileModalOpen(true);
	const profileCancel = () => setProfileModalOpen(false);

	// Navigation handler
	const handleNavigatePermissionWise = (currentPath: string) => {
		const targetPath = currentPath.includes('settings') ? '/' : '/settings';
		navigate(targetPath);
	};
	const [notificationState, setNotificationState] =
		useState<NotificationState>({
			isOpen: false,
			data: [],
			loading: false,
			pageSize: 20,
			pageNumber: 1,
			searchValue: '',
			totalCount: 0,
			hasMore: true,
		});

	const { data: userData } = useSelector((state: any) => state?.userProfile);

	const debouncedSearchValue = useDebounce(
		notificationState.searchValue,
		500
	);

	// Fetch notifications handler
	const fetchNotifications = useCallback(
		async (isInitialFetch = false) => {
			try {
				setNotificationState((prev) => ({ ...prev, loading: true }));

				const response = await notificationApi.getNotificationListing({
					pageSize: notificationState.pageSize,
					page: notificationState.pageNumber,
					search: debouncedSearchValue,
				});

				const newNotifications = response.data.data.notifications;
				const totalCount = response.data.data.totalCount || 0;

				setNotifications(totalCount);
				setNotificationState((prev) => ({
					...prev,
					data: isInitialFetch
						? newNotifications
						: [...newNotifications, ...prev.data],
					loading: false,
					totalCount,
					hasMore:
						newNotifications.length === prev.pageSize &&
						prev.data.length + newNotifications.length < totalCount,
				}));
			} catch (error: any) {
				toastText(error.response.data.message, 'error');
				setNotificationState((prev) => ({
					...prev,
					loading: false,
					hasMore: false,
				}));
			}
		},
		[
			notificationState.pageSize,
			notificationState.pageNumber,
			debouncedSearchValue,
		]
	);

	// Handle popover visibility
	const handleOpenChange = useCallback(
		(newOpen: boolean) => {
			if (!newOpen) {
				setNotificationState((prev) => ({
					...prev,
					isOpen: false,
					pageNumber: 1,
					pageSize: 20,
					data: [],
					hasMore: true,
				}));
			} else {
				setNotificationState((prev) => ({
					...prev,
					isOpen: true,
					pageNumber: 1,

					pageSize: 20,
					data: [],
					hasMore: true,
				}));
				fetchNotifications(true);
			}
		},
		[fetchNotifications]
	);

	// Handle search input
	const handleSearch = useCallback((value: string) => {
		setNotificationState((prev) => ({
			...prev,
			searchValue: value,
			pageNumber: 1,
			data: [],
			hasMore: true,
		}));
	}, []);

	// Handle load more
	const handleLoadMore = useCallback(() => {
		setNotificationState((prev) => ({
			...prev,
			pageNumber: prev.pageNumber + 1,
			pageSize: prev.pageSize + 20,
		}));
	}, []);

	// Effect for fetching notifications when page changes
	useEffect(() => {
		fetchNotifications(false);
	}, [notificationState.pageNumber, notificationState.pageSize]);

	// Effect for fetching notifications when search changes
	useEffect(() => {
		if (notificationState.isOpen) {
			fetchNotifications(true);
		}
	}, [debouncedSearchValue]);

	// Effect for company data
	useEffect(() => {
		if (userData && userData.companies?.length > 0) {
			setCompanies(userData.companies);
			const tokenData: {
				id: string;
				email: string;
				companyId: string;
			} = jwtDecode(localStorage.getItem('accessToken') as string);
			setSelectedCompany(tokenData.companyId);
		}
	}, [userData]);

	// Effect for permissions
	useEffect(() => {
		const _isUserPermission = checkPermission(
			permissionContext.permissions,
			{
				permissionName: 'Users',
				permission: ['add', 'view', 'edit', 'delete'],
			}
		);

		const _isRolePermission = checkPermission(
			permissionContext.permissions,
			{
				permissionName: 'Roles',
				permission: ['add', 'view', 'edit', 'delete'],
			}
		);

		const _configuration = configurationModules.map((module) => {
			return checkPermission(permissionContext.permissions, {
				permissionName: module,
				permission: ['add', 'view', 'edit', 'delete'],
			});
		});

		const _configurationPermission = _configuration.some(
			(configuration) => configuration === true
		);

		const _payroll = payrollModules.map((module) => {
			return checkPermission(permissionContext.permissions, {
				permissionName: module,
				permission: ['add', 'view', 'edit', 'delete'],
			});
		});

		const _payrollPermission = _payroll.some((payroll) => payroll === true);

		setIsConfigurationPermission(_configurationPermission);
		setIsPayrollSettingsPermission(_payrollPermission);
		setIsUserPermission(_isUserPermission);
		setIsRolePermission(_isRolePermission);
	}, [permissionContext]);

	// Memoized notification content
	const notificationContent = useMemo(
		() => (
			<Notifications
				onClose={() => handleOpenChange(false)}
				open={notificationState.isOpen}
				notificationData={notificationState.data}
				loading={notificationState.loading}
				fetchAllNotifications={() => fetchNotifications(true)}
				changePage={handleLoadMore}
				handleSearch={handleSearch}
				setNotifications={setNotificationState}
				searchValue={notificationState.searchValue}
			/>
		),
		[
			notificationState,
			handleOpenChange,
			fetchNotifications,
			handleLoadMore,
			handleSearch,
		]
	);

	return (
		<>
			<header className={styles['header']}>
				<Row
					className={styles['header__wrapper']}
					align={'middle'}
					justify={'space-between'}
				>
					<Col className={styles['header__details-left']}>
						<div className={styles['header__details-left--logo']}>
							<Image
								src="/assets/images/WageWorks.png"
								preview={false}
								alt="group"
								onClick={() => navigate('/')}
								style={{ cursor: 'pointer' }}
							/>
						</div>
					</Col>

					<Col className={styles['header__details-right']}>
						{companies?.length > 0 && (
							<Select
								placeholder="Select Organization"
								className={
									styles[
										'header__details-right--organization'
									]
								}
								value={selectedCompany}
								onChange={(id: string) => companyHandler(id)}
								disabled={companies?.length == 1}
							>
								{companies?.map((company: any, key: number) => (
									<Select.Option
										value={company?.id}
										key={key}
									>
										{company?.name}
									</Select.Option>
								))}
							</Select>
						)}

						<div style={{ display: 'flex', gap: '2rem' }}>
							{(isUserPermission ||
								isRolePermission ||
								isPayrollSettingsPermission ||
								isConfigurationPermission) && (
								<Tooltip placement="bottom" title="Settings">
									<SettingOutlined
										style={{
											color: '#444444',
											fontSize: 24,
										}}
										onClick={() =>
											handleNavigatePermissionWise(
												window.location.pathname
											)
										}
									/>
								</Tooltip>
							)}

							<Popover
								placement="bottomRight"
								trigger="click"
								open={notificationState.isOpen}
								onOpenChange={handleOpenChange}
								content={notificationContent}
							>
								<span className="pointer">
									{notifications > 0 ? (
										<NotificationSvgRed />
									) : (
										<NotificationSvg />
									)}
								</span>
							</Popover>
						</div>

						<Tooltip placement="bottom" title="Profile">
							<div
								className={
									styles['header__details-right--user']
								}
								onClick={myProfileHandler}
							>
								<div
									className={
										styles[
											'header__details-right--user-logo'
										]
									}
								>
									<UserNameBox
										name={`${userData?.firstName} ${userData?.lastName}`}
									/>
								</div>

								<div
									className={
										styles[
											'header__details-right--user-details'
										]
									}
								>
									<p
										className={
											styles[
												'header__details-right--user-name'
											]
										}
									>
										{userData?.firstName}{' '}
										{userData?.lastName}
									</p>
									<p
										className={
											styles[
												'header__details-right--user-profile'
											]
										}
									>
										My Profile
									</p>
								</div>
							</div>
						</Tooltip>

						<div
							className={
								styles['header__details-right--user-logout']
							}
						>
							<Tooltip placement="bottom" title="Logout">
								<LogoutOutlined
									style={{
										cursor: isLogoutLoading
											? 'not-allowed'
											: 'pointer',
										pointerEvents: isLogoutLoading
											? 'none'
											: 'auto',
										fontSize: 23,
										color: '#727272',
									}}
									onClick={logoutHandler}
								/>
							</Tooltip>
						</div>
					</Col>
				</Row>
			</header>

			{isProfileModalOpen && (
				<UserProfileModal
					isProfileModalOpen={isProfileModalOpen}
					handleCancel={profileCancel}
				/>
			)}

			{isNotificationModalOpen && (
				<NotificationModal
					isNotificationModalOpen={isNotificationModalOpen}
					handleCancel={() => setNotificationModalOpen(false)}
					approvalId={null}
				/>
			)}
		</>
	);
};

export default Header;
