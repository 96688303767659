import { Button, Form, Select } from 'antd';
// import { InputWithLabelAndSvg } from "components/Global";
import InputWithLabelAndSvg from 'components/Global/InputWithLabel';
import { FORMDATA } from 'constants/Data';
import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import { getApi, postApi, putApi } from 'apis';
import { toastText } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const AddUserBody: FC<SideDrawerBodyProps> = (props) => {
	// Inits
	const { addUserFields } = FORMDATA;
	const { closeDrawerByAnimation, editSelectedUser,roleOptions, fetchUsers } = props;
	const [form] = Form.useForm();

	// State Management
	const [selectedRole, setSelectedRole] = useState(
		editSelectedUser ? editSelectedUser?.roleId : null
	);
	const [isSelected, setIsSelected] = useState<boolean>();
	const [showError, setShowError] = useState<boolean>();
	const [loading, setLoading] = useState<boolean>();

	const editData = {
		...editSelectedUser,
		fullName: editSelectedUser?.name || '',
	};

	const dispatch = useDispatch<AppDispatch>();

	const inviteUser = async (finalData: any) => {
		try {
			setLoading(true);
			await postApi('/user/invite-user', finalData);
			toastText('User invited successfully', 'success');
		} catch (err: any) {
			let message = 'Something went wrong when trying to invite';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	const updateUser = async (finalData: any) => {
		try {
			setLoading(true);
			await putApi('/user', finalData);
		} catch (err) {
			toastText('Something went wrong in updating user', 'error');
		} finally {
			setLoading(false);
		}
	};

	const onFinish = async (values: any, buttonType = 'Save') => {
		const fullName = (values.fullName as string).trim();

		if (editSelectedUser) {
			let data: any = {
				userId: editSelectedUser?.userId,
				fullName,
			};

			if (selectedRole) {
				data = {
					...data,
					roleId: selectedRole,
					// isChangeStatus: false,
				};

				await updateUser(data);
				fetchUsers();
				closeDrawerByAnimation();
			}
		} else {
			if (selectedRole) {
				setIsSelected(true);
				const finalData = {
					email: values.email,
					role: selectedRole,
					fullName,
				};
				await inviteUser(finalData);
				fetchUsers();
				closeDrawerByAnimation();
			} else {
				selectedRole ? setIsSelected(true) : setIsSelected(false);
			}
		}
	};

	// If form fails
	const onFinishFailed = () => {
		setShowError(true);
		if (selectedRole) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	};

	// JSX
	return (
		<div className={styles['side-drawer-body']}>
			<Form
				form={form}
				name="basic"
				initialValues={editData}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				layout="vertical"
				labelAlign="left"
				className={styles['side-drawer-form']}
			>
				<div className={styles['side-drawer-form__inputs']}>
					{addUserFields.map((singleField: any, index: number) => {
						return (
							<React.Fragment key={index}>
								{singleField.id === 'phone' && (
									<>
										<Form.Item
											className="side-drawer-form__role"
											name={singleField.name}
										>
											<label
												className={
													styles[
														'side-drawer-form__role--label'
													]
												}
											>
												{singleField.title}{' '}
												{singleField?.required && (
													<span className="required-color">
														*
													</span>
												)}
											</label>
										</Form.Item>
									</>
								)}
								{singleField.id !== 'roleName' &&
									singleField.id !== 'phone' && (
										<InputWithLabelAndSvg
											singleUserInput={singleField}
											disabled={
												singleField?.name == 'email' &&
												editSelectedUser &&
												true
											}
										/>
									)}
								{singleField.id === 'roleName' && (
									<Form.Item
										className="side-drawer-form__role"
										name={singleField.name}
									>
										<label
											className={
												styles[
													'side-drawer-form__role--label'
												]
											}
										>
											{singleField.title}{' '}
											{singleField?.required && (
												<span className="required-color">
													*
												</span>
											)}
										</label>

										<Select
											placeholder="Select Role"
											className={
												styles[
													'side-drawer-form__role--select'
												]
											}
											size="large"
											disabled={false}
											value={selectedRole}
											onSelect={(role) => {
												setSelectedRole(role);
												setIsSelected(true);
											}}
										>
											{/* <Select.Option>Test</Select.Option> */}
											{roleOptions?.map(
												(role: any, key: number) => {
													return (
														<Select.Option
															value={role?.id}
															key={key}
														>
															{role?.name}
														</Select.Option>
													);
												}
											)}
										</Select>
										{isSelected == false && (
											<p className="ant-form-item-explain-error">
												Please select role
											</p>
										)}
									</Form.Item>
								)}
							</React.Fragment>
						);
					})}
				</div>
				<div className={styles['side-drawer-form__buttons']}>
					<Form.Item>
						<Button
							htmlType="submit"
							className={`${
								styles['side-drawer-form__buttons--edit']
							} ${false && 'pointer-event-none'}`} //isAddUserLoading
							loading={loading}
						>
							Save
							{/* {editSelectedUser ? 'Edit' : 'Add'} */}
						</Button>
					</Form.Item>
					<Form.Item>
						<Button
							htmlType="reset"
							className={`${styles['side-drawer-form__buttons--cancel']}`}
							onClick={closeDrawerByAnimation}
							 disabled={loading}
						>
							Cancel
						</Button>
					</Form.Item>
				</div>
			</Form>
		</div>
	);
};

export default AddUserBody;
