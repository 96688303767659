import { postApi, getApi, getApiPDF } from 'apis';
const createPaye = async (data: any) => {
	return await postApi('/paye/create', data, true);
};

const getLatestPaye = async () => {
	return await getApi('/paye/latest');
};

const getPayeHistory = async (params: any) => {
	return await getApi(`/paye/history`, params);
};
const createCommunication = async (data: any) => {
	return await postApi('/payeCommunication/create', data, true);
};

const getAllCommunication = async (params: any) => {
	return await getApi(`/payeCommunication`, params);
};

const calculatePaye = async (data: any) => {
	return await postApi('/calculation/paye', data);
};

const getPayeReports = async (params: any) => {
	return await getApi('/paye/payeReports', params);
};

const createPayeRecord = async (data: any, payeRecordId: string) => {
	return await postApi(`/paye/record/${payeRecordId}`, data, true);
};

const downloadPayeCommunication = async (communicationId: string) => {
	return await getApiPDF('/payeCommunication/download', {
		communicationId: communicationId,
	});
};

const downloadPayeRecord = async (recordId: string) => {
	return await getApiPDF('/paye/record/download', {
		payeRecordId: recordId,
	});
};
const downloadPayeAttachment = async (payeId: string) => {
	return await getApiPDF('/paye/attachment/download', {
		payeId: payeId,
	});
};

export const payeApi = {
	createPaye,
	getLatestPaye,
	getPayeHistory,
	calculatePaye,
	getPayeReports,
	createPayeRecord,
	createCommunication,
	getAllCommunication,
	downloadPayeCommunication,
	downloadPayeRecord,
	downloadPayeAttachment,
};
